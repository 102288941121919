import IBeaconMonitoring from "@/contracts/IBeaconMonitoring";
import IConversation from "@/contracts/IConversation";

export function findBeacons(beacons: IBeaconMonitoring[], filter: string): IBeaconMonitoring[] {
    return beacons.filter(
        beacon =>
            beacon.Name.toLowerCase().includes(filter.toLowerCase()) ||
            beacon.IridiumImei.includes(filter) ||
            beacon.GsmImei.includes(filter) ||
            beacon.SerialNumber.includes(filter)
    );
}

export function findConversations(conversations: IConversation[], filter: string): IConversation[] {
    if (!filter) {
        return conversations;
    }
    return conversations.filter(
        conversation =>
            conversation.Name.toLowerCase().includes(filter.toLowerCase()) ||
            conversation.IridiumImei.includes(filter) ||
            conversation.GsmImei.includes(filter) ||
            conversation.SerialNumber.includes(filter)
    );
}
