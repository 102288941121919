import { Vue, Component, Prop } from "vue-property-decorator";
import IBeaconMonitoring from "@/contracts/IBeaconMonitoring";
import "./MessageCard.scss";
import { IMessage, MessageStatus } from "@/contracts/IMessage";

@Component
export default class MessageCard extends Vue {
    @Prop()
    message!: IMessage;

    @Prop()
    beacon!: IBeaconMonitoring;

    get clientName() {
        return this.beacon.InterfaceSettings.Client1 || this.$t("messenger.client", { number: 1 }).toString();
    }

    render() {
        return (
            <div class={[this.message.Status !== MessageStatus.Incoming && "message-card_reverse", "message-card"]}>
                <div class="message-card__avatar">
                    <span class="message-card__avatar-letter">
                        {(this.message.UserName || this.clientName).slice(0, 1)}
                    </span>
                </div>
                <div class="message-card__body">
                    <div class="message-card__content">{this.message.Content}</div>
                    <div class="message-card__info text-muted font-size-xs">
                        <div class="mx-2">{this.message.UserName || this.clientName}</div>
                        {this.message.SentDt &&
                        (this.message.Status === MessageStatus.SentAndConfirmed ||
                            this.message.Status === MessageStatus.Incoming ||
                            this.message.Status === MessageStatus.SentAndConfirmedByIridium) ? (
                            <date-wrapper date={this.message.SentDt} />
                        ) : this.message.Status === MessageStatus.Waiting ? (
                            <div>{this.$t("messenger.messageStatus.sending")}</div>
                        ) : this.message.Status === MessageStatus.Sent ? (
                            <div>{this.$t("messenger.messageStatus.sentToBeacon")}</div>
                        ) : this.message.Status === MessageStatus.Error ? (
                            <div class="color-glyph-emergency font-weight-bold">
                                {this.$t("messenger.messageStatus.sendError")}
                            </div>
                        ) : null}
                    </div>
                </div>
                {!this.message.IsRead ? (
                    <div class="message-card__indicator">
                        <div class="message-card__indicator-inner" />
                    </div>
                ) : null}
            </div>
        );
    }
}
