import { Layout, Config } from "plotly.js/lib/core";

export default {
    getOptions: (lang: string): Partial<Config> => {
        return {
            scrollZoom: true, // lets us scroll to zoom in and out - works
            showLink: false, // removes the link to edit on plotly - works
            modeBarButtonsToRemove: ["sendDataToCloud", "lasso2d", "select2d"],
            displaylogo: false,
            locale: lang,
            responsive: true
        };
    },
    getLayout: (height: number): Partial<Layout> | any => {
        return {
            xaxis: {
                type: "date",
                tickangle: 0,
                tickformat: "%H:%M\n%d.%m.%y"
            },
            yaxis: {
                fixedrange: true
            },
            height: height,
            hovermode: "x",
            spikedistance: 0,
            margin: { t: 10, l: 50, b: 30, r: 10 },
            autosize: true,
            showlegend: false
        };
    }
};
