








import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class IconBase extends Vue {
    @Prop({ type: String, default: "Car0" })
    iconName!: string;

    @Prop({ type: [Number, String], default: 36 })
    iconWidth!: number | string;

    @Prop({ type: [Number, String], default: 36 })
    iconHeight!: number | string;

    @Prop({ type: String, default: "black" })
    iconColor!: string;
}
