import { feedback } from "@/api/account";
import { Vue, Component, Prop } from "vue-property-decorator";
import { IFeedbackForm } from "@/contracts/IFeedbackForm";

@Component
export default class FeedbackModal extends Vue {
    render() {
        return (
            <el-dialog
                style="z-index: 1000"
                title={this.$t("feedback.title")}
                modal-append-to-body={true}
                append-to-body={true}
                visible={this.visibility}
                onClose={() => this.$emit("close")}
                width="30%"
            >
                <div class="font-weight-medium mb-3 font-size-m">{this.$t("feedback.rate")}</div>
                <div class="w-100 d-flex flex-column mb-4">
                    <div>
                        <div class="d-flex mb-2 justify-content-between p-2 rounded" style="background-color: #F5F7Fb">
                            <span class="mr-4">{this.$t("feedback.overall")}</span>
                            <el-rate
                                value={this.feedback.overall}
                                onInput={event => (this.feedback.overall = event)}
                                colors={["#99A9BF", "#F7BA2A", "#FF9900"]}
                            />
                        </div>
                        <div class="d-flex mb-2 justify-content-between p-2 rounded" style="background-color: #F5F7Fb">
                            <span class="mr-4">{this.$t("feedback.design")}</span>
                            <el-rate
                                value={this.feedback.design}
                                onInput={event => (this.feedback.design = event)}
                                colors={["#99A9BF", "#F7BA2A", "#FF9900"]}
                            />
                        </div>
                        <div class="d-flex mb-2 justify-content-between p-2 rounded" style="background-color: #F5F7Fb">
                            <span class="mr-4">{this.$t("feedback.functionality")}</span>
                            <el-rate
                                value={this.feedback.functionality}
                                onInput={event => (this.feedback.functionality = event)}
                                colors={["#99A9BF", "#F7BA2A", "#FF9900"]}
                            />
                        </div>
                        <div class="d-flex mb-2 justify-content-between p-2 rounded" style="background-color: #F5F7Fb">
                            <span class="mr-4">{this.$t("feedback.easeofuse")}</span>
                            <el-rate
                                value={this.feedback.easeofuse}
                                onInput={event => (this.feedback.easeofuse = event)}
                                colors={["#99A9BF", "#F7BA2A", "#FF9900"]}
                            />
                        </div>
                    </div>
                </div>
                <div class="font-weight-medium mb-3 font-size-m">{this.$t("feedback.leavecomment")}</div>
                <el-input
                    type="textarea"
                    rows={5}
                    value={this.feedback.comment}
                    onInput={event => (this.feedback.comment = event)}
                />
                <div slot="footer">
                    <el-button size="small" type="primary" loading={this.working} onClick={this.send}>
                        {this.$t("feedback.send")}
                    </el-button>
                </div>
            </el-dialog>
        );
    }

    @Prop() visibility!: boolean;

    working: boolean = false;
    feedback: IFeedbackForm = {
        overall: null,
        design: null,
        functionality: null,
        easeofuse: null,
        comment: ""
    };

    send() {
        feedback(this.feedback).then(() => {
            this.$emit("update:visibility", false);
        });
    }
}
