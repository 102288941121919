const localizations = [
    {
        value: 0,
        key: "ru",
        label: "Russian"
    },
    {
        value: 1,
        key: "en",
        label: "English"
    }
];

const cardSizes = [
    {
        value: 0,
        key: "normal"
    },
    {
        value: 1,
        key: "small"
    }
];

const geometryTypeKeys = {
    point: "Point",
    polygon: "Polygon"
};

const geometryTypes = [
    {
        key: geometryTypeKeys.point,
        value: 1,
        label: "Точка"
    },
    {
        key: geometryTypeKeys.polygon,
        value: 2,
        label: "Полигон"
    }
];

const notificationTypes = {
    0: {
        key: "connected",
        value: 0,
        label: "выход на связь"
    }, // ВЫХОД НА СВЯЗЬ
    1: {
        key: "disconnected",
        value: 1,
        label: "уход со связи"
    }, // УХОД СО СВЯЗИ
    2: {
        key: "relevance",
        value: 2,
        label: "релевантность данных"
    }, // РЕЛЕВАНТНОСТЬ ДАННЫХ
    3: {
        key: "packetSent",
        value: 3,
        label: "отправка пакетов"
    }, // ОТПРАВКА ПАКЕТОВ
    4: {
        key: "packetReceived",
        value: 4,
        label: "прием пакетов"
    }, // ПРИЕМ ПАКЕТОВ
    5: {
        key: "messenger",
        value: 5,
        label: "сообщение на мессенджер"
    }, // СООБЩЕНИЯ НА МЕССЕНДЖЕР
    6: {
        key: "trafficOverflow",
        value: 6,
        label: "перерасход траффика"
    }, // ПЕРЕРАСХОД ТРАФИКА
    7: {
        key: "processByServer",
        value: 7,
        label: "обработка сервером"
    }, // ОБРАБОТКА СЕРВЕРОМ
    // ПО КОМАНДАМ
    8: {
        key: "command",
        value: 8,
        label: "отправка команды/подтверждение"
    }, // ОТПРАВКА КОМАНДЫ/ПОДТВЕРЖДЕНИЕ
    // ПО КАНАЛАМ
    9: {
        key: "channelState",
        value: 9,
        label: "состояние канала"
    }, // ПО СОСТОЯНИЮ КАНАЛА
    // ГЕОЗОНЫ
    10: {
        key: "geoobject",
        value: 10,
        label: "положение относительно геообъекта"
    }, // ПОЛОЖЕНИЕ ОТНОСИТЕЛЬНО ГЕООБЪЕКТА
    // ПОЛЬЗОВАТЕЛИ
    11: {
        key: "transactions",
        value: 11,
        label: "транзакции пользователя"
    }, // ТРАНЗАКЦИИ ПОЛЬЗОВАТЕЛЯ
    12: {
        key: "resourceShared",
        value: 12,
        label: "расшаривание ресурсов"
    } // РАСШАРИВАНИЕ РЕСУРСОВ
};

const notificationStatusTypes = {
    /// Не определено
    0: {
        key: "undefined",
        value: 0,
        label: "",
        iconClass: ""
    },
    /// Норма
    1: {
        key: "normal",
        value: 1,
        label: "норма",
        iconClass: "fa-check-circle"
    },
    /// <summary>
    /// Предаварийное
    /// </summary>
    2: {
        key: "preEmergency",
        value: 2,
        label: "предаварийное",
        iconClass: "fa-exclamation-circle"
    },
    /// <summary>
    /// Аварийное
    /// </summary>
    3: {
        key: "emergency",
        value: 3,
        label: "аварийное",
        iconClass: "fa-exclamation-circle"
    },
    /// <summary>
    /// Информационное
    /// </summary>
    4: {
        key: "info",
        value: 4,
        label: "информационное",
        iconClass: "fa-info-circle"
    }
};

const floatingPanelViews = {
    beaconSidePage: "BeaconSidePage",
    notificationsBar: "NotificationsBar",
    geoobjectsBar: "GeoobjectsBar",
    messengerBar: "MessengerBar"
};

export {
    localizations,
    geometryTypes,
    geometryTypeKeys,
    notificationTypes,
    notificationStatusTypes,
    floatingPanelViews,
    cardSizes
};
