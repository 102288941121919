import { Vue, Component, Prop } from "vue-property-decorator";
import { StatusEventEnum } from "@/api/models/Timeline";
import { StatusEvent } from "@/api/monitoring";

import "./StatusEventCard.scss";

@Component
export default class StatusEventCard extends Vue {
    @Prop()
    statusEvent!: StatusEvent;

    get statusClass() {
        switch (this.statusEvent.EventId) {
            case StatusEventEnum.RebootHardware:
            case StatusEventEnum.RebootSoftware:
            case StatusEventEnum.VoltageLow:
            case StatusEventEnum.ExternalVoltageLow:
            case StatusEventEnum.ExternalVoltageHigh:
            case StatusEventEnum.InternalVoltageLow:
            case StatusEventEnum.BoardTempLow:
            case StatusEventEnum.BoardTempHigh:
            case StatusEventEnum.RebootWatchdog:
                return { statusClass: "status-event_warning", iconClass: "fad fa-lg fa-exclamation-circle" };
            case StatusEventEnum.SOS:
            case StatusEventEnum.GPSFault:
            case StatusEventEnum.IridiumFault:
            case StatusEventEnum.GSMFault:
            case StatusEventEnum.FlashFault:
            case StatusEventEnum.AccelerometerFault:
            case StatusEventEnum.TemperatureFault:
            case StatusEventEnum.SimFault:
            case StatusEventEnum.VoltageFault:
            case StatusEventEnum.RadioFault:
            case StatusEventEnum.IridiumUnavailable:
            case StatusEventEnum.GSMUnavailable:
                return { statusClass: "status-event_error", iconClass: "fad fa-lg fa-exclamation-circle" };
            case StatusEventEnum.BoardTempNormal:
            case StatusEventEnum.InternalVoltageNormal:
            case StatusEventEnum.ExternalVoltageNormal:
            default:
                return { statusClass: "status-event_info", iconClass: "fad fa-lg fa-info-circle" };
        }
    }

    public render() {
        if (!this.statusEvent) {
            return;
        }
        return (
            <div class={["status-event", this.statusClass.statusClass]}>
                <div class="status-event__body">
                    <div class="status-event__header">
                        <i class={["mr-3", this.statusClass.iconClass]} />
                        <span class="status-event__title">
                            {this.$t(`StatusEvent.${StatusEventEnum[this.statusEvent.EventId]}`)}
                        </span>
                    </div>
                    <div class="status-event__timestamp">
                        <date-wrapper date={this.statusEvent.StartDt} format="DD.MM.YY HH:mm" />
                    </div>
                </div>
            </div>
        );
    }
}
