import "promise-polyfill/src/polyfill";
import "@/polyfills/includes";
import "@/polyfills/find";
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import VueI18n from "vue-i18n";
import VueChatScroll from "vue-chat-scroll";

import App from "./App";

import "@/element-ui";

import router from "./router";
import store from "./store";

import http, { configureAPI } from "@/api/http";

import "@/components/DateWrapper";
import "@/components/DurationWrapper";
import "@/components/CoordinatesWrapper";

import messages from "@/locale";

import "@/assets/scss/lookout.scss";

import IconBase from "@/components/Icons/IconBase.vue";

import dayjs from "dayjs";
import "dayjs/locale/ru"; // load on demand
// Vue.config.performance = true;

Vue.component("icon-base", IconBase);

Vue.config.productionTip = false;

Vue.use(VueChatScroll);
Vue.use(VueI18n);

Vue.prototype.$http = http;
http.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");

http.interceptors.response.use(
    (response: any) => {
        return response;
    },
    (error: any) => {
        if (store.state.isLoggedIn) {
            if (error.response) {
                if (error.response.status === 401) {
                    store.dispatch("logout").then(() => {
                        location.reload(true);
                    });
                }
            }
        }
        return Promise.reject(error);
    }
);

const lastLocale = localStorage.getItem("locale");
/* eslint-disable no-unneeded-ternary */
const i18n = new VueI18n({
    locale: lastLocale ? lastLocale : "ru",
    messages
});
dayjs.locale(lastLocale ? lastLocale : "ru");

configureAPI().then(() => {
    /* eslint-disable no-new */
    /* eslint-disable no-unused-vars */
    /* eslint-disable @typescript-eslint/no-unused-vars */
    // @ts-ignore
    const vue = new Vue({
        components: { App },
        el: "#app",
        i18n,
        router,
        store,
        template: "<App/>"
    });
});
