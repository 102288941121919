import "./ChatInput.scss";

import { Vue, Component, Prop } from "vue-property-decorator";

@Component
class ChatInput extends Vue {
    @Prop() disabled!: boolean;

    messageContent: string = "";

    handleSendMessage(event: KeyboardEvent): void {
        if (event.shiftKey) {
            return;
        }
        if (this.messageContent.trim()) {
            this.$emit("send", this.messageContent);
            this.messageContent = "";
        }
    }

    render() {
        return (
            <div class="chat-input">
                <div class="chat-input__container border-top">
                    <el-input
                        disabled={this.disabled}
                        maxlength={200}
                        placeholder={this.$t("messenger.placeholder")}
                        rows={1}
                        autosize={{ minRows: 1, maxRows: 6 }}
                        onKeyupEnter={this.handleSendMessage}
                        class="flex-grow-1 p-4"
                        resize="none"
                        type="textarea"
                        vModel={this.messageContent}
                    />
                    <div class="m-2">
                        <el-button
                            disabled={this.disabled || !this.messageContent.trim()}
                            onClick={this.handleSendMessage}
                            class="chat-input__send-button"
                            icon="fas fa-arrow-up"
                            type="primary"
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default ChatInput;
