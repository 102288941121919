export default [
    "#BF2600",
    "#FF8B00",
    "#006644",
    "#008DA6",
    "#403294",
    "#0747A6",
    "#091E42",

    "#DE350B",
    "#FF991F",
    "#00875A",
    "#00A3BF",
    "#5243AA",
    "#0052CC",
    "#253858",

    "#FF5630",
    "#FFAB00",
    "#36B37E",
    "#00B8D9",
    "#8777D9",
    "#0065FF",
    "#42526E"
];
