import plotlyLocales from "@/const/PlotlyLocales";
const Plotly = require("plotly.js/lib/core");

// const plotlyScatter = require("plotly.js/lib/scatter");
const ScatterGL = require("plotly.js/lib/scattergl");
const Bar = require("plotly.js/lib/bar");

Plotly.register(ScatterGL);
Plotly.register(Bar);
// plotlyCore.register(plotlyScatter);
Plotly.register(plotlyLocales.ru);

export default Plotly;
