import NavMenu from "@/components/Menu/NavBar/NavMenu";
import NavItemRouteLink from "@/components/Menu/NavBar/NavItemRouteLink";
import NavItemDropdown from "@/components/Menu/NavBar/NavItemDropdown";
import NavItemButton from "@/components/Menu/NavBar/NavItemButton";

import * as types from "@/store/const/mutation-types";

import FeedbackModal from "@/components/FeedbackModal";

import { floatingPanelViews } from "@/const/enums";

import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Action, Mutation, State } from "vuex-class";
import IUser from "@/contracts/IUser";
import "./LookoutMenu.scss";

@Component({
    components: {
        NavMenu,
        NavItemRouteLink: NavItemRouteLink as any,
        NavItemButton: NavItemButton as any,
        NavItemDropdown,
        FeedbackModal
    }
})
export default class LookoutMenu extends Vue {
    private feedbackModal: boolean = false;
    private items: any[] = [
        {
            route: { path: "/monitoring" },
            key: "monitoring"
        },
        {
            route: { path: "/tracks" },
            key: "tracks",
            mode: "desktop"
        },
        {
            route: { path: "/geoobjects" },
            key: "geoobjects",
            mode: "desktop"
        },
        {
            route: { path: "/history" },
            key: "history",
            mode: "desktop"
        },
        {
            route: { path: "/management" },
            key: "management",
            mode: "desktop"
        },
        {
            route: { path: "/reports" },
            key: "reports",
            mode: "desktop"
        },
        {
            route: { path: "/bnwas" },
            key: "bnwas",
            mode: "desktop"
        }
    ];

    @Action("logout")
    private logout;

    private isActiveTab(val) {
        return this.$route.path.indexOf(val) >= 0;
    }

    private handleLogout() {
        this.$root.$emit("autoUpdate:stop");
        this.logout().then(() => {
            location.reload(true);
        });
    }

    @Action("updateUnreadNotificationsCount", { namespace: "notifications" })
    private updateUnreadNotificationsCount;

    @Mutation(types.SET_FLOATING_PANEL_CURRENT_VIEW)
    private setFloatingPanelCurrentView;

    private toggleGeoobjects() {
        this.setFloatingPanelCurrentView(floatingPanelViews.geoobjectsBar);
    }

    private toggleNotifications() {
        this.setFloatingPanelCurrentView(floatingPanelViews.notificationsBar);
    }

    private toggleMessenger() {
        this.setFloatingPanelCurrentView(floatingPanelViews.messengerBar);
    }

    @Watch("$route")
    onRouteChanged() {
        this.setFloatingPanelCurrentView(null);
    }

    @State("user")
    private user!: IUser;

    @State("unreadNotificationsCount", { namespace: "notifications" })
    private unreadNotificationsCount;

    private get hasGeoobjects() {
        return this.$route.meta.geoobjects;
    }

    private get unreadMessagesCount() {
        return this.$store.state.monitoring.beacons.reduce((acc, beacon) => acc + beacon.UnreadMessagesCount, 0);
    }

    public created() {
        this.updateUnreadNotificationsCount();

        this.$root.$on("autoUpdate:started", () => {
            this.updateUnreadNotificationsCount();
        });
    }

    public render() {
        if (!this.user) {
            return null;
        }

        return (
            <nav-menu class="lookout-menu" collapseSize="md" logoClass="app-logo">
                <template slot="logo">
                    <div class="h-100 d-flex align-items-center ml-2 mr-4">
                        <img alt height="60%" src="/static/Logo.png" />
                    </div>
                </template>
                <ul class="navbar-nav" slot="navbarLeft">
                    {this.items.map(item => {
                        if (item.key === "bnwas" && !this.user.BnwasManagement) {
                            return null;
                        }

                        return (
                            <nav-item-route-link
                                key={item.key}
                                outerClass={[
                                    { active: this.isActiveTab(item.route.path) },
                                    item.mode === "desktop" && "hidden-md"
                                ]}
                                route={item.route}
                            >
                                {this.$t("menu." + item.key)}
                            </nav-item-route-link>
                        );
                    })}
                </ul>
                <ul class="navbar-nav" slot="navbarRight">
                    {this.hasGeoobjects && (
                        <nav-item-button onClick={this.toggleGeoobjects} outerClass="fa-lg fad pb-1 fa-map-marked" />
                    )}
                    {this.user && this.user.CanViewMessages && (
                        <nav-item-button onClick={this.toggleMessenger}>
                            <el-badge hidden={this.unreadMessagesCount === 0} value={this.unreadMessagesCount}>
                                <i class="fad fa-lg fa-envelope" />
                            </el-badge>
                        </nav-item-button>
                    )}
                    <nav-item-button onClick={this.toggleNotifications}>
                        <el-badge hidden={this.unreadNotificationsCount === 0} value={this.unreadNotificationsCount}>
                            <i class="fad fa-lg fa-bell" />
                        </el-badge>
                    </nav-item-button>
                    {this.user && (
                        <nav-item-dropdown
                            outerClass={{
                                "nav-text dropdown": true,
                                active: this.isActiveTab("/profile")
                            }}
                            innerClass="mr-0 px-3"
                        >
                            <div slot="header">
                                <i class="mr-3 fa-lg fad fa-user" />
                                <span>{this.user.UserName}</span>
                            </div>
                            <router-link to="/profile" class="dropdown-item">
                                {this.$t("menu.profile")}
                            </router-link>
                            <a class="dropdown-item" href="/Lookout_v2.0_user_guide.pdf" target="_blank">
                                {this.$t("menu.docs")}
                            </a>
                            <div
                                onClick={() => {
                                    this.feedbackModal = true;
                                }}
                                class="dropdown-item"
                            >
                                {this.$t("menu.feedback")}
                                <feedback-modal
                                    onClose={() => {
                                        this.feedbackModal = false;
                                    }}
                                    visibility={this.feedbackModal}
                                />
                            </div>
                            <a onClick={this.handleLogout} class="dropdown-item" href="#">
                                {this.$t("menu.logout")}
                            </a>
                        </nav-item-dropdown>
                    )}
                </ul>
            </nav-menu>
        );
    }
}
