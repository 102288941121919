import Plotly from "@/Plotly";
import plotlyOptions from "@/const/PlotlyOptions";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import "./Chart.scss";
import Empty from "@/components/Core/Empty";
import ResizeObserver from "resize-observer-polyfill";
import { FuelDailyConsumptionData } from "@/api/monitoring";

@Component({
    components: {
        Empty
    }
})
export default class FuelConsumptionChart extends Vue {
    private traces: any[] = [];

    @Prop()
    private chartData!: FuelDailyConsumptionData;

    @Prop()
    private loading!: boolean;

    @Watch("chartData", { immediate: true })
    public onChartDataChanged() {
        if (this.chartData) {
            this.drawChart();
        }
    }

    private drawChart() {
        this.traces = this.chartData.Values.map(x => ({
            x: this.chartData.Timestamps,
            y: x.Values,
            visible: true,
            type: "bar",
            name: x.Name
        }));
        this.$nextTick(() => {
            const height = this.$el.clientHeight - 5;
            const locale = localStorage.getItem("locale");
            Plotly.newPlot(
                this.$refs.fuelChart as Element,
                this.traces,
                {
                    ...plotlyOptions.getLayout(height),
                    showlegend: true,
                    legend: {
                        x: 1,
                        xanchor: "right",
                        y: 1
                    }
                },
                plotlyOptions.getOptions(locale || "ru")
            );
        });
    }

    private resizeObserver: ResizeObserver | null = null;

    public mounted() {
        this.resizeObserver = new ResizeObserver(() => {
            if (this.chartData) {
                this.$nextTick(() => {
                    Plotly.Plots.resize(this.$refs.chartContainer);
                });
            }
        });

        this.resizeObserver.observe(this.$refs.chartContainer as Element);
    }

    public beforeDestroy() {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
        Plotly.purge(this.$refs.fuelChart as HTMLElement);
    }

    public render() {
        return (
            <div ref="chartContainer" class="d-flex flex-grow-1 flex-column-reverse" vLoading={this.loading}>
                <div class={[!this.chartData && "d-none"]} ref="fuelChart" />
                {!this.chartData && !this.loading ? (
                    <empty icon="fad fa-analytics fa-5x" text={this.$t("default.nodata")} />
                ) : null}
            </div>
        );
    }
}
