export default interface TimelineElement {
    Timestamp: string;
    LocationData: LocationData[];
    ChannelData: ChannelData[];
    StatusData: EventData[];
}

export interface EventData {
    Event: StatusEventEnum;
}

export enum StatusEventEnum {
    RebootHardware = 0,
    RebootSoftware = 1,
    RebootWatchdog = 2,
    GPSFault = 3,
    IridiumFault = 4,
    GSMFault = 5,
    IridiumUnavailable = 6,
    GSMUnavailable = 7,
    FlashFault = 100,
    AccelerometerFault = 101,
    TemperatureFault = 102,
    SimFault = 103,
    VoltageFault = 104,
    RadioFault = 105,
    CaseOpen = 200,
    CaseClose = 201,
    VoltageLow = 202,
    MountRemove = 203,
    MountInstall = 204,
    ExternalPowerConnected = 205,
    ExternalPowerDisconnected = 206,
    SOS = 207,
    BNWASUndefined = 208,
    BNWASPowerOff = 209,
    BNWASOff = 210,
    ExternalVoltageLow = 300,
    ExternalVoltageHigh = 301,
    InternalVoltageLow = 302,
    BoardTempLow = 303,
    BoardTempHigh = 304,
    ExternalVoltageNormal = 305,
    InternalVoltageNormal = 306,
    BoardTempNormal = 307,
    // Norway
    PowerOn = 400,
    PowerOff = 401,
    GPSAntennaBlockage = 402
}

export interface ChannelData {
    ChannelId: number;
    Name: string;
    FormattedValue: string;
}

export interface LocationData {
    Latitude: number;
    Longitude: number;
}
