import { Component, Vue, Prop } from "vue-property-decorator";
import { VNode } from "vue";

import "./Empty.scss";

@Component
export default class Empty extends Vue {
    @Prop()
    private icon!: string;

    @Prop()
    private text!: string;

    public render(): VNode {
        return (
            <div class="EmptyWrapper">
                <i class={["EmptyIcon", this.icon]}></i>
                <label class="EmptyText">{this.text}</label>
            </div>
        );
    }
}
