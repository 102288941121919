import SidePage from "@/components/Containers/SidePage";

import NotificationsBar from "@/components/Notifications/NotificationsBar";
import GeoobjectsBar from "@/components/GeoobjectsBar/GeoobjectsBar.vue";
import Messenger from "@/components/MessengerBar/Messenger";

import BeaconSidePage from "@/components/Monitoring/BeaconSidePage";

import PushNotifications from "@/components/Mixins/PushNotifications";

import http from "@/api/http";
import { localizations } from "@/const/enums";

import { Component, Watch, Mixins } from "vue-property-decorator";
import { Mutation, Action, Getter, State } from "vuex-class";

import "./App.scss";
import IBeaconMonitoring from "./contracts/IBeaconMonitoring";
import IUser from "./contracts/IUser";
import * as types from "@/store/const/mutation-types";

@Component({
    components: {
        NotificationsBar,
        GeoobjectsBar,
        Messenger,
        SidePage,
        BeaconSidePage
    }
})
export default class App extends Mixins(PushNotifications) {
    errorMessage: any = null;
    loadingInstance: any = null;
    timer: any = null;
    floatingPanelGeoobjectsSettings: any = {
        header: "geoobjects",
        minWidth: "500px",
        outerClass: "col-lg-3 col-md-3 col-sm-12 p-0",
        headerStyle: "background-color: #fff;"
    };
    floatingPanelMessengerSettings: any = {
        header: "messages",
        minWidth: "500px",
        outerClass: "col-lg-3 col-md-3 col-sm-12 p-0",
        headerStyle: "background-color: #fff;"
    };
    floatingPanelNotificationsSettings: any = {
        header: "notifications",
        minWidth: "500px",
        outerClass: "col-lg-3 col-md-3 col-sm-12 p-0",
        headerStyle: "background-color: #fff;"
    };
    beaconSidePageSettings: any = {
        header: "notifications",
        minWidth: "800px",
        outerClass: "col-lg-3 col-md-3 col-sm-12 p-0",
        headerStyle: "background-color: #fff;"
    };

    @Mutation("RESIZE_CANVAS")
    resizeCanvas;

    @Mutation("SET_FLOATING_PANEL_CURRENT_VIEW")
    setFloatingPanelCurrentView;

    @Mutation("SHOW_POSITIONS", { namespace: "monitoring" })
    showPositions;

    @Action("loadUser")
    loadUser;

    @Action("loadData", { namespace: "monitoring" })
    loadData;

    @Action("loadBeacons", { namespace: "monitoring" })
    loadBeacons;

    @Action("loadGroups", { namespace: "monitoring" })
    loadGroups;

    @Action("updateTracks", { namespace: "monitoring" })
    updateTracks;

    @Action("loadGeoobjects", { namespace: "geoobjects" })
    loadGeoobjects;

    @Mutation(types.SELECT_BEACON, { namespace: "monitoring" })
    selectBeacon;

    cancelAutoUpdate() {
        clearInterval(this.timer);
        this.timer = null;
    }

    async fetchData() {
        this.loadingInstance = (this.$loading as any).service({ fullscreen: true });
        try {
            await Promise.all([this.loadUser(), this.loadBeacons(), this.loadGroups(), this.loadGeoobjects()]);
            this.$i18n.locale = localizations[this.user.Localization].key;
            localStorage.setItem("locale", localizations[this.user.Localization].key);
            if (this.user.MonitoringInterfaceSettings.ShowPositionOnLoad) {
                setTimeout(() => {
                    const beaconsWithLocation = this.beacons.filter(beacon => beacon.Location);
                    const ids = beaconsWithLocation.map(beacon => beacon.Id);
                    this.showPositions(ids);
                }, 1500);
            }
        } catch (error) {
            console.log(error);
        } finally {
            this.loadingInstance.close();
        }
    }

    updateIsFloatingPanelVisible() {
        if (this.floatingPanelCurrentView === "BeaconSidePage") {
            this.selectBeacon(null);
        }
        this.setFloatingPanelCurrentView("");
    }

    async autoUpdate() {
        this.$root.$emit("autoUpdate:started");
        try {
            await this.loadBeacons();
            await this.updateTracks();
        } catch (error) {
            console.log(error);
        }
    }

    resumeAutoUpdate() {
        this.autoUpdate();
        this.startAutoUpdate();
    }

    startAutoUpdate() {
        if (!this.timer) {
            this.timer = setInterval(this.autoUpdate, 60000);
        }
    }

    @Watch("error")
    onErrorChanged(value) {
        this.$router.push("/error");
    }

    @Watch("isLoggedIn")
    onIsLoggedInChanged(value) {
        if (value) {
            http.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
            this.fetchData();
            this.startAutoUpdate();
        } else {
            http.defaults.headers.common["Authorization"] = "";
        }
    }

    @Getter("getSelectedBeacon", { namespace: "monitoring" })
    beacon;

    @State("beacons", { namespace: "monitoring" })
    beacons!: IBeaconMonitoring[];

    @State
    isLoggedIn!: boolean;

    @State
    user!: IUser;

    @State
    error;

    @State
    floatingPanelCurrentView;

    created() {
        if (this.isLoggedIn) {
            this.fetchData();
            this.startAutoUpdate();
            this.startProcessNotifications();
        }
        this.$root.$on("autoUpdate:stop", this.cancelAutoUpdate);
        this.$root.$on("autoUpdate:start", this.startAutoUpdate);
        this.$root.$on("autoUpdate:resume", this.resumeAutoUpdate);
    }

    beforeDestroy() {
        clearInterval(this.timer);
    }

    mounted() {
        let resizeTimer;
        window.addEventListener("resize", evt => {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(() => {
                this.resizeCanvas({
                    w: window.innerWidth,
                    h: window.innerHeight - 85
                });
            }, 250);
        });
    }

    render() {
        return (
            <div id="app">
                <keep-alive>
                    <router-view name="header" />
                </keep-alive>
                <div class="w-100 h-100">
                    <keep-alive include={["MonitoringView", "TracksView", "GeoobjectsView", "HistoryView"]}>
                        <router-view name="main" />
                    </keep-alive>
                </div>
                <side-page
                    headerStyle={this.floatingPanelGeoobjectsSettings.headerStyle}
                    outerClass={this.floatingPanelGeoobjectsSettings.outerClass}
                    header={this.$t("menu." + this.floatingPanelGeoobjectsSettings.header)}
                    minWidth={this.floatingPanelGeoobjectsSettings.minWidth}
                    visibility={this.floatingPanelCurrentView === "GeoobjectsBar"}
                    onHide={this.updateIsFloatingPanelVisible}
                >
                    <geoobjects-bar />
                </side-page>
                <side-page
                    headerStyle={this.floatingPanelMessengerSettings.headerStyle}
                    outerClass={this.floatingPanelMessengerSettings.outerClass}
                    header={this.$t("menu." + this.floatingPanelMessengerSettings.header)}
                    minWidth={this.floatingPanelMessengerSettings.minWidth}
                    visibility={this.floatingPanelCurrentView === "MessengerBar"}
                    onHide={this.updateIsFloatingPanelVisible}
                >
                    <messenger />
                </side-page>
                <side-page
                    headerStyle={this.floatingPanelNotificationsSettings.headerStyle}
                    outerClass={this.floatingPanelNotificationsSettings.outerClass}
                    header={this.$t("menu." + this.floatingPanelNotificationsSettings.header)}
                    minWidth={this.floatingPanelNotificationsSettings.minWidth}
                    visibility={this.floatingPanelCurrentView === "NotificationsBar"}
                    onHide={this.updateIsFloatingPanelVisible}
                >
                    <notifications-bar />
                </side-page>
                <side-page
                    headerStyle={this.beaconSidePageSettings.headerStyle}
                    outerClass={this.beaconSidePageSettings.outerClass}
                    header={this.beacon && this.beacon.Name}
                    minWidth={this.beaconSidePageSettings.minWidth}
                    visibility={this.floatingPanelCurrentView === "BeaconSidePage"}
                    onHide={this.updateIsFloatingPanelVisible}
                >
                    <beacon-side-page />
                </side-page>
            </div>
        );
    }
}
