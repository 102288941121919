import http from "./http";
import store from "@/store";
import { ERROR } from "@/store/const/mutation-types";
import handleError from "./handleError";
import INotification from "@/contracts/INotification";

export async function loadNotifications(
    count: number,
    offset: number,
    unread: boolean,
    unshown?: boolean
): Promise<INotification[]> {
    try {
        const { data } = await http.get<INotification[]>("/notifications", {
            params: {
                takeCount: count,
                skipCount: offset,
                filterByUnread: unread,
                filterByUnshown: unshown
            }
        });
        return data;
    } catch (error) {
        store.commit(ERROR, handleError(error));
        throw error;
    }
}

export async function markNotificationsAs(ids: number[], isRead: boolean, isShown: boolean) {
    try {
        await http.post("/notifications.markAs", ids, {
            params: {
                asRead: isRead,
                asShown: isShown
            }
        });
    } catch (error) {
        store.commit(ERROR, handleError(error));
        throw error;
    }
}

export async function loadNotificationsCount(unread: boolean, unshown?: boolean): Promise<number> {
    try {
        let { data } = await http.get<number>("/notifications.getCount", {
            params: {
                filterByUnread: unread,
                filterByUnshown: unshown
            }
        });
        return data;
    } catch (error) {
        store.commit(ERROR, handleError(error));
        throw error;
    }
}
