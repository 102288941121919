import { Vue, Component, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";

import IBeaconMonitoring from "@/contracts/IBeaconMonitoring";

import * as echarts from "echarts/lib/echarts";
import "echarts/lib/chart/gauge";
import "echarts/lib/chart/bar";
import "echarts/lib/component/grid";

import "./GaugesWidget.scss";
import { Gauge } from "./Gauge";

@Component
export default class GaugesWidget extends Vue {
    render() {
        let body = (
            <div
                class="d-flex flex-column align-items-center justify-content-center h-100"
                style="background-color: #fff;"
            >
                <img class="mb-4" src="/static/info.svg" height="100" alt />
                <label class="text-muted">{this.$t("channelswidget.choosebeacon")}</label>
            </div>
        );

        if (this.selectedBeacon) {
            const fuelSensors = this.selectedBeacon.Channels.filter(el => el.ChannelFlag === 7);
            const tanks = fuelSensors.map(channel => {
                return (
                    <div class="card m-2 p-2 rounded panel">
                        <div class="font-weight-semibold mb-2 font-size-s">{channel.Name}</div>
                        <div id={channel.Name} style="width: 150px; height: 250px;" />
                    </div>
                );
            });

            const flowSensors = this.selectedBeacon.Channels.filter(el => el.ChannelFlag === 8);
            const gauges = flowSensors.map(channel => {
                return (
                    <div class="card m-2 p-2 rounded panel">
                        <div class="font-weight-semibold mb-2 font-size-s">{channel.Name}</div>
                        <div id={`gauge${channel.Name}`} style="width: 200px; height: 200px;" />
                    </div>
                );
            });

            if (fuelSensors.length > 0) {
                body = (
                    <div class="p-3" style="overflow-x: scroll">
                        <div>
                            {fuelSensors.length > 0 && (
                                <div class="font-weight-semibold">{this.$t("channelswidget.fuelLevelMonitoring")}</div>
                            )}
                            <div class="scrolling-wrapper mb-2">{tanks}</div>
                            {flowSensors.length > 0 && (
                                <div class="font-weight-semibold">
                                    {this.$t("channelswidget.fuelConsumptionMonitoring")}
                                </div>
                            )}
                            <div class="scrolling-wrapper mt-1">{gauges}</div>
                        </div>
                    </div>
                );
            } else {
                body = (
                    <div class="p-3 d-flex flex-column align-items-center justify-content-center h-100">
                        <img class="mb-4" src="/static/info.svg" height="100" alt />
                        <label class="text-muted">{this.$t("default.nodata")}</label>
                    </div>
                );
            }
        }

        return <div class="widget-container position-relative text-left scroll gauges-widget">{body}</div>;
    }

    @Getter("getSelectedBeacon", { namespace: "monitoring" })
    selectedBeacon!: IBeaconMonitoring;

    gaugeCharts: echarts.ECharts[] = [];
    tankCharts: echarts.ECharts[] = [];

    search: string = "";

    @Watch("selectedBeacon")
    onSelectedBeaconChanged(value: IBeaconMonitoring | undefined) {
        this.draw(value);
    }

    draw(value: IBeaconMonitoring | undefined) {
        if (value) {
            this.gaugeCharts.forEach(element => {
                element.dispose();
            });
            this.gaugeCharts = [];

            this.tankCharts.forEach(element => {
                element.dispose();
            });
            this.tankCharts = [];

            this.$nextTick(() => {
                value.Channels.forEach(element => {
                    if (element.ChannelFlag === 7) {
                        this.drawEchartsTank(
                            element.Name,
                            element.Value || 0,
                            element.DisplayedValue,
                            element.Min,
                            element.Max
                        );
                    }
                });

                value.Channels.forEach((element, index) => {
                    if (element.ChannelFlag === 8) {
                        this.drawEchartsGauge(
                            element.Name,
                            element.Value || 0,
                            element.DisplayedValue,
                            element.Min,
                            element.Max
                        );
                    }
                });
            });
        }
    }

    drawEchartsGauge(name: string, value: number, displayValue: string, min: number, max: number): void {
        // based on prepared DOM, initialize echarts instance
        var elem = document.getElementById(`gauge${name}`) as HTMLDivElement;
        if (!elem) {
            return;
        }

        var gaugeChart = echarts.init(elem);

        // specify chart configuration item and data
        var option = {
            tooltip: {
                show: false
            },
            series: [
                {
                    name: name,
                    type: "gauge",
                    radius: "100%",
                    animation: false,
                    max: max,
                    min: min,
                    startAngle: 225,
                    endAngle: -45,
                    splitNumber: 10,
                    clockwise: true,
                    data: [{ value: value.toFixed(2) }],
                    pointer: {
                        width: 4
                    },
                    splitLine: {
                        length: 14,
                        lineStyle: {
                            color: "#152935"
                        }
                    },
                    detail: {
                        color: "#152935",
                        fontSize: 16,
                        fontWeight: "bold",
                        formatter: displayValue
                    },
                    axisTick: {
                        lineStyle: {
                            color: "#152935"
                        }
                    },
                    axisLine: {
                        show: false,
                        lineStyle: {
                            opacity: 0.8,
                            width: 4,
                            shadowBlur: 0,
                            color: [[0.13, "#A7B6C2"], [0.6, "#15B371"], [0.8, "#F29D49"], [1.0, "#F55656"]]
                        }
                    },
                    axisLabel: {
                        show: true,
                        distance: 0,
                        color: "#152935",
                        fontSize: 10
                    }
                }
            ]
        };

        // use configuration item and data specified to show chart
        gaugeChart.setOption(option);
        this.gaugeCharts.push(gaugeChart);
    }

    drawEchartsTank(name: string, value: number, displayValue: string, min: number, max: number) {
        // based on prepared DOM, initialize echarts instance
        var elem = document.getElementById(`${name}`) as HTMLDivElement;
        if (!elem) {
            return;
        }

        var tankChart = echarts.init(elem);

        var option = {
            tooltip: {
                show: false
            },
            grid: {
                top: "10%",
                left: "30%",
                right: "0%",
                bottom: "10%"
            },
            xAxis: {
                data: ["Двигатель 1"],
                offset: 15,
                axisTick: {
                    show: false
                },
                axisLine: {
                    show: false
                },
                axisLabel: {
                    show: false
                }
            },
            yAxis: {
                min: min,
                max: max,
                splitLine: {
                    show: false
                },
                axisLine: {
                    lineStyle: {
                        color: "#888"
                    }
                }
            },
            series: [
                {
                    type: "bar",
                    animation: false,
                    label: {
                        show: true,
                        position: "top",
                        color: "#2979ff",
                        fontSize: 14,
                        formatter: displayValue
                    },
                    itemStyle: {
                        color: "#1070ca",
                        barBorderRadius: 5
                    },
                    barWidth: "95%",
                    data: [value.toFixed(2)],
                    z: 10
                },
                {
                    type: "bar",
                    animation: false,
                    barGap: "-100%",
                    itemStyle: {
                        color: "#eee",
                        barBorderColor: "#aaa",
                        barBorderWidth: 1,
                        barBorderRadius: 5
                    },
                    barWidth: "95%",
                    data: [max],
                    z: 5
                }
            ],
            backgroundColor: "#fff"
        };

        // use configuration item and data specified to show chart
        tankChart.setOption(option);
        this.tankCharts.push(tankChart);
    }

    private drawGauge(name: string, value: number, displayValue: string, min: number, max: number) {
        let config: any = {
            size: 220,
            label: name,
            min: undefined !== min ? min : 0,
            max: undefined !== max ? max : 100,
            minorTicks: 5
        };

        // var range = config.max - config.min;
        // config.yellowZones = [{ from: config.min + range * 0.75, to: config.min + range * 0.9 }];
        // config.redZones = [{ from: config.min + range * 0.9, to: config.max }];
        this.gauge = new Gauge(`gauge${name}`, config);
        this.gauge.render();
        this.gauge.redraw(value);
    }

    private gauge: any = null;
    mounted() {
        this.draw(this.selectedBeacon);
    }
}
