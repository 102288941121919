export default {
    generalStates: {
        0: {
            value: 0,
            class: "d-none",
            key: "normal"
        },
        1: {
            value: 1,
            class: "d-none",
            key: "normal"
        },
        2: {
            value: 2,
            class: "color-glyph-preemergency fad fa-exclamation-circle",
            key: "preemergency"
        },
        3: {
            value: 3,
            class: "color-glyph-emergency fad fa-exclamation-circle",
            key: "emergency"
        }
    },
    communicationStatuses: {
        0: {
            value: 0,
            class: "color-undefined",
            key: "disabled"
        },
        1: {
            value: 1,
            class: "color-normal",
            key: "readytoactivation"
        },
        2: {
            value: 2,
            class: "color-normal",
            key: "active"
        },
        3: {
            value: 3,
            class: "color-emergency",
            key: "temporaryblocked"
        }
    },
    relevanceStates: {
        0: {
            value: 0,
            class: "color-normal",
            key: "undefined"
        },
        1: {
            value: 1,
            class: "state-undefined font-weight-bold",
            key: "undefined"
        },
        2: {
            value: 2,
            class: "color-normal",
            key: "actual"
        },
        3: {
            value: 3,
            class: "state-preemergency font-weight-bold",
            key: "recent"
        },
        4: {
            value: 4,
            class: "state-emergency font-weight-bold",
            key: "outdated"
        }
    },
    channelValueStates: {
        "1": {
            value: 1,
            class: "state-undefined font-weight-bold",
            key: "undefined"
        },
        "0": {
            value: 0,
            class: "color-normal",
            key: "novalue"
        },
        "2": {
            value: 2,
            class: "color-normal",
            key: "normal"
        },
        "3": {
            value: 3,
            class: "state-emergency font-weight-bold",
            key: "emergency"
        },
        "4": {
            value: 4,
            class: "state-preemergency font-weight-bold",
            key: "preemergency"
        },
        "5": {
            value: 5,
            class: "state-preemergency font-weight-bold",
            key: "preemergency"
        },
        "6": {
            value: 6,
            class: "state-emergency font-weight-bold",
            key: "emergency"
        },
        "7": {
            value: 7,
            class: "state-preemergency font-weight-bold",
            key: "preemergency"
        },
        "8": {
            value: 8,
            class: "state-emergency font-weight-bold",
            key: "emergency"
        }
    },
    channelStates: {
        0: {
            value: 0,
            class: "color-undefined",
            key: "undefined"
        },
        1: {
            value: 1,
            class: "color-undefined",
            key: "notpollingbyserver"
        },
        2: {
            value: 2,
            class: "color-emergency",
            key: "emergency"
        },
        3: {
            value: 3,
            class: "color-preemergency",
            key: "preemergency"
        },
        4: {
            value: 4,
            class: "color-emergency",
            key: "dataoutdated"
        },
        5: {
            value: 5,
            class: "color-normal",
            key: "datarecent"
        },
        6: {
            value: 6,
            class: "color-normal",
            key: "normal"
        }
    },
    bnwasStates: {
        0: {
            value: 0,
            class: "d-none",
            key: "normal"
        },
        1: {
            value: 1,
            class: "state-emergency",
            key: "emergency"
        },
        2: {
            value: 2,
            class: "state-preemergency",
            key: "emergency"
        }
    },
    bnwasModeStates: {
        0: {
            value: 0,
            class: "state-preemergency-inverse",
            key: "undefined"
        },
        1: {
            value: 1,
            class: "state-normal-inverse",
            key: "auto"
        },
        2: {
            value: 2,
            class: "state-normal-inverse",
            key: "manual"
        },
        3: {
            value: 3,
            class: "state-emergency-inverse",
            key: "off"
        },
        4: {
            value: 4,
            class: "state-emergency-inverse",
            key: "poweroff"
        }
    }
};
