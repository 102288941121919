import { Vue, Component, Prop } from "vue-property-decorator";

import IconBase from "@/components/Icons/IconBase.vue";
import beaconIcon from "@/components/BeaconIcon";
import IConversation from "@/contracts/IConversation";

import "./ConversationCard.scss";

@Component({
    components: {
        IconBase,
        BeaconIcon: beaconIcon as any
    }
})
export default class MessengerBeaconCard extends Vue {
    @Prop()
    conversation!: IConversation;

    render() {
        return (
            <div onClick={() => this.$emit("select")} class="conversation-card">
                <div class="conversation-card__icon">
                    <beacon-icon
                        width={42}
                        height={42}
                        color={this.conversation.UserInterfaceSettings.Color}
                        icon-path={this.conversation.IconPath}
                    />
                </div>
                <div class="conversation-card__body">
                    <div class="conversation-card__header">
                        <div class="conversation-card__name">{this.conversation.Name}</div>
                        {this.conversation.LastMessage && (
                            <date-wrapper
                                class="conversation-card__message-timestamp"
                                date={this.conversation.LastMessage.SentDt}
                                format="DD.MM.YY HH:mm"
                            />
                        )}
                    </div>
                    {(this.conversation.UnreadCount > 0 || this.conversation.LastMessage) && (
                        <div class="conversation-card__footer">
                            <div class="conversation-card__message">
                                {this.conversation.LastMessage && this.conversation.LastMessage.Status === 3 && (
                                    <div class="conversation-card__avatar">
                                        <span class="conversation-card__avatar-letter">
                                            <i class="fas fa-user fa-sm" />
                                        </span>
                                    </div>
                                )}
                                <div
                                    class={[
                                        "conversation-card__message-content",
                                        this.conversation.UnreadCount > 0 &&
                                            "conversation-card__message-content__medium"
                                    ]}
                                >
                                    {this.conversation.LastMessage ? this.conversation.LastMessage.Content : null}
                                </div>
                            </div>
                            <div class="conversation-card__badge">
                                <span
                                    class={[
                                        this.conversation.UnreadCount > 0 ? "badge-primary" : "badge-none",
                                        "badge badge-circle"
                                    ]}
                                >
                                    {this.conversation.UnreadCount > 0 && <div>{this.conversation.UnreadCount}</div>}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
