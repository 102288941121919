import { loadTrack, TrackPoint } from "@/api/monitoring";
import * as types from "@/store/const/mutation-types";
import { TrackSettings } from "@/components/Map/yandex/Track";

export interface TrackData {
    Id: number;
    TrackPoints: TrackPoint[];
    Coordinates: [number, number][];
}

export const trackData: TrackData[] = [];

const state = {
    tracks: []
};

const actions = {
    async addTrack({ commit }, track: TrackSettings) {
        const data = await loadTrack(track.BeaconId, track.StartDt, track.EndDt);

        const trackCopy: TrackData = {
            Id: track.Id,
            TrackPoints: data.TrackPoints,
            Coordinates: data.Coordinates
        };

        trackData.push(trackCopy);

        if (data.TrackPoints.length > 1) {
            commit(types.ADD_TRACK, track);
        }

        return data.TrackPoints.length > 1;
    }
};

const mutations = {
    [types.ADD_TRACK](state, track) {
        state.tracks = state.tracks.concat([track]);
    },
    [types.DELETE_TRACK](state, track) {
        state.tracks = state.tracks.filter(el => el.Id !== track.Id);
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
