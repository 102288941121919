export enum ChannelFlag {
    None = 0,
    Voltage = 1,
    Charge = 2,
    Speed = 3,
    Course = 4,
    Temperature = 5,
    Altitude = 6,
    Petrol = 7,
    Flow = 8,
    BNWASAlarmFlag = 10,
    BNWASMode = 11,
    BNWASAutopilotState = 12,
    Status = 50
}

export enum ChannelType {
    Real,
    Binary,
    Enum
}

export interface IChannelInfo {
    Id: number;
    BeaconId: number;
    ChannelFlag: ChannelFlag;
    Name: string;
    ChannelType: ChannelType;
    Min: number;
    Max: number;
    Comment: string;
}
