//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations, mapState } from "vuex";

import GeoobjectCard from "@/components/Geoobjects/GeoobjectCard.tsx";

export default {
    name: "GeoobjectsBar",
    components: {
        GeoobjectCard
    },
    methods: {
        ...mapMutations("geoobjects", {
            toggleGeoobjectMonitoring: "TOGGLE_GEOOBJECT_MONITORING",
            toggleGeoobjectTracks: "TOGGLE_GEOOBJECT_TRACKS",
            hideAllGeoobjectMonitoring: "HIDE_ALL_GEOOBJECT_MONITORING",
            hideAllGeoobjectTracks: "HIDE_ALL_GEOOBJECT_TRACKS",
            showAllGeoobjectMonitoring: "SHOW_ALL_GEOOBJECT_MONITORING",
            showAllGeoobjectTracks: "SHOW_ALL_GEOOBJECT_TRACKS"
        }),
        handleToggleAll() {
            if (this.$route.name === "monitoring") {
                if (this.geoobjects.length === this.selectedIds.length) {
                    this.hideAllGeoobjectMonitoring();
                } else {
                    this.showAllGeoobjectMonitoring();
                }
            } else if (this.$route.name === "tracks") {
                if (this.geoobjects.length === this.selectedIds.length) {
                    this.hideAllGeoobjectTracks();
                } else {
                    this.showAllGeoobjectTracks();
                }
            }
        },
        handleSelectGeoobject(id) {
            if (this.$route.name === "monitoring") {
                this.toggleGeoobjectMonitoring(id);
            } else if (this.$route.name === "tracks") {
                this.toggleGeoobjectTracks(id);
            }
        }
    },
    data() {
        return {
            search: ""
        };
    },
    computed: {
        ...mapState("geoobjects", ["geoobjects", "selectedGeoobjectsIds", "tracksSelectedGeoobjectsIds"]),
        selectedIds() {
            if (this.$route.name === "monitoring") {
                return this.selectedGeoobjectsIds;
            } else if (this.$route.name === "tracks") {
                return this.tracksSelectedGeoobjectsIds;
            }
            return [];
        },
        filteredGeoobjects() {
            if (this.search) {
                return this.geoobjects.filter(el => el.Name.toLowerCase().includes(this.search.toLowerCase()));
            }
            return this.geoobjects;
        }
    }
};
