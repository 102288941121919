import http from "./http";
import store from "@/store";
import { ERROR } from "@/store/const/mutation-types";
import handleError from "./handleError";

export async function loadGeoobjects(type?: any, userId?: number) {
    try {
        return await http.get("/geoobjects", {
            params: {
                type: type,
                userId: userId
            }
        });
    } catch (error) {
        store.commit(ERROR, handleError(error));
        throw error;
    }
}

export async function createGeoobject(geoobject) {
    try {
        return await http.post("/geoobjects", geoobject);
    } catch (error) {
        store.commit(ERROR, handleError(error));
        throw error;
    }
}

export async function updateGeoobject(geoobject) {
    return http.put("/geoobjects/" + geoobject.Id, geoobject).catch(error => {
        store.commit(ERROR, handleError(error));
    });
}

export async function deleteGeoobject(id) {
    return http.delete("/geoobjects/" + id).catch(error => {
        store.commit(ERROR, handleError(error));
    });
}
