/* eslint-disable */
export default {
    default: {
        settings: "Settings",
        operationsuccess: "The operation successful!",
        operationfailed: "The operation failed!",
        warning: "Warning!",
        edit: "Edit",
        ok: "OK",
        back: "Back",
        cancel: "Cancel",
        save: "Save",
        search: "Search...",
        reset: "Discard",
        apply: "Apply",
        nodata: "No data",
        startTime: "Start time",
        endTime: "End time",
        addParameter: "Add parameter",
        minimumSpeedKnots: "Minimum speed, knots",
        minimumDistanceM: "Minimum distance, m",
        BNWASReportName: "Name",
        BNWASDistance: "Distance",
        BNWASRunningTime: "Running time",
        BNWASStatusNormalTime: "Status normal, time",
        BNWASStatusNormalPercentage: "Status normal, %",
        BNWASAlarmStatusTime: "Alarm status, time",
        BNWASAlarmStatusPercentage: "Alarm status, %",
        dateshortcuts: {
            twodays: "2 days",
            week: "Week",
            month: "Month",
            threemonths: "3 months"
        },
        maxlength: "Max length: {length} characters",
        required: "The field can not be empty",
        unsavedData: "All unsaved data will be lost. Continue?",
        invalid: "Invalid characters entered",
        invalidPhone: "Invalid phone number entered",
        beacons: "Beacons",
        sensors: "Sensors",
        delete: "Delete",
        thisActionCannotBeUndoneDelete: "This action cannot be undone! Delete?",
        confirmDelete: 'Delete "{name}"?',
        beacon: "Object",
        object: "Object",
        sensor: "Sensor",
        reportType: "Report type",
        reportTemplates: "Reports settings",
        enterReportName: "Enter report name",
        reportName: "Report name",
        noData: "Nothing to show",
        preferredCommunicationType: "Communication channel to use",
        currentCommunicationType: "Last communication through:",
        name: "Name",
        comment: "Comment",
        actions: "Actions",
        icon: "Icon",
        color: "Color",
        activate: "Activate",
        interval: "Interval",
        update: "Update",
        sorting: {
            nameaz: "By name; A-Z",
            nameza: "By name; Z-A",
            datedesc: "By date; Recent first",
            dateasc: "By date; Recent last"
        },
        model: "Model",
        selectAll: "Select all",
        build: "Build",
        timeline: "Timeline",
        add: "Add",
        integrations: "Integrations",
        timestamp: "Time"
    },
    reportIntervals: {
        twoDays: "2 days",
        week: "7 days",
        month: "30 days"
    },
    integrations: {
        fd: "Norwegian Directorate of Fisheries",
        fdDisable: "Disable Sporing",
        fdEnable: "Enable Sporing"
    },
    Models: {
        Undefined: "Undefined",
        Mini: "Lookout Mini",
        Standard: "Lookout Standard",
        Pro: "Lookout Pro",
        ProEPS: "Lookout ProEPS",
        Fishery: "Lookout Fishery",
        Marine: "Lookout Marine",
        Compact: "Lookout Compact",
        Marine2: "Lookout Marine 2.0",
        Lite: "Lookout Lite"
    },
    parameterState: {
        notSent: "-",
        waiting: "Waiting to be sent",
        sent: "Sent",
        sentToIridium: "Sent to Iridium",
        sentAndConfirmed: "Sent and confirmed",
        error: "Error"
    },
    parameterCardHeader: {
        lastSend: "Last sent time:",
        state: "State:",
        resetToDefault: "Set default values",
        send: "Send"
    },
    exchangeModeConfigurationParameter: {
        title: "Data exchange settings ",
        exchangeMode: "Mode",
        startTime: "Starting point (UTC)",
        interval: "Interval",
        schedule: "Schedule"
    },
    statusConfigurationParameter: {
        title: "Statuses and navigation message settings",
        statusesSettings: "Statuses settings",
        navMessageSettings: "Navigation message settings",
        HardReset: "Hardware reset (power on)",
        SoftReset: "Software reset",
        TimerReset: "Reboot after watchdog timeout",
        CaseOpen: "Case opened",
        CaseClose: "Case closed",
        GPSNotAvailable: "GPS/GLONASS signal not available",
        IridiumNotAvailable: "Iridium unavailable",
        GSMNotAvailable: "GSM unavailable",
        LowBattery: "Low battery",
        MountUninstall: "Removing from the mount",
        MountInstall: "Mounting",
        PowerConnected: "External power connected",
        PowerDisconnected: "External power disconnected",
        ExternalVoltageLow: "External voltage low",
        ExternalVoltageHigh: "External voltage high",
        ExternalVoltageNormal: "External voltage normal",
        InternalVoltageLow: "Battery voltage low",
        InternalVoltageNormal: "Battery voltage normal",
        BoardTempLow: "Board temperature low",
        BoardTempHigh: "Board temperature high",
        BoardTempNormal: "Board temperature normal",
        MessageFormat: "Main navigation message format"
    },
    channelsConfigParameter: {
        title: "Analog and digital inputs configuration",
        measurement: "Measured analog value",
        analogMode: "Analog input data collection mode",
        analogPeriod: "Analog input data collection period",
        discreteMode: "Digital input data collection mode",
        discretePeriod: "Discrete input data collection period"
    },
    collectModeParameter: {
        title: "Data collection period configuration",
        collectStartTime: "Collection interval start time (UTC)",
        collectInterval: "Collection interval"
    },
    parameters: {
        CustomCommand: "Command in HEX (length is a multiple of 2, e.g. ABFF78)",
        SSASDisableAlarm: "Cancellation of the SSAS combat alert",
        SSASOptionalString: "Configuration of the optional part of the SSAS message",
        LRITConfiguration: "MMSI for LRIT",
        LRITEnable: "Enabling/disabling RTDR data transmission",
        LRITPeriod: "LRIT Transmission Period",
        Poll: "Poll current coordinates",
        CurrentCoordinates: "Coordinate request",
        MaximumSendingTime: "Maximum time for sending data",
        DataChannel: "Data channel",
        GPSPredictiveStartTime: "Proactive start time of the GPS module",
        FactoryReset: "Factory Reset",
        ClearArchives: "Clear archives",
        Reboot: "Reboot",
        ForcedDataSending: "Forced data sending",
        ModbusPeriod: "Modbus sensor polling period",
        OnlineModeSendPeriod: "Data sending period for online mode",
        OnlineModeCollectPeriod: "Data collecting period for online mode",
        ProtocolVersion: "Send protocol version",
        FirmwareVersion: "Send firmvare version",
        DataSendingPeriod: "Data sending period",
        DataReceivingPeriod: "Data receiving period (from server)",
        CoordinateReceivingPeriod: "Data collecting period",
        GPSValidSessionTime: "GPS session hold time before fixing coordinates",
        OperationMode: "Operation mode",
        IntermediateCoordinates: "Use data collect period",
        CollectAndSendData: "Data sending",
        Compression: "Data compression",
        AccelerationLevel: "Acceleration level for movement detection",
        SimSelection: "SIM card",
        AdaptiveMode: "Adaptive data analysis",
        ForcedMode: "Forced data sending",
        BatteryLife: "Battery lifetime",
        InternalSensors: "Internal sensors data sending",
        StatusConfiguration: "Status messages configuration",
        ModbusConfiguration: "Modbus Sensor Configuration",
        ModbusCollectModeConfiguration: "Data collection period from Modbus sensors"
    },
    map: {
        stop: "Stop",
        parking: "Parking"
    },
    errors: {
        auth: {
            failed: "Wrong password or user name",
            blocked: "Account is blocked",
            notActivated: "Email is not confirmed"
        },
        error: "Something went wrong.",
        mainPage: "Main page",
        internalServerError: "Error on the server. Please try to repeat your request later.",
        serverUnavailable: "Server is unavailable. Please try again later."
    },
    login: {
        title: "Sign in to your account",
        username: "Username",
        password: "Password",
        demo: "Demo",
        resetpassword: "Forgot your password?",
        register: "Register",
        login: "Sign in",
        wrongcredentials: "Wrong username or password",
        blocked: "Account is blocked",
        emailnotconfirmed: "Email is not confirmed",
        internalerror: "Error on server",
        serverunavailable: "Server is unavailable"
    },
    resetpassword: {
        title: "Reset Password",
        username: "Login",
        email: "Email",
        enterusername: "Enter login",
        enteremail: "Enter email",
        resetPassword: "Reset password"
    },
    register: {
        title: "Register",
        username: "Username",
        email: "Email",
        password: "Password",
        enterusername: "Enter username",
        usernamenotfree: "Username is taken",
        enteremail: "Enter email",
        entervalidemail: "Enter valid email",
        enterpassword: "Enter password",
        passwordlength: "The password must be at least 6 characters in length",
        cancel: "Cancel",
        enterprise: "Enterprise",
        individual: "Individual",
        name: "How can we call You?",
        phone: "Phone",
        organization: "Organization",
        country: "Country",
        timezone: "Timezone",
        createaccount: "Create an account",
        toauth: "To Login page",
        success: "Account has been successfully created",
        successtext: "To complete the registration, click on the link we sent to the email address you provided.",
        fail: "Account is not created",
        failtext: "Sorry, there was an error on the server and the account was not created. Please try again.",
        usernametaken: "Username is taken",
        datasending: "Data sending..."
    },
    profile: {
        balance: "Balance: {balance} rub",
        makepayment: "Refill account",
        lengthunit: "Distance unit",
        timezone: "Timezone",
        locale: "Interface language",
        cardSize: "Beacon card size",
        showPositionOnLoad: "Show all beacons on the map on start",
        password: "Password",
        oldpassword: "Old password",
        oldpasswordvalidation: "Enter old password",
        newpassword: "New password",
        newpasswordvalidation: "Enter new password",
        repeatnewpassword: "Repeat new password",
        changepassword: "Change",
        personalinfo: "Info",
        enterprise: "Enterprise",
        individual: "Individual",
        country: "Country",
        name: "How can we call You?",
        phone: "Phone",
        organization: "Organization",
        passwordchangesuccess: "Password changed successfully!",
        settingsupdatedsuccess: "Saved successfully!",
        passwordlength: "The password must be at least 6 characters. Max 50 characters",
        passwordmustbeequal: "Passwords must be equal",
        wrongoldpassword: "Wrong old password"
    },
    channelstable: {
        nochannels: "No sensors"
    },
    beaconactivation: {
        title: "Activate beacon",
        activationcode: "Activation code",
        emptyvalidation: "The field can not be empty",
        imeivalidation: "IMEI must contain {count} characters",
        activationcodevalidation: "Activation code must contain {count} characters",
        activationsuccess: "This Beacon has been successfully activated",
        failtitle: "Error!",
        faildescription: "Beacon activation error, check the data entered!",
        notready: "Warning!",
        notreadydescription: "At the moment, this beacon is not available for the activation."
    },
    beaconcard: {
        objectcard: "Object card"
    },
    beaconsettings: {
        CheckRelevance: "Check relevance",
        CheckEmergency: "Check emergency",
        Emergency: "Emergency situations",
        EmergencyOverRelevancePriority: "Emergency priority over data relevance",
        LengthUnit: "Distance unit",
        VehicleType: "Type of transport",
        messenger: "Messenger",
        client: "Client",
        settings: "General settings",
        updateProgrammFiles: "Software update",
        firmwareSize: "Firmvare size",
        addingTime: "Adding time",
        timeLastOperation: "time of last operation",
        status: "status",
        successfullyCompleted: "Successfully completed",
        error: "Error",
        nothingToDo: "Nothing to do",
        waitingBeaconCommunicate: "Waiting for the beacon to communicate",
        description: "Description",
        queue: "Queue",
        cancelSending: "Cancel sending",
        delete: "delete",
        selectFile: "Select a file",
        settingsSendingCommandsToIridium: "Settings for sending commands to Iridium",
        specification: "Specification",
        iridiumGatewayHost: "Iridium gateway host",
        iridiumGatewayPort: "Iridium gateway port"
    },
    channelsettings: {
        impactonbeaconstatus: "Used in determining the state of the beacon",
        impactonbeaconlastupdate: "Used when determining the beacon updating time",
        precision: "Precision (decimal places)",
        padleadingzeros: "Pad leading zeros",
        determineemergencystatus: "Identify emergency situations",
        emergencystatus: "Emergency situations",
        lowerpreemergencythreshold: "Lower preemergency threshold",
        upperpreemergencythreshold: "Upper preemergency threshold",
        loweremergencythreshold: "Lower emergency threshold",
        upperemergencythreshold: "Upper emergency threshold",
        binarychannelvalue0: 'The textual designation of the value "0"',
        binarychannelstatus0: 'Status for the value "0"',
        binarychannelvalue1: 'The textual designation of the value "1"',
        binarychannelstatus1: 'Status for the value "1"'
    },
    datarelevance: {
        datarelevance: "Data relevance",
        usebeacontimeouts: "Use beacon timeouts",
        relevancetimeout1: "Relevance timeout 1",
        relevancetimeout2: "Relevance timeout 2",
        hour: "h ",
        minute: " min"
    },
    settingscards: {
        beaconsettings: "Beacon Settings",
        remotecontrol: "Remote control",
        card: "Object card",
        beaconnotifications: "Notifications"
    },
    datepicker: {
        from: "From",
        to: "To",
        fuelReport: "Fuel report"
    },
    tracks: {
        beaconvalidation: "Choose object",
        periodvalidation: "Set the interval",
        linewidth: "Line Thickness",
        draw: "Build track",
        notracks: "No tracks",
        nodata: "For the specified period, there is no data"
    },
    geoobjects: {
        nodescription: "No description",
        // geoobjectseditor
        namevalidation: "Please enter the name of the geoobject!",
        type: "Type",
        linewidth: "Line Thickness",
        // geoobjectspanel
        nogeoobjects: "No geoobjects",
        // geoobjectsbar
        showall: "Show all",
        hideall: "Hide all"
    },
    notifications: {
        // Notification Card
        BeaconConnectionState: "Connection state",
        BeaconCommandState: "Command send state",
        RealChannelState: "Sensor state",
        RealChannelStateWithCustomThresholds: "Sensor state",
        BinaryChannelState: "Sensor state",
        BeaconLocationInGeoobject: "Geoobjects",
        BNWASEmergencyState: "Status of SKDVP",
        LastOnline: "Beacon offline",
        // Notification Bar
        markasread: "Mark as read",
        loadmore: "Load more",
        nonotifications: "No notifications",
        NoNotificationsToLoad: "No notifications to load",
        nonewnotifications: "No new notifications",
        all: "All",
        unread: "Unread"
    },
    menu: {
        profile: "Profile",
        logout: "Logout",
        monitoring: "Monitoring",
        tracks: "Tracks",
        geoobjects: "Geoobjects",
        history: "History",
        management: "Management",
        reports: "Reports",
        docs: "User guide",
        notifications: "Notifications",
        feedback: "Feedback",
        messages: "Messages",
        bnwas: "Report BNWAS"
    },
    channelswidget: {
        value: "Value",
        time: "Time",
        timereceived: "Received time",
        choosebeacon: "No beacon selected",
        fuelLevelMonitoring: "Fuel level monitoring",
        fuelConsumptionMonitoring: "Fuel c onsumption monitoring"
    },
    monitoringwidget: {
        groupsettings: "Groups settings",
        folders: "Show as list with groups",
        list: "Show as list"
    },
    infoWidget: {
        diagnostic: "Diagnostic messages",
        mainInfo: "Main info",
        location: "Location",
        transportCard: "Transport card",
        iridiumModule: "Iridium module",
        gsmModule: "GSM module",
        bluetoothModule: "Bluetooth module",
        serialNumber: "Serial number",
        iridiumImei: "Iridium IMEI",
        gsmImei: "GSM IMEI",
        sim1: "SIM 1",
        sim2: "SIM 2",
        owner: "Owner",
        coordinates: "Coordinates",
        time: "Timestamp",
        packetDt: "Packet timestamp",
        sensors: "Sensors",
        fuelControl: "Fuel control",
        charts: "Charts",
        events: "Events",
        fuelDailyConsumtion: "Fuel daily consumption",
        locationInGeoObjects: "Location in geo objects",
        buildReport: "Build report"
    },
    groupseditor: {
        add: "Add",
        editgroup: "Edit group",
        deleteconfirm: "Delete group?"
    },
    notificationssettings: {
        EditTitle: "Editing notification",
        StandardNotifications: "Standard notifications",
        ConnectionState: "Notify when beacon connected",
        CommandState: "Notify when command sent",
        BNWASState: "BNWAS state",
        GeoobjectsNotifications: "Geozone notifications",
        ChannelsNotifications: "Sensor notifications",
        LastOnline: "Beacon offline for a specified time notification",
        // breadcrumb
        notificationssettings: "Notifications settings",
        //
        deleteconfirm: "Delete notification?"
    },
    notificationeditor: {
        connectionEstablished: "Connection established",
        connectionClosed: "Connection closed",
        geozones: "Geozone",
        notifywhen: "Notify when",
        // geozones
        ingeozone: "Enter geozone",
        outofgeozone: "Exit geozone",
        // commands
        sendingcommand: "Command was sent",
        confirmcommand: "Command was confirmed",
        // sensors
        sensors: "Sensors",
        usechannelthresholds: "Use sensor thresholds",
        normalstate: "Normal state",
        preemergencystate: "Preemergency state",
        emergencystate: "Emergency state",
        incorrectstate: "Incorrect state",
        interval: "Interval",
        // binary sensors
        zerotoone: 'Received "0"',
        onetozero: 'Received "1"',
        zerotooneandonetozero: 'Received "0" or "1"',
        notify: "Notify",
        app: "In app",
        email: "By email",
        appandemail: "By email and in the app",
        emails: "Emails",
        // last online
        lastonline: "Notify when beacon offline for more than a specified number of minutes:"
    },
    feedback: {
        title: "Feedback",
        rate: "How do you rate LookOut?",
        overall: "Overall experience",
        design: "Design",
        functionality: "Functionality",
        easeofuse: "Ease of use",
        leavecomment: "Please leave a comment",
        send: "Send"
    },
    remotecontrol: {
        remotecontrol: "Remote control"
    },
    enums: {
        cardSizes: {
            small: "Small",
            normal: "Default"
        },
        binaryChannelStatuses: {
            normal: "Normal",
            emergency: "Emergency"
        },
        reporttypes: {
            beaconsReport: "On beacons",
            channelsReport: "On channels",
            beaconDataReport: "Position",
            channelDataReport: "Sensors data",
            emergenciesReport: "Emergencies",
            beaconsDataPacketsReport: "Traffic"
        },
        NotificationTypes: {
            BeaconConnectionState: "Connection state",
            BeaconCommandState: "Commands state",
            RealChannelState: "Sensor state",
            RealChannelStateWithCustomThresholds: "Sensor state",
            BinaryChannelState: "Sensor state",
            BeaconLocationInGeoobject: "Geoobjects",
            BNWASEmergencyState: "BNWAS state",
            LastOnline: "Beacon offline"
        },
        lengthUnits: {
            m: "Meter",
            km: "Kilometer",
            mile: "Mile",
            knot: "Knot"
        },
        transporttypes: {
            none: "Other",
            air: "Air",
            rail: "Rail",
            water: "Water",
            truck: "Truck",
            special: "Special",
            car: "Car",
            container: "Container"
        },
        timezones: {
            gmtminus12: "(UTC -12:00) Date change line, Aleutian Islands",
            gmtminus10: "(UTC -10:00) Hawaii",
            gmtminus9: "(UTC -09:00) Alaska",
            gmtminus8: "(UTC -08:00) Lower California, Pacific Time (USA and Canada)",
            gmtminus7: "(UTC -07:00) Arizona, Mountain Time (USA and Canada), La Paz, Mazatlan, Chihuahua",
            gmtminus6: "(UTC -06:00) Central America, Easter Island, Saskatchewan, Mexico City, Monterrey",
            gmtminus5: "(UTC -05:00) Eastern time (USA and Canada), Havana, Haiti, Bogota, Quito",
            gmtminus4: "(UTC -04:00) Atlantic Time (Canada), Santiago, La Paz, Caracas",
            gmtminus3: "(UTC -03:00) Brazil, Greenland, Montevideo, El Salvador",
            gmtminus2: "(UTC -02:00) Time in UTC-02 format",
            gmtminus1: "(UTC -01:00) Azores, Cape Verde",
            gmt0: "(UTC) Dublin, Edinburgh, Lisbon, London, Monrovia, Reykjavik",
            gmt1:
                "(UTC +01:00) Amsterdam, Berlin, Rome, Stockholm, Budapest, Prague, Paris, Sarajevo, Western Central Europe",
            gmt2:
                "(UTC +02:00) Kaliningrad, Athens, Bucharest, Beirut, Sector-Gaza, Tallinn, Helsinki, Windhoek, Damascus, Jerusalem, Chisinau, Tripoli",
            gmt3: "(UTC +03:00) Moscow, St. Petersburg, Minsk, Baghdad, Nairobi, Istanbul, Kuwait",
            gmt4: "(UTC +04:00) Astrakhan, Baku, Yerevan, Izhevsk, Port Louis, Tbilisi",
            gmt5: "(UTC +05:00) Yekaterinburg, Ashgabat, Tashkent, Islamabad, Karachi",
            gmt6: "(UTC +06:00) Astana, Dhaka, Omsk",
            gmt7: "(UTC +07:00) Krasnoyarsk, Novosibirsk, Bangkok, Hanoi, Barnaul, Khovd",
            gmt8: "(UTC +08:00) Irkutsk, Hong Kong, Beijing, Kuala Lumpur, Singapore, Perth, Ulaanbaatar",
            gmt9: "(UTC +09:00) Yakutsk, Chita, Osaka, Tokyo, Seoul",
            gmt10: "(UTC +10:00) Vladivostok, Guam, Port Moresby, Canberra, Melbourne, Sydney, Hobart",
            gmt11: "(UTC +11:00) Magadan, Bougainville Island, Norfolk Island, Sakhalin, Solomon Islands, Chokurdakh",
            gmt12: "(UTC +12:00) Anadyr, Petropavlovsk-Kamchatsky, Wellington, Oakland, Fiji",
            gmt13: "(UTC +13:00) Samoa, Nukualofa",
            gmt14: "(UTC +14:00) Kiritimati Island"
        },
        groups: {
            // без группировки
            beacons: "Beacons",
            // личные группы
            nogroup: "Without group"
        },
        geometrytypes: {
            Polygon: "Polygon",
            Point: "Point"
        },
        analogFlag: {
            voltage: "Voltage",
            current: "Current"
        },
        analogMode: {
            dontSaveData: "do not save data from analog inputs",
            saveDataWithCoordinates: "save the data at the same time as the coordinate",
            saveDataWithInterval: "save data according to the period (mode 2)"
        },
        discreteMode: {
            dontSaveData: "do not save data from digital inputs",
            saveDataWithCoordinates: "save the data at the same time as the coordinate",
            saveDataWithInterval: "save data according to the period (mode 2)",
            saveDataWithStateChange: "save data on the change of the logical state"
        },
        ExchangeMode: {
            Interval: "Interval",
            Schedule: "Schedule"
        },
        DataChannel: {
            OnlyIridium: "Iridium only",
            OnlyGSM: "GSM only",
            PriorityIridium: "Iridium priority",
            PriorityGSM: "GSM priority"
        },
        nmeaMode: {
            nmeaOn: "NMEA broadcast mode enabled",
            nmeaOff: "NMEA broadcast mode disabled",
            adaptiveMode:
                "adaptive mode (when the external power is connected, the NMEA offers are broadcast, when the external power is turned off, the broadcast stops)"
        },
        navMessageFormat: {
            onlyCoordAndTime: "only coordinates and time",
            dynamicFormation: "dynamic package formation"
        },
        dataChannel: {
            onlyIridium: "only Iridium",
            onlyGSM: "only GSM",
            priorityIridium: "priority Iridium",
            priorityGSM: "priority GSM"
        },
        modulesAvailability: {
            true: "Yes",
            false: "No"
        },
        exportTypes: {
            html: "HTML",
            xlsx: "XLSX"
        }
    },
    reports: {
        type: "Report type",
        reports: "Reports",
        create: "Create",
        saveTemplate: "Save",
        loadTemplate: "Load",
        noTemplates: "No templates",
        chooseReportOrCreateNew: "Choose templates or create new",
        editor: {
            templateName: "Temlate Name",
            object: "Object",
            sensors: "Sensors",
            interval: "Interval",
            intervalType: "Interval type",
            fixedInterval: "Fixed interval",
            daysInterval: "Days",
            daysBeforeCurrent: "Days",
            buildWithoutSaving: "Build",
            creatingReport: "Creating report",
            transactions: "Transactions:"
        },
        report: {
            templateName: "Template name",
            reportType: "Report type",
            intervalType: "Interval type",
            interval: "Interval",
            object: "Object:",
            sensor: "Sensor:",
            sensorType: "Sensor type:"
        },
        viewer: {
            object: "Object(s)",
            sensors: "Sensors",
            interval: "Interval",
            daysBeforeCurrent: "Interval: {days} day | Interval: {days} days",
            buildReport: "Build",
            templateName: "Template name",
            noInterval: "No interval",
            closeReport: "Close",
            fullscreen: "Full screen"
        }
    },
    messenger: {
        placeholder: "Enter your message...",
        client: "Client {number}",
        chatLog: {
            loadMore: "Load more...",
            noMsgs: "No messages"
        },
        messageStatus: {
            sending: "Sending",
            sentToBeacon: "Sent to object",
            sendError: "Send failed"
        }
    },
    payment: {
        fillBalance: "Refill balance",
        balance: "Balance: {balance} rub",
        sumToPay: "Amount to be paid",
        fill: "Refill",
        sumToPayRequired: "Please, insert the amount to refill your balance"
    },
    paymentsuccess: {
        header: "The balanced is refilled",
        description:
            "Within a half an hour the money will be credited to your account in the service. Payment for services will resume automatically.",
        toMainPage: "To the main page",
        toProfile: "To the personal account page"
    },
    paymenterror: {
        header: "Unable to transfer the money",
        description: "Please contact our technical support specialist for more information.",
        toMainPage: "To the main page",
        toProfile: "To the personal account page"
    },
    bnwas: {
        poweroff: "BNWAS power off",
        off: "BNWAS off",
        confirm: "Confirm BNWAS",
        interfaceerror: "No BNWAS data",
        bnwas: "BNWAS"
    },
    StatusEvent: {
        RebootHardware: "Hardware reboot",
        RebootSoftware: "Software reboot",
        RebootWatchdog: "Watchdog reboot",
        GPSFault: "GPS fault",
        IridiumFault: "Iridium fault",
        GSMFault: "GSM fault",
        FlashFault: "Flash fault",
        AccelerometerFault: "Accelerometer fault",
        TemperatureFault: "Temperature sensor fault",
        SimFault: "SIM fault",
        VoltageFault: "Incorrect voltage",
        RadioFault: "Radio module fault",
        CaseOpen: "Case opened",
        CaseClose: "Case closed",
        VoltageLow: "Low voltage",
        MountRemove: "Removing from the mount",
        MountInstall: "Mounted",
        ExternalPowerConnected: "External power connected",
        ExternalPowerDisconnected: "External power disconnected",
        SOS: "SOS",
        BNWASUndefined: "BNWAS status not defined",
        BNWASPowerOff: "BNWAS power off",
        BNWASOff: "BNWAS off",
        ExternalVoltageLow: "Low external voltage",
        ExternalVoltageHigh: "High external voltage",
        InternalVoltageLow: "Low battery voltage",
        BoardTempLow: "Low board temperature",
        BoardTempHigh: "High board temperature",
        InternalVoltageNormal: "Normal battery voltage",
        ExternalVoltageNormal: "Normal external voltage",
        BoardTempNormal: "Normal board temperature",
        IridiumUnavailable: "Iridium unavailable",
        GSMUnavailable: "GSM unavailable",
        PowerOn: "Power Up",
        PowerOff: "Power Off",
        GPSAntennaBlockage: "GPS antenna blockage"
    },

    diagnosticMessage: {
        FirmwareVersion: "Firmware version",
        Voltage: "Voltage",
        ExternalVoltage: "External voltage",
        ProcessorVoltage: "Processor voltage",
        ProcessorTemp: "Processor temp",
        DataTransferPeriod: "Data transfer period",
        DataTransferStartTime: "Data transfer start time",
        DataTransferChannel: "Data transfer channel",
        GNSSSessionTime: "GNSS session time",
        DataTransferSessionTime: "Data transfer session time",
        CoordinateAcquisitionPeriod: "Coordinate acquisition period",
        GSMPollPeriod: "GSM Poll period",
        ExternalVoltageUpperLimit: "External voltage upper limit",
        ExternalVoltageLowerLimit: "External voltage lower limit",
        StoredCoordsCount: "Stored coords count",
        CurrentCoordsCount: "Current coords count",
        StatusMessagesCount: "Status messages count",
        GPSWithNonZeroSignalCount: "GPS with non zero signal count",
        GPSWithGoodSignalCount: "GPS with good signal count",
        GPSPdop: "GPS PDOP",
        GSMImei: "GSM IMEI",
        GSMCcid: "GSM SSID",
        GsmCsq: "GSM CSQ",
        GsmSessionResult: "GSM session result",
        IridiumImei: "Iridium IMEI",
        IridiumCsq: "Iridium CSQ",
        IridiumSessionResult: "Iridium session result",
        GSMFault: "GSM Fault",
        IridiumFault: "Iridium Fault",
        SIMFault: "SIM Fault",
        GPSFault: "GPS Fault",
        FlashFault: "Flash Fault"
    }
};
