import http from "./http";
import store from "@/store";
import * as types from "@/store/const/mutation-types";
import handleError from "./handleError";

import IMessageOutput from "@/contracts/IMessageOutput";
import IConversation from "@/contracts/IConversation";
import { IMessage } from "@/contracts/IMessage";

export async function loadMessages(
    beaconId: number,
    takeCount?: number,
    skipCount?: number,
    timestamp?: string
): Promise<IMessage[]> {
    try {
        const { data } = await http.get<IMessage[]>(`/conversations/${beaconId}/messages`, {
            params: {
                skipCount,
                takeCount,
                timestamp
            }
        });
        return data;
    } catch (error) {
        store.commit(types.ERROR, handleError(error));
        throw error;
    }
}

export async function sendMessage(beaconId: number, message: IMessageOutput): Promise<IMessage> {
    try {
        const { data } = await http.post<IMessage>(`/conversations/${beaconId}/messages`, message);
        return data;
    } catch (error) {
        store.commit(types.ERROR, handleError(error));
        throw error;
    }
}

export async function markMessagesAsRead(beaconId: number, timestamp: string): Promise<void> {
    try {
        await http.put(`/conversations/${beaconId}/messages.markAsRead`, null, {
            params: {
                timestamp
            }
        });
    } catch (error) {
        store.commit(types.ERROR, handleError(error));
    }
}

export async function loadUnreadMessagesCount(beaconId: number): Promise<number> {
    try {
        const { data } = await http.get<number>(`/conversations/${beaconId}/messages.getUnreadCount`);
        return data;
    } catch (error) {
        store.commit(types.ERROR, handleError(error));
        throw error;
    }
}

export async function loadConversations(): Promise<IConversation[]> {
    try {
        const { data } = await http.get<IConversation[]>(`/conversations`);
        return data;
    } catch (error) {
        store.commit(types.ERROR, handleError(error));
        throw error;
    }
}
