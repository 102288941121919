import Plotly from "@/Plotly";
import plotlyOptions from "@/const/PlotlyOptions";
import colors from "./ChartColors";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import ChartData from "@/contracts/IChartData";
import { PlotlyHTMLElement } from "plotly.js/lib/core";
import "./Chart.scss";
import { ChannelType } from "@/contracts/IChannelInfo";
import dayjs from "dayjs";
import Empty from "@/components/Core/Empty";
import ResizeObserver from "resize-observer-polyfill";

@Component({
    components: {
        Empty
    }
})
export default class Chart extends Vue {
    private visible: boolean = true;
    private traces: any[] = [];

    @Prop()
    private timestamp!: Date | string | null;

    @Prop()
    private clickEvent!: boolean;

    @Prop()
    private chartData!: ChartData;

    @Prop()
    private loading!: boolean;

    @Watch("chartData", { immediate: true })
    public onChartDataChanged() {
        if (this.chartData) {
            this.drawChart();
        }
    }

    private toggleAllTraces(id) {
        (this.$refs.chart as any).data.forEach((data, index) => {
            if (index === id) {
                data.visible = true;
            } else if (this.visible) {
                data.visible = false;
            } else {
                data.visible = true;
            }
        });
        this.visible = !this.visible;
        Plotly.redraw(this.$refs.chart as Element);
    }

    private toggleTrace(id: number) {
        if ((this.$refs.chart as any).data[id].visible === true) {
            (this.$refs.chart as any).data[id].visible = false;
        } else if ((this.$refs.chart as any).data[id].visible === false) {
            (this.$refs.chart as any).data[id].visible = true;
        }
        Plotly.redraw(this.$refs.chart as Element);
    }

    private time: Date | string | null = null;

    @Watch("timestamp")
    public onTimestampChanged(timestamp) {
        this.time = timestamp;
    }

    @Watch("time")
    public onTimeChanged(timestamp) {
        const time = dayjs(timestamp);
        const timeRange = [time.add(-3, "hour").toDate(), time.add(3, "hour").toDate()];

        if (this.chartData && timestamp) {
            let update = {
                xaxis: {
                    range: timeRange
                },
                shapes: [
                    {
                        type: "line",
                        xref: "x",
                        yref: "paper",
                        x0: time.toDate(),
                        y0: 0,
                        x1: time.toDate(),
                        y1: 1,

                        line: {
                            color: "rgba(0, 0, 0, 0.5)",
                            width: 1,
                            dash: "dash"
                        }
                    }
                ]
            };
            Plotly.relayout(this.$refs.chart as Element, update);
        }
    }

    private drawChart() {
        let id = 0;
        this.traces = [];
        this.chartData.ChartChannelData.forEach(channelData => {
            const trace = {
                id: channelData.Id,
                x: channelData.Timestamps,
                y: channelData.Values,
                customdata: channelData.Coordinates,
                name: channelData.Name,
                hoverinfo: "x+text",
                mode: "lines+markers",
                visible: true,
                line: {
                    color: colors[id],
                    shape: channelData.ChannelType === ChannelType.Real ? "linear" : "hv"
                },
                text: channelData.FormattedValues,
                type: "scattergl",
                marker: { size: 5 },
                connectgaps: true
            };
            id++;
            if (id > 20) {
                id = 0;
            }
            this.traces.push(trace);
        });
        this.$nextTick(() => {
            const height = this.$el.clientHeight - (this.$refs.legend as Element).clientHeight - 5;
            const locale = localStorage.getItem("locale");
            Plotly.newPlot(
                this.$refs.chart as Element,
                this.traces,
                plotlyOptions.getLayout(height),
                plotlyOptions.getOptions(locale || "ru")
            );
            if (this.clickEvent) {
                (this.$refs.chart as PlotlyHTMLElement).on("plotly_click", this.onChartClick.bind(this));
            }
        });
    }

    private onChartClick(data) {
        if (data.points[0].customdata.Latitude && data.points[0].customdata.Longitude) {
            this.time = dayjs(data.points[0].x).toDate();
            let time = dayjs(data.points[0].x).format("YYYY-MM-DDTHH:mm:ss");
            this.$emit("chartClick", {
                Latitude: data.points[0].customdata.Latitude,
                Longitude: data.points[0].customdata.Longitude,
                Timestamp: time
            });
        }
    }

    private resizeObserver: ResizeObserver | null = null;

    public mounted() {
        this.resizeObserver = new ResizeObserver(() => {
            if (this.chartData) {
                this.$nextTick(() => {
                    Plotly.Plots.resize(this.$refs.chartContainer);
                });
            }
        });

        this.resizeObserver.observe(this.$refs.chartContainer as Element);
    }

    public beforeDestroy() {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
        Plotly.purge(this.$refs.chart as HTMLElement);
    }

    public render() {
        return (
            <div ref="chartContainer" class="d-flex flex-grow-1 flex-column-reverse" vLoading={this.loading}>
                {this.chartData ? (
                    <div class="ChartLegend" ref="legend">
                        <div class="ChartLegendScrollContainer">
                            <div class="ChartLegendWrapper">
                                {this.traces.map((sensor, index) => (
                                    <div
                                        onClick={() => this.toggleTrace(index)}
                                        onDblclick={() => this.toggleAllTraces(index)}
                                        key={sensor.id}
                                        class={[
                                            "ChartLegendItem",
                                            sensor.visible === false && "ChartLegendItemDisabled"
                                        ]}
                                    >
                                        <div
                                            class="flex-shrink-0 mr-2"
                                            style={{
                                                backgroundColor: sensor.line.color,
                                                width: "10px",
                                                height: "10px",
                                                borderRadius: "50%"
                                            }}
                                        />
                                        <div
                                            style="user-select: none;"
                                            class={[
                                                sensor.visible === false && "text-muted",
                                                "font-size-s text-left flex-shrink-0"
                                            ]}
                                        >
                                            {sensor.name}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ) : null}
                <div class={[!this.chartData && "d-none"]} ref="chart" />
                {!this.chartData && !this.loading ? (
                    <empty icon="fad fa-analytics fa-5x" text={this.$t("default.nodata")} />
                ) : null}
            </div>
        );
    }
}
