import { loadNotificationsCount } from "@/api/notifications";

import * as types from "@/store/const/mutation-types";

const state = {
    unreadNotificationsCount: 0
};

const getters = {};

const actions = {
    async updateUnreadNotificationsCount({ state, commit }) {
        let count = await loadNotificationsCount(true);
        commit(types.SET_UNREAD_NOTIFICATIONS_COUNT, count);
    }
};

const mutations = {
    [types.SET_UNREAD_NOTIFICATIONS_COUNT](state, count) {
        state.unreadNotificationsCount = count;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
