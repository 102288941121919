import http from "./http";
import store from "@/store";
import { ERROR } from "@/store/const/mutation-types";
import handleError from "./handleError";
import ILoginCredentials from "@/contracts/ILoginCredentials";

export async function login(data: ILoginCredentials) {
    return http.post<string>("/account/login", data);
}

export async function loadCurrUser() {
    try {
        return await http.get("/users");
    } catch (error) {
        store.commit(ERROR, handleError(error));
        throw error;
    }
}

export async function resetPassword(data) {
    return http.post("/account/resetpassword", data).catch(error => {
        store.commit(ERROR, handleError(error));
    });
}

export async function checkUsername(data) {
    return http.post("/account/isusernamefree", { value: data });
}

export async function register(data) {
    return http.post("/account/register", data);
}

export async function loadSettings() {
    try {
        const { data } = await http.get("/users.getSettings");
        return data;
    } catch (error) {
        store.commit(ERROR, handleError(error));
        throw error;
    }
}

export async function updateSettings(data) {
    return http.put("/users.editSettings", data).catch(error => {
        store.commit(ERROR, handleError(error));
    });
}

export async function loadInfo() {
    return http.get("/users.getInfo").catch(error => {
        store.commit(ERROR, handleError(error));
    });
}

export async function updateInfo(data) {
    return http.put("/users.editInfo", data).catch(error => {
        store.commit(ERROR, handleError(error));
    });
}

export async function changePassword(data) {
    return http.put("/users.changePassword", data);
    // .catch((error) => {
    //   store.commit(types.ERROR, handleError(error))
    // })
}

export async function feedback(data) {
    return http.post("/users.feedback", data).catch(error => {
        store.commit(ERROR, handleError(error));
    });
}

export async function initPayment(data) {
    return http.post("/payment/init", data).catch(error => {
        store.commit(ERROR, handleError(error));
    });
}

export async function loadTransactions(): Promise<any[]> {
    try {
        let { data } = await http.get<any[]>("/transactions");
        return data;
    } catch (error) {
        store.commit(ERROR, handleError(error));
        return [];
    }
}
