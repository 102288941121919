import { Vue, Component, Prop } from "vue-property-decorator";
import "./MobileMenu.scss";
import { Mutation, State, Action } from "vuex-class";

import { floatingPanelViews } from "@/const/enums";
import IUser from "@/contracts/IUser";

@Component
export default class MobileMenu extends Vue {
    @Prop()
    open!: boolean;

    @State
    user!: IUser;

    @State("unreadNotificationsCount", { namespace: "notifications" })
    unreadNotificationsCount;

    private items = [
        {
            route: { path: "/monitoring" },
            key: "monitoring"
        },
        // {
        //     route: { path: "/tracks" },
        //     key: "tracks"
        // },
        // {
        //     route: { path: "/geoobjects" },
        //     key: "geoobjects"
        // },
        {
            route: { path: "/management" },
            key: "management"
        }
    ];

    @Mutation("SET_FLOATING_PANEL_CURRENT_VIEW")
    setFloatingPanelCurrentView;

    toggleGeoobjects() {
        this.setFloatingPanelCurrentView(floatingPanelViews.geoobjectsBar);
        this.$emit("openChanged", false);
    }

    toggleNotifications() {
        this.setFloatingPanelCurrentView(floatingPanelViews.notificationsBar);
        this.$emit("openChanged", false);
    }

    toggleMessenger() {
        this.setFloatingPanelCurrentView(floatingPanelViews.messengerBar);
        this.$emit("openChanged", false);
    }

    handleLogout() {
        this.$root.$emit("autoUpdate:stop");
        this.logout().then(() => {
            location.reload(true);
        });
    }

    @Action("logout")
    logout;

    get hasGeoobjects() {
        return this.$route.meta.geoobjects;
    }

    get unreadMessagesCount() {
        return this.$store.state.monitoring.beacons.reduce((acc, beacon) => acc + beacon.UnreadMessagesCount, 0);
    }

    render() {
        const menuItems = this.items.map(item => (
            <li class="mobile-menu__listitem">
                <router-link to={item.route.path}>
                    <div class="link" onClick={() => this.$emit("openChanged", false)}>
                        {this.$t("menu." + item.key)}
                    </div>
                </router-link>
            </li>
        ));

        return (
            <div class={["mobile-menu", this.open && "open"]}>
                <i class="mobile-menu__close fa-lg fal fa-times" onClick={() => this.$emit("openChanged", false)} />
                <div class="mobile-menu__wrapper">
                    <div class="d-flex align-items-center mt-3" style="margin-left: 2rem;">
                        <img alt height="30" src="/static/Logo.png" />
                    </div>
                    <ul class="mobile-menu__list">
                        {menuItems}
                        <li class="mobile-menu__listitem">
                            <router-link to={"/profile"}>
                                <div class="link" onClick={() => this.$emit("openChanged", false)}>
                                    {this.$t("menu.profile")}
                                </div>
                            </router-link>
                        </li>
                        <li class="mobile-menu__listitem">
                            <div
                                class="link"
                                onClick={() => {
                                    this.$emit("openChanged", false);
                                    this.handleLogout();
                                }}
                            >
                                {this.$t("menu.logout")}
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="mobile-menu__footer">
                    <div class="d-flex">
                        {this.hasGeoobjects ? (
                            <div onClick={this.toggleGeoobjects}>
                                <div
                                    class="d-flex align-items-center justify-content-center"
                                    style="height: 60px; width: 80px;"
                                >
                                    <i class="far fa-lg fa-map-marker-alt" />
                                </div>
                            </div>
                        ) : null}
                        {this.user && this.user.CanViewMessages ? (
                            <div onClick={this.toggleMessenger} class="d-flex align-items-center">
                                <div
                                    class="d-flex align-items-center justify-content-center"
                                    style="height: 60px; width: 80px;"
                                >
                                    <el-badge
                                        hidden={this.unreadMessagesCount === 0}
                                        value={this.unreadMessagesCount}
                                        class="d-flex align-items-center justify-content-center"
                                    >
                                        <i class="far fa-lg fa-envelope" />
                                    </el-badge>
                                </div>
                            </div>
                        ) : null}

                        <div onClick={this.toggleNotifications} class="d-flex align-items-center">
                            <div
                                class="d-flex align-items-center justify-content-center"
                                style="height: 60px; width: 80px;"
                            >
                                <el-badge
                                    hidden={this.unreadNotificationsCount === 0}
                                    value={this.unreadNotificationsCount}
                                    class="d-flex align-items-center justify-content-center"
                                >
                                    <i class="far fa-lg fa-bell" />
                                </el-badge>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
