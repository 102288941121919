import { RenderContext } from "vue";

import "./NavItemButton.scss";

interface INavItemButtonProps {
    outerClass: string;
}

export default ({ props, listeners, slots }: RenderContext<INavItemButtonProps>) => (
    <li class={["nav-item-button", props.outerClass]} onClick={listeners.click}>
        {slots().default}
    </li>
);
