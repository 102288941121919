import { Vue, Prop, Component } from "vue-property-decorator";
import BeaconChannels from "@/components/Monitoring/BeaconChannels";
import BeaconChart from "./BeaconChart";

@Component({
    components: {
        BeaconChannels,
        BeaconChart
    }
})
export default class ChannelsPanel extends Vue {
    currentView: "table" | "chart" = "table";

    render() {
        return (
            <div class="beacon-side-page__events">
                <div class="beacon-side-page__events-header justify-content-between">
                    <div class="d-flex align-items-center">
                        <i class="fad fa-thermometer-half beacon-side-page__events-header-icon" />
                        <div class="beacon-side-page__events-header-text">{this.$t("infoWidget.sensors")}</div>
                    </div>
                    <div>
                        <el-radio-group vModel={this.currentView} size="small" class="d-flex w-100 lo-radio-group">
                            <el-radio-button class="flex-grow-1" style="flex-basis: 0" label={"table"}>
                                <i class="fad fa-table"></i>
                            </el-radio-button>
                            <el-radio-button class="flex-grow-1" style="flex-basis: 0" label={"chart"}>
                                <i class="fad fa-analytics"></i>
                            </el-radio-button>
                        </el-radio-group>
                    </div>
                </div>
                {this.currentView === "table" ? <beacon-channels /> : <beacon-chart isActive={true} />}
            </div>
        );
    }
}
