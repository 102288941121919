import { Vue, Component, Watch } from "vue-property-decorator";

import GaugesWidget from "@/components/Monitoring/GaugesWidget";
import StatusEventCard from "./StatusEventCard";
import DiagnosticMessageCard from "./DiagnosticMessageCard";

import BeaconMainInfo from "./BeaconMainInfo";
import { Getter, State } from "vuex-class";
import IBeaconMonitoring from "@/contracts/IBeaconMonitoring";
import {
    loadStatusEvents,
    StatusEvent,
    BeaconAdditionalInfo,
    loadBeaconInfo,
    loadDiagnosticMessages,
    DiagnosticMessageModel,
    loadFuelDailyConsumption,
    FuelDailyConsumptionData
} from "@/api/monitoring";

import "./BeaconSidePage.scss";
import IGeoobject from "@/contracts/IGeoobject";
import CollapseHeader from "./CollapseHeader";
import ChannelsPanel from "./ChannelsPanel";
import FuelConsumptionChart from "./FuelConsumptionChart";
import dayjs from "dayjs";
import IChannelMonitoring from "@/contracts/IChannelMonitoring";
import FuelReportModal from "./FuelReportModal";
import { max } from "lodash";

@Component({
    components: {
        GaugesWidget,
        BeaconMainInfo,
        StatusEventCard,
        CollapseHeader,
        ChannelsPanel,
        DiagnosticMessageCard,
        FuelConsumptionChart,
        FuelReportModal
    }
})
export default class BeaconSidePage extends Vue {
    fuelReportModal: boolean = false;

    @Getter("getSelectedBeacon", { namespace: "monitoring" })
    private beacon!: IBeaconMonitoring;

    @State("geoobjects", { namespace: "geoobjects" })
    private geoobjects!: IGeoobject[];

    private coordintesFormat: string = "ddd";
    private activeNames: string[] = [];
    private beaconInfo: BeaconAdditionalInfo | null = null;
    private diagnosticMessages: DiagnosticMessageModel[] = [];

    get bnwasModeChannel() {
        // ChannelFlag === 11 - режим СКДВП
        return this.beacon.Channels.find(channel => channel.ChannelFlag === 11);
    }

    get bnwasState() {
        if (this.bnwasModeChannel) {
            switch (this.bnwasModeChannel.Value) {
                case 4:
                    return this.$t("bnwas.poweroff");
                case 3:
                    return this.$t("bnwas.off");
                case 0:
                    return this.$t("bnwas.interfaceerror");
                default:
                    return this.$t("bnwas.off");
            }
        }
        return this.$t("bnwas.off");
    }

    private toggleCoordinatesFormat() {
        if (this.coordintesFormat === "ddd") {
            this.coordintesFormat = "dms";
        } else {
            this.coordintesFormat = "ddd";
        }
    }

    private statusEvents: StatusEvent[] = [];
    private fuelDailyConsumptionData: FuelDailyConsumptionData | null = null;
    private loading: boolean = false;

    @Watch("beacon", { immediate: true })
    async onBeaconChanged(beacon: IBeaconMonitoring) {
        try {
            this.loading = true;
            if (beacon.Channels.some(x => x.FuelDailyConsumption)) {
                const fuelChannelsTimestamps = beacon.Channels.filter(x => x.FuelDailyConsumption).map(
                    x => x.Timestamp
                );
                const maxTimestamp = max(fuelChannelsTimestamps);
                this.fuelDailyConsumptionData = await loadFuelDailyConsumption(
                    beacon.Id,
                    dayjs(maxTimestamp)
                        .subtract(7, "day")
                        .toDate(),
                    dayjs(maxTimestamp).toDate()
                );
            }
            let statusEventsPromise = loadStatusEvents(beacon.Id, undefined, undefined);
            let beaconInfoPromise = loadBeaconInfo(beacon.Id);
            let diagnosticMessagesPromise = loadDiagnosticMessages(beacon.Id);
            const [statusEvents, beaconInfo, diagnosticMessages] = await Promise.all([
                statusEventsPromise,
                beaconInfoPromise,
                diagnosticMessagesPromise
            ]);
            this.statusEvents = statusEvents;
            this.beaconInfo = beaconInfo;
            this.diagnosticMessages = diagnosticMessages;
        } catch (error) {
        } finally {
            this.loading = false;
        }
    }

    render() {
        return (
            <div class="beacon-side-page" vLoading={this.loading}>
                <div class="beacon-side-page__bnwas">
                    {(this.beacon.BNWASShowStatus && this.beacon.BNWASAlarm) ||
                    (this.bnwasModeChannel && this.bnwasModeChannel.Value === 0) ? (
                        <div class="status-event status-event_error mx-3">
                            <div class="status-event__body">
                                <div class="beacon-side-page__event-title">
                                    <i class="fad fa-lg fa-exclamation-triangle mr-3" />
                                    {this.bnwasState}
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
                {this.beacon.Location ? (
                    <div class="beacon-side-page__events">
                        <div class="beacon-side-page__events-header">
                            <i class="fad fa-crosshairs beacon-side-page__events-header-icon" />
                            <div class="beacon-side-page__events-header-text">{this.$t("infoWidget.location")}</div>
                        </div>
                        <div class="container-fluid pb-3" style="font-size: 13px;">
                            <div class="row">
                                <div class="col px-1 text-right">{this.$t("infoWidget.coordinates")}</div>
                                <div class="d-flex col px-1 justify-content-start font-weight-semibold word-break-all">
                                    <coordinates-wrapper
                                        class="mr-2"
                                        coordinates={this.beacon.Location.Coordinates}
                                        format={this.coordintesFormat}
                                    />
                                    <el-button
                                        size="mini"
                                        type="text"
                                        icon="far fa-exchange-alt"
                                        class="p-0"
                                        onClick={this.toggleCoordinatesFormat}
                                    />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col px-1 text-right">{this.$t("infoWidget.time")}</div>
                                <div class="col px-1 font-weight-semibold word-break-all">
                                    <date-wrapper date={this.beacon.Location.Timestamp} />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col px-1 text-right">{this.$t("infoWidget.packetDt")}</div>
                                <div class="col px-1 font-weight-semibold word-break-all">
                                    <date-wrapper date={this.beacon.PacketDt} />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
                <channels-panel />
                {this.fuelReportModal && (
                    <fuel-report-modal
                        beacon={this.beacon}
                        visibility={this.fuelReportModal}
                        onVisibilityChange={(val: boolean) => (this.fuelReportModal = val)}
                    ></fuel-report-modal>
                )}
                {this.fuelDailyConsumptionData && (
                    <div class="beacon-side-page__events">
                        <div class="beacon-side-page__events-header justify-content-between">
                            <div class="d-flex align-items-center w-100">
                                <i class="fad fa-thermometer-half beacon-side-page__events-header-icon" />
                                <div class="beacon-side-page__events-header-text d-flex justify-content-between w-100">
                                    {this.$t("infoWidget.fuelDailyConsumtion")}
                                    <el-button
                                        onClick={() => (this.fuelReportModal = true)}
                                        class="p-0"
                                        size="medium"
                                        type="text"
                                    >
                                        {this.$t("infoWidget.buildReport")}
                                    </el-button>
                                </div>
                            </div>
                        </div>
                        <fuel-consumption-chart chartData={this.fuelDailyConsumptionData} loading={this.loading} />
                    </div>
                )}
                <el-collapse vModel={this.activeNames}>
                    {this.diagnosticMessages.length > 0 && (
                        <el-collapse-item name="0">
                            <collapse-header
                                slot="title"
                                title={this.$t("infoWidget.diagnostic")}
                                iconClass="fad fa-tasks-alt"
                            ></collapse-header>
                            <div class="p-3 scroll">
                                {this.diagnosticMessages.map(diagnosticMessage => (
                                    <diagnostic-message-card
                                        diagnosticMessage={diagnosticMessage}
                                    ></diagnostic-message-card>
                                ))}
                            </div>
                        </el-collapse-item>
                    )}
                    {this.statusEvents.length > 0 && (
                        <el-collapse-item name="1">
                            <collapse-header
                                slot="title"
                                title={this.$t("infoWidget.events")}
                                iconClass="fad fa-bell"
                            ></collapse-header>
                            <div class="p-3 scroll">
                                {this.statusEvents.map(statusEvent => (
                                    <status-event-card statusEvent={statusEvent} />
                                ))}
                            </div>
                        </el-collapse-item>
                    )}
                    {this.beacon.LocationInGeoobjects.length > 0 ? (
                        <el-collapse-item name="2">
                            <collapse-header
                                slot="title"
                                title={this.$t("infoWidget.locationInGeoObjects")}
                                iconClass="fad fa-map-marked"
                            ></collapse-header>
                            <div class="p-3" style="background-color: #E1E8ED;">
                                {this.beacon.LocationInGeoobjects.map(geoobjectId => {
                                    const geoobject = this.geoobjects.find(geoobject => geoobject.Id === geoobjectId);
                                    if (geoobject) {
                                        return (
                                            <div class="beacon-card lo-card mb-2">
                                                <div class="font-weight-medium">{geoobject.Name}</div>
                                            </div>
                                        );
                                    }
                                    return null;
                                })}
                            </div>
                        </el-collapse-item>
                    ) : null}
                    <el-collapse-item name="3">
                        <collapse-header
                            slot="title"
                            title={this.$t("infoWidget.mainInfo")}
                            iconClass="fad fa-info"
                        ></collapse-header>
                        <beacon-main-info beacon={this.beacon} beaconInfo={this.beaconInfo} />
                    </el-collapse-item>
                    {this.beacon.TransportCard && this.beacon.TransportCard.length > 0 && (
                        <el-collapse-item name="4">
                            <collapse-header
                                slot="title"
                                title={this.$t("infoWidget.transportCard")}
                                iconClass="fad fa-list-alt"
                            ></collapse-header>
                            <div class="container-fluid pb-3">
                                {this.beacon.TransportCard &&
                                    this.beacon.TransportCard.length > 0 &&
                                    this.beacon.TransportCard.map(field => {
                                        return (
                                            <div class="row">
                                                <div class="col text-right">{field.label}</div>
                                                <div class="col font-weight-semibold word-break-all">{field.value}</div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </el-collapse-item>
                    )}
                    {this.beacon.FuelManagement && (
                        <el-collapse-item name="5">
                            <collapse-header
                                slot="title"
                                title={this.$t("infoWidget.fuelControl")}
                                iconClass="fad fa-gas-pump"
                            ></collapse-header>
                            {this.activeNames.includes("4") && <gauges-widget />}
                        </el-collapse-item>
                    )}
                </el-collapse>
            </div>
        );
    }
}
