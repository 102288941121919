import { Vue, Component, Prop } from "vue-property-decorator";
import { VNode } from "vue";

import "./CollapseHeader.scss";

@Component
export default class CollapseHeader extends Vue {
    @Prop()
    private title!: string;

    @Prop()
    private iconClass!: string;

    public render(): VNode {
        return (
            <div slot="title" class="CollapseHeader">
                <i class={["CollapseHeaderIcon", this.iconClass]} />
                <div class="font-weight-semibold">{this.title}</div>
            </div>
        );
    }
}
