/* base (index) */
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const SET_USER = "SET_USER";
export const RESIZE_CANVAS = "RESIZE_CANVAS";
export const ERROR = "ERROR";
/* end of base (index) */

/* monitoring */
export const LOAD_BEACONS = "LOAD_BEACONS";
export const SET_GROUPS = "SET_GROUPS";
export const TOGGLE_WIDGET_CONTROLS = "TOGGLE_WIDGET_CONTROLS";
export const ADD_GROUP = "ADD_GROUP";
export const DELETE_GROUP = "DELETE_GROUP";
export const UPDATE_GROUP = "UPDATE_GROUP";
export const SHOW_POSITIONS = "SHOW_POSITIONS";
export const HIDE_POSITIONS = "HIDE_POSITIONS";
export const SHOW_TRACKS = "SHOW_TRACKS";
export const HIDE_TRACKS = "HIDE_TRACKS";
export const UPDATE_TRACKS = "UPDATE_TRACKS";
export const SET_TRACK = "SET_TRACK";
export const SELECT_BEACON = "SELECT_BEACON";
/* end of monitoring */

/* floating panel */
export const SET_FLOATING_PANEL_CURRENT_VIEW = "SET_FLOATING_PANEL_CURRENT_VIEW";
/* end of floating panel */

/* tracks */
export const ADD_TRACK = "ADD_TRACK";
export const DELETE_TRACK = "DELETE_TRACK";
/* end of tracks */

/* geoobjects */
export const HIDE_ALL_GEOOBJECT_MONITORING = "HIDE_ALL_GEOOBJECT_MONITORING";
export const HIDE_ALL_GEOOBJECT_TRACKS = "HIDE_ALL_GEOOBJECT_TRACKS";
export const SHOW_ALL_GEOOBJECT_MONITORING = "SHOW_ALL_GEOOBJECT_MONITORING";
export const SHOW_ALL_GEOOBJECT_TRACKS = "SHOW_ALL_GEOOBJECT_TRACKS";
export const TOGGLE_GEOOBJECT_MONITORING = "TOGGLE_GEOOBJECT_MONITORING";
export const TOGGLE_GEOOBJECT_TRACKS = "TOGGLE_GEOOBJECT_TRACKS";
export const SET_GEOOBJECTS = "SET_GEOOBJECTS";
export const SET_CURRENT_GEOOBJECT_ID = "SET_CURRENT_GEOOBJECT_ID";
export const INSERT_GEOOBJECT = "INSERT_GEOOBJECT";
export const DELETE_GEOOBJECT = "DELETE_GEOOBJECT";
export const UPDATE_GEOOBJECT = "UPDATE_GEOOBJECT";
/* end of geoobjects */

/* notifications */
export const SET_UNREAD_NOTIFICATIONS_COUNT = "SET_UNREAD_NOTIFICATIONS_COUNT";
/* end of notifications */
