/* eslint-disable */
export default {
    default: {
        settings: "Настройки",
        operationsuccess: "Операция выполнена успешно!",
        operationfailed: "Ошибка выполнения операции!",
        warning: "Внимание!",
        edit: "Редактировать",
        ok: "ОК",
        back: "Назад",
        cancel: "Отмена",
        save: "Сохранить",
        search: "Поиск...",
        reset: "Сброс",
        apply: "Применить",
        nodata: "Нет данных",
        startTime: "Время начала",
        endTime: "Время конца",
        addParameter: "Добавить параметр",
        minimumSpeedKnots: "Минимальная скорость, уз",
        minimumDistanceM: "Минимальная дистанция, м",
        BNWASReportName: "Название",
        BNWASDistance: "Дистанция",
        BNWASRunningTime: "Время ходовое",
        BNWASStatusNormalTime: "Статус норма, время",
        BNWASStatusNormalPercentage: "Статус норма, %",
        BNWASAlarmStatusTime: "Статус авария, время",
        BNWASAlarmStatusPercentage: "Статус авария, %",
        dateshortcuts: {
            twodays: "2 дня",
            week: "Неделя",
            month: "Месяц",
            threemonths: "3 месяца"
        },
        maxlength: "Максимальная длина: {length} символов",
        required: "Поле не может быть пустым",
        unsavedData: "Все несохраненные изменения будут утеряны. Продолжить?",
        invalid: "Введены недопустимые символы",
        invalidPhone: "Введён неверный телефон",
        beacons: "Объекты",
        sensors: "Датчики",
        delete: "Удалить",
        confirmDelete: 'Удалить "{name}"?',
        thisActionCannotBeUndoneDelete: "Это действие нельзя отменить! Удалить?",
        beacon: "Объект",
        object: "Объект",
        sensor: "Датчик",
        reportType: "Тип отчета",
        reportTemplates: "Настройки отчетов",
        enterReportName: "Введите название отчета",
        reportName: "Название отчета",
        noData: "Нет данных для отображения",
        preferredCommunicationType: "Канал для отправки параметров",
        currentCommunicationType: "Последний выход на связь:",
        name: "Название",
        comment: "Комментарий",
        actions: "Действия",
        icon: "Иконка",
        color: "Цвет",
        activate: "Активировать",
        interval: "Интервал",
        update: "Обновить",
        sorting: {
            nameaz: "По названию; А-Я",
            nameza: "По названию; Я-А",
            datedesc: "По дате; Сначала новые",
            dateasc: "По дате; Новые в конце"
        },
        model: "Модель",
        selectAll: "Выбрать все",
        build: "Построить",
        timeline: "Таймлайн",
        add: "Добавить",
        integrations: "Интеграции",
        timestamp: "Время"
    },
    reportIntervals: {
        twoDays: "2 дня",
        week: "7 дней",
        month: "30 дней"
    },
    integrations: {
        fd: "Norwegian Directorate of Fisheries",
        fdDisable: "Disable Sporing",
        fdEnable: "Enable Sporing"
    },
    Models: {
        Undefined: "Не указано",
        Mini: "Lookout Mini",
        Standard: "Lookout Standard",
        Pro: "Lookout Pro",
        ProEPS: "Lookout ProEPS",
        Fishery: "Lookout Fishery",
        Marine: "Lookout Marine",
        Compact: "Lookout Compact",
        Marine2: "Lookout Marine 2.0",
        Lite: "Lookout Lite"
    },
    parameterState: {
        notSent: "-",
        waiting: "Ожидает отправки",
        sent: "Отправлен",
        sentToIridium: "Отправлен в Iridium",
        sentAndConfirmed: "Отправлен и подтвержден",
        error: "Ошибка"
    },
    parameterCardHeader: {
        lastSend: "Последняя отправка:",
        state: "Состояние:",
        resetToDefault: "Установить значения по умолчанию",
        send: "Отправить"
    },
    exchangeModeConfigurationParameter: {
        title: "Настройка отправки данных",
        exchangeMode: "Режим отправки данных",
        startTime: "Время старта отправки данных (UTC)",
        interval: "Интервал отправки данных",
        schedule: "Расписание:"
    },
    statusConfigurationParameter: {
        title: "Настройка статусов и навигационного сообщения",
        statusesSettings: "Настройка статусов",
        navMessageSettings: "Настройка навигационного сообщения",
        HardReset: "Аппаратная перезагрузка (включение питания)",
        SoftReset: "Программная перезагрузка (из встроенного ПО)",
        TimerReset: "Перезагрузка по таймауту",
        CaseOpen: "Вскрытие корпуса",
        CaseClose: "Закрытие корпуса",
        GPSNotAvailable: "Недоступен сигнал GPS/ГЛОНАСС",
        IridiumNotAvailable: "Недоступен Iridium",
        GSMNotAvailable: "Недоступен GSM",
        LowBattery: "Низкий заряд батареи",
        MountUninstall: "Снятие с крепления",
        MountInstall: "Постановка на крепление",
        PowerConnected: "Подключено внешнее питание",
        PowerDisconnected: "Отключено внешнее питание",
        ExternalVoltageLow: "Низкое напряжение внешнего питания",
        ExternalVoltageHigh: "Высокое напряжение внешнего питания",
        ExternalVoltageNormal: "Нормальное напряжение внешнего питания",
        InternalVoltageLow: "Низкое напряжение батареи",
        InternalVoltageNormal: "Нормальное напряжение батареи",
        BoardTempLow: "Низкая температура платы",
        BoardTempHigh: "Высокая температура платы",
        BoardTempNormal: "Нормальная температура платы",
        MessageFormat: "Формат основного навигационного сообщения"
    },
    channelsConfigParameter: {
        title: "Настройка аналоговых и дискретных входов",
        measurement: "Измеряемая аналоговая величина",
        analogMode: "Режим сбора данных с аналоговых входов",
        analogPeriod: "Период сбора данных аналогового входа",
        discreteMode: "Режим сбора данных с дискретных входов",
        discretePeriod: "Период сбора данных дискретного входа"
    },
    collectModeParameter: {
        title: "Настройка периода сбора данных",
        collectStartTime: "Время старта сбора данных (UTC)",
        collectInterval: "Интервал сбора данных"
    },
    parameters: {
        CollectModeConfiguration: "Настройка интервала сбора данных",
        ExchangeModeConfiguration: "Настройка интервала отправки данных",
        ChannelsConfig: "Режимы сбора данных по внешним дискретным и аналоговым каналам",
        NMEAModeConfig: "Режим трансляции предложений NMEA",
        CustomCommandTemplate: "Произвольный параметр в HEX",
        ModbusConfig: "Настройки Modbus",
        ShockSettings: "Установить уровень ускорения/настроить датчик удара",
        Sim1APN: "Имя точки доступа GPRS соединения (APN) для 1-ой SIM карты",
        Sim1Username: "Имя пользователя GPRS соединения для 1-ой SIM карты",
        Sim1Password: "Пароль GPRS соединения для 1-ой SIM карты",
        Sim1Address: "Адрес GPRS соединения для 1-ой SIM карты",
        Sim2APN: "Имя точки доступа GPRS соединения (APN) для 2-ой SIM карты",
        Sim2Username: "Имя пользователя GPRS соединения для 2-ой SIM карты",
        Sim2Password: "Пароль GPRS соединения для 2-ой SIM карты",
        Sim2Address: "Адрес GPRS соединения для 2-ой SIM карты",
        CustomCommand: "Команда в HEX (длина кратна 2, например ABFF78)",
        SSASDisableAlarm: "Отмена боевой тревоги ССОО",
        SSASOptionalString: "Конфигурация опциональной части сообщения ССОО",
        LRITConfiguration: "MMSI для ОСДР",
        LRITEnable: "Включение/отключение передачи данных ОСДР",
        LRITPeriod: "Период передачи ОСДР",
        Poll: "Запрос текущей позиции",
        CurrentCoordinates: "Запрос координат",
        MaximumSendingTime: "Максимальное время отправки данных",
        DataChannel: "Канал передачи данных",
        GPSPredictiveStartTime: "Время упреждающего старта GPS модуля",
        FactoryReset: "Сброс на заводские настройки",
        ClearArchives: "Очистить внутренние архивы",
        Reboot: "Перезагрузка",
        ForcedDataSending: "Форсированная отправка данных",
        ModbusPeriod: "Период опроса датчиков Modbus",
        OnlineModeSendPeriod: "Период отправки данных для онлайн режима",
        OnlineModeCollectPeriod: "Период получения данных для онлайн режима",
        ProtocolVersion: "Версия протокола",
        FirmwareVersion: "Сообщить версию прошивки",
        DataSendingPeriod: "Период отправки данных",
        DataReceivingPeriod: "Период получения данных (от сервера)",
        CoordinateReceivingPeriod: "Период сбора данных",
        GPSValidSessionTime: "Время удержания GPS сессии перед сбором координат",
        OperationMode: "Режим работы",
        IntermediateCoordinates: "Промежуточное получение координат",
        CollectAndSendData: "Отправка данных",
        Compression: "Сжатие данных",
        AccelerationLevel: "Уровень ускорения для детектирования движения",
        SimSelection: "SIM карта",
        AdaptiveMode: "Адаптивный анализ данных",
        ForcedMode: "Форсированная отправка данных",
        BatteryLife: "Наработка маяка",
        InternalSensors: "Передача данных с внутренних датчиков",
        StatusConfiguration: "Настройка статусных сообщений",
        ModbusConfiguration: "Конфигурация датчиков Modbus",
        ModbusCollectModeConfiguration: "Период сбора данных с датчиков Modbus"
    },
    map: {
        stop: "Остановка",
        parking: "Парковка"
    },
    errors: {
        auth: {
            failed: "Неверный логин или пароль",
            blocked: "Аккаунт заблокирован",
            notActivated: "Email не подтвержден"
        },
        error: "Произошла непредвиденная ошибка.",
        mainPage: "Вернуться на главную",
        internalServerError: "Ошибка на сервере. Пожалуйста попробуйте повторить запрос через некоторое время.",
        serverUnavailable: "Сервер недоступен. Пожалуйста попробуйте повторить запрос через некоторое время."
    },
    login: {
        title: "Войдите в свой аккаунт",
        username: "Имя пользователя",
        password: "Пароль",
        demo: "Демо",
        resetpassword: "Забыли свой пароль?",
        register: "Регистрация",
        login: "Войти",
        wrongcredentials: "Неверный логин или пароль",
        blocked: "Аккаунт заблокирован",
        emailnotconfirmed: "Email не подтвержден",
        internalerror: "Ошибка на сервере",
        serverunavailable: "Сервер не доступен"
    },
    resetpassword: {
        title: "Сброс пароля",
        username: "Имя пользователя",
        email: "Email",
        enterusername: "Введите имя пользователя",
        enteremail: "Введите email",
        resetPassword: "Сбросить пароль"
    },
    register: {
        title: "Регистрация",
        username: "Имя пользователя",
        email: "Email",
        password: "Пароль",
        enterusername: "Введите имя пользователя",
        usernamenotfree: "Имя пользователя занято",
        enteremail: "Введите email",
        entervalidemail: "Пожалуйста введите правильный email",
        enterpassword: "Введите пароль",
        passwordlength: "Длина пароля должна быть не менее 6 символов",
        cancel: "К входу",
        enterprise: "Юр. лицо",
        individual: "Физ. лицо",
        name: "Как к Вам обращаться?",
        phone: "Телефон",
        organization: "Организация",
        country: "Страна",
        timezone: "Часовой пояс",
        createaccount: "Создать аккаунт",
        toauth: "К авторизации",
        success: "Аккаунт успешно создан",
        successtext:
            "Для завершения регистрации перейдите по ссылке которую мы отправили на указанный при регистрации email.",
        fail: "Аккаунт не был создан",
        failtext: "К сожалению на сервере произошла ошибка и аккаунт не был создан. Пожалуйста попробуйте еще раз.",
        usernametaken: "Имя пользователя занято",
        datasending: "Отправляю данные..."
    },
    profile: {
        balance: "Баланс: {balance} руб",
        makepayment: "Пополнить",
        lengthunit: "Ед. измерения расстояния",
        timezone: "Часовой пояс",
        locale: "Язык интерфейса",
        cardSize: "Размер карточки маяка",
        showPositionOnLoad: "Отображать все маяки на карте при загрузке",
        password: "Пароль",
        oldpassword: "Старый пароль",
        oldpasswordvalidation: "Введите старый пароль",
        newpassword: "Новый пароль",
        newpasswordvalidation: "Введите новый пароль",
        repeatnewpassword: "Повторите новый пароль",
        changepassword: "Сменить пароль",
        personalinfo: "Информация",
        enterprise: "Юридическое лицо",
        individual: "Физическое лицо",
        country: "Страна",
        name: "Как к Вам обращаться?",
        phone: "Телефон",
        organization: "Организация",
        passwordchangesuccess: "Пароль успешно изменен!",
        settingsupdatedsuccess: "Данные успешно сохранены!",
        passwordlength: "Длинна пароля должна быть не менее 6 символов и не более 50",
        passwordmustbeequal: "Пароли должны совпадать",
        wrongoldpassword: "Неверный старый пароль"
    },
    channelstable: {
        nochannels: "Нет датчиков"
    },
    beaconactivation: {
        title: "Активировать маяк",
        activationcode: "Код активации",
        emptyvalidation: "Поле не может быть пустым",
        imeivalidation: "IMEI должен содержать {count} символов",
        activationcodevalidation: "Код активации должен содержать {count} символов",
        activationsuccess: "Маяк успешно активирован",
        failtitle: "Ошибка!",
        faildescription: "Ошибка активации маяка, проверьте вводимые данные!",
        notready: "Внимание!",
        notreadydescription: "В данный момент маяк не доступен для активации."
    },
    beaconcard: {
        objectcard: "Карточка объекта"
    },
    beaconsettings: {
        CheckRelevance: "Определять релевантность",
        CheckEmergency: "Определять нештатные ситуации",
        Emergency: "Нештатные ситуации",
        EmergencyOverRelevancePriority: "Приоритет нештатных ситуаций над релевантностью данных",
        LengthUnit: "Единица измерения расстояния",
        VehicleType: "Тип транспорта",
        messenger: "Мессенджер",
        client: "Клиент",
        settings: "Основные настройки",
        updateProgrammFiles: "Обновление программного обеспечения",
        firmwareSize: "Размер прошивки",
        addingTime: "Время добавления",
        timeLastOperation: "Время последней операции",
        status: "Статус",
        successfullyCompleted: "Успешно завершена",
        error: "Ошибка",
        nothingToDo: "Ничего не делать",
        waitingBeaconCommunicate: "Ожидает выхода маяка на связь",
        description: "Описание",
        queue: "Поставить в очередь",
        cancelSending: "Отменить отправку",
        delete: "Удалить",
        selectFile: "Выбрать файл",
        settingsSendingCommandsToIridium: "Настройки для отправки команд в Иридиум",
        specification: "Спецификация",
        iridiumGatewayHost: "Хост шлюза Иридиум",
        iridiumGatewayPort: "Порт шлюза Иридиум"
    },
    channelsettings: {
        impactonbeaconstatus: "Учитывается при определении состояния маяка",
        impactonbeaconlastupdate: "Учитывается при определении времени обновления маяка",
        precision: "Точность (знаков после запятой)",
        padleadingzeros: "Добавлять незначащие нули",
        determineemergencystatus: "Определять нештатные ситуации",
        emergencystatus: "Нештатные ситуации",
        lowerpreemergencythreshold: "Нижняя предаварийная граница",
        upperpreemergencythreshold: "Верхняя предаварийная граница",
        loweremergencythreshold: "Нижняя аварийная граница",
        upperemergencythreshold: "Верхняя аварийная граница",
        binarychannelvalue0: 'Текстовое обозначение значения "0"',
        binarychannelstatus0: 'Статус для значения "0"',
        binarychannelvalue1: 'Текстовое обозначение значения "1"',
        binarychannelstatus1: 'Статус для значения "1"'
    },
    datarelevance: {
        datarelevance: "Релевантность данных",
        usebeacontimeouts: "Использовать таймауты маяка",
        relevancetimeout1: "Таймаут релевантности 1",
        relevancetimeout2: "Таймаут релевантности 2",
        hour: "ч ",
        minute: " мин"
    },
    settingscards: {
        beaconsettings: "Настройки маяка",
        remotecontrol: "Удаленное управление",
        card: "Карточка объекта",
        beaconnotifications: "Уведомления"
    },
    datepicker: {
        from: "От",
        to: "До",
        fuelReport: "Отчет по топливу"
    },
    tracks: {
        beaconvalidation: "Выберите объект",
        periodvalidation: "Задайте интервал",
        linewidth: "Толщина линии",
        draw: "Построить",
        notracks: "Нет построенных треков",
        nodata: "За указанный период данных нет"
    },
    geoobjects: {
        nodescription: "Описание отсутствует",
        // geoobjectseditor
        namevalidation: "Пожалуйста введите имя геообъекта!",
        type: "Тип",
        linewidth: "Толщина линии",
        // geoobjectspanel
        nogeoobjects: "Нет геообъектов",
        // geoobjectsbar
        showall: "Отобразить все",
        hideall: "Скрыть все"
    },
    notifications: {
        // Notification Card
        BeaconConnectionState: "Состояние соединения",
        BeaconCommandState: "Состояние отправки команды",
        RealChannelState: "Состояние датчика",
        RealChannelStateWithCustomThresholds: "Состояние датчика",
        BinaryChannelState: "Состояние датчика",
        BeaconLocationInGeoobject: "Геообъекты",
        BNWASEmergencyState: "Состояние СКДВП",
        LastOnline: "Маяк оффлайн",
        // Notification Bar
        markasread: "Отметить прочитанными",
        loadmore: "Загрузить еще",
        nonotifications: "Нет уведомлений",
        NoNotificationsToLoad: "Нет нотификаций для загрузки",
        nonewnotifications: "Нет новых уведомлений",
        all: "Все",
        unread: "Непрочитанные"
    },
    menu: {
        profile: "Личный кабинет",
        logout: "Выход",
        monitoring: "Мониторинг",
        tracks: "Треки",
        geoobjects: "Геообъекты",
        history: "История",
        management: "Управление",
        reports: "Отчеты",
        docs: "Руководство пользователя",
        notifications: "Уведомления",
        feedback: "Оставить отзыв",
        messages: "Сообщения",
        bnwas: "Отчет СКДВП"
    },
    channelswidget: {
        value: "Значение",
        time: "Время",
        timereceived: "Время получения",
        choosebeacon: "Маяк не выбран",
        fuelLevelMonitoring: "Контроль уровня топлива",
        fuelConsumptionMonitoring: "Контроль расхода топлива"
    },
    monitoringwidget: {
        groupsettings: "Настройки групп",
        folders: "В виде списка с группами",
        list: "В виде списка"
    },
    infoWidget: {
        diagnostic: "Диагностические сообщения",
        mainInfo: "Основная информация",
        location: "Текущее положение",
        transportCard: "Транспортная карточка",
        iridiumModule: "Модуль Iridium",
        gsmModule: "Модуль GSM",
        bluetoothModule: "Модуль Bluetooth",
        serialNumber: "Серийный номер",
        iridiumImei: "Iridium IMEI",
        gsmImei: "GSM IMEI",
        sim1: "SIM 1",
        sim2: "SIM 2",
        owner: "Владелец",
        coordinates: "Координаты",
        time: "Время",
        packetDt: "Время пакета",
        sensors: "Датчики",
        fuelControl: "Контроль за топливом",
        charts: "Графики",
        events: "События",
        fuelDailyConsumtion: "Суточный расход топлива",
        locationInGeoObjects: "Нахождение в геообъектах",
        buildReport: "Построить отчет"
    },
    groupseditor: {
        add: "Добавить",
        editgroup: "Редактирование группы",
        deleteconfirm: "Удалить группу?"
    },
    notificationssettings: {
        EditTitle: "Редактирование уведомления",
        StandardNotifications: "Стандартные уведомления",
        ConnectionState: "Уведомлять о выходе на связь",
        CommandState: "Уведомлять об отправке команды",
        BNWASState: "Состояние СКДВП",
        GeoobjectsNotifications: "Уведомления по геозонам",
        ChannelsNotifications: "Уведомления по датчикам",
        LastOnline: "Уведомление об отсутствии маяка на связи заданное время",
        // breadcrumb
        notificationssettings: "Настройка уведомлений",
        //
        deleteconfirm: "Удалить уведомление?"
    },
    notificationeditor: {
        connectionEstablished: "Начале сеанса связи",
        connectionClosed: "Окончании сеанса связи",
        geozones: "Геозона",
        notifywhen: "Уведомлять о",
        // geozones
        ingeozone: "Входе в геозону",
        outofgeozone: "Выходе из геозоны",
        // commands
        sendingcommand: "Отправке команды",
        confirmcommand: "Подтверждении команды",
        // sensors
        sensors: "Датчики",
        usechannelthresholds: "Использовать аварийные границы канала",
        normalstate: "Нормальном состоянии",
        preemergencystate: "Предаварийном состоянии",
        emergencystate: "Аварийном состоянии",
        incorrectstate: "Некорректном состоянии",
        interval: "Диапазон",
        // binary sensors
        zerotoone: 'Получении "0"',
        onetozero: 'Получении "1"',
        zerotooneandonetozero: 'Получении "0" или "1"',
        notify: "Уведомлять",
        app: "В приложении",
        email: "Email",
        appandemail: "Email и приложение",
        emails: "Emails",
        lastonline: "Уведомлять, когда маяк оффлайн более заданного количества минут:"
    },
    feedback: {
        title: "Отзыв",
        rate: "Как Вы оцените новую версию LookOut?",
        overall: "Общее впечатление",
        design: "Дизайн",
        functionality: "Функциональность",
        easeofuse: "Простота использования",
        leavecomment: "Пожалуйста, оставьте комментарий",
        send: "Отправить"
    },
    remotecontrol: {
        remotecontrol: "Удаленное управление"
    },
    enums: {
        cardSizes: {
            small: "Маленький",
            normal: "Обычный"
        },
        binaryChannelStatuses: {
            normal: "Норма",
            emergency: "Авария"
        },
        reporttypes: {
            beaconsReport: "По маякам",
            channelsReport: "По каналам",
            beaconDataReport: "Позиция маяка",
            channelDataReport: "Данные датчиков",
            emergenciesReport: "Внештатные ситуации",
            beaconsDataPacketsReport: "Пакеты данных"
        },
        NotificationTypes: {
            BeaconConnectionState: "Состояние соединения",
            BeaconCommandState: "Состояние отправки команды",
            RealChannelState: "Состояние датчика",
            RealChannelStateWithCustomThresholds: "Состояние датчика",
            BinaryChannelState: "Состояние датчика",
            BeaconLocationInGeoobject: "Геообъекты",
            BNWASEmergencyState: "Состояние СКДВП",
            LastOnline: "Маяк оффлайн"
        },
        lengthUnits: {
            m: "Метр",
            km: "Километр",
            mile: "Миля",
            knot: "Узел"
        },
        transporttypes: {
            none: "Другое",
            air: "Воздушный",
            rail: "Ж/Д",
            water: "Водный",
            truck: "Грузовой",
            special: "Специализированный",
            car: "Автомобиль",
            container: "Контейнер"
        },
        timezones: {
            gmtminus12: "(UTC -12:00) Линия перемены дат, Алеутские острова",
            gmtminus10: "(UTC -10:00) Гавайи",
            gmtminus9: "(UTC -09:00) Аляска",
            gmtminus8: "(UTC -08:00) Нижняя Калифорния, Тихоокеанское время (США и Канада)",
            gmtminus7: "(UTC -07:00) Аризона, Горное время (США и Канада), Ла-Пас, Мазатлан, Чихуахуа",
            gmtminus6: "(UTC -06:00) Центральная Америка, о.Пасхи, Саскачеван, Мехико, Монтеррей",
            gmtminus5: "(UTC -05:00) Восточное время (США и Канада), Гавана, Гаити, Богота, Кито",
            gmtminus4: "(UTC -04:00) Атлантическое время (Канада), Сантьяго, Ла-Пас, Каракас",
            gmtminus3: "(UTC -03:00) Бразилия, Гренландия, Монтевидео, Сальвадор",
            gmtminus2: "(UTC -02:00) Время в формате UTC-02",
            gmtminus1: "(UTC -01:00) Азорские о-ва, Кабо-Верде",
            gmt0: "(UTC) Дублин, Эдинбург, Лиссабон, Лондон, Монровия, Рейкьявик",
            gmt1:
                "(UTC +01:00) Амстердам, Берлин, Рим, Стокгольм, Будапешт, Прага, Париж, Сараево, Западная Центральная Европа",
            gmt2:
                "(UTC +02:00) Калининград, Афины, Бухарест, Бейрут, Сектор-Газа, Таллин, Хельсинки, Виндхук, Дамаск, Иерусалим, Кишинев, Триполи",
            gmt3: "(UTC +03:00) Москва, Санкт-Петербург, Минск, Багдад, Нароби, Стамбул, Кувейт",
            gmt4: "(UTC +04:00) Астрахань, Баку, Ереван, Ижевск, Порт-Луи, Тбилиси",
            gmt5: "(UTC +05:00) Екатеринбург, Ашхабад, Ташкент, Исламабад,, Карачи",
            gmt6: "(UTC +06:00) Астана, Дакка, Омск",
            gmt7: "(UTC +07:00) Красноярск, Новосибирск, Бангкок, Ханой, Барнаул, Ховд",
            gmt8: "(UTC +08:00) Иркутск, Гонконг, Пекин, Куала-Лумпур, Сингапур, Перт, Улан-Батор",
            gmt9: "(UTC +09:00) Якутск, Чита, Осака, Токио, Сеул",
            gmt10: "(UTC +10:00) Владивосток, Гуам, Порт-Морсби, Канберра, Мельбурн, Сидней, Хобарт",
            gmt11: "(UTC +11:00) Магадан, Остров Бугенвиль, Остров Норфолк, Сахалин, Соломоновы о-ва, Чокурдах",
            gmt12: "(UTC +12:00) Анадырь, Петропавловск-Камчатский, Веллингтон, Окленд, Фиджи",
            gmt13: "(UTC +13:00) Самоа, Нукуалофа",
            gmt14: "(UTC +14:00) О-в Киритимати"
        },
        monitoringsorting: {
            nameaz: "По названию; А-Я",
            nameza: "По названию; Я-А",
            datedesc: "По дате; Сначала новые",
            dateasc: "По дате; Новые в конце"
        },
        groups: {
            // без группировки
            beacons: "Маяки",
            // личные группы
            nogroup: "Без группы"
        },
        geometrytypes: {
            Polygon: "Полигон",
            Point: "Точка"
        },
        analogFlag: {
            voltage: "Напряжение",
            current: "Ток"
        },
        analogMode: {
            dontSaveData: "не сохранять данные с аналоговых входов",
            saveDataWithCoordinates: "сохранять данные одновременно с получением координаты",
            saveDataWithInterval: "сохранять данные согласно периоду (режим 2)"
        },
        discreteMode: {
            dontSaveData: "не сохранять данные с дискретных входов",
            saveDataWithCoordinates: "сохранять данные одновременно с получением координаты",
            saveDataWithInterval: "сохранять данные согласно периоду (режим 2)",
            saveDataWithStateChange: "сохранять данные по изменению логического состояния"
        },
        ExchangeMode: {
            Interval: "Интервал",
            Schedule: "Расписание"
        },
        DataChannel: {
            OnlyIridium: "только Iridium",
            OnlyGSM: "только GSM",
            PriorityIridium: "приоритет Iridium",
            PriorityGSM: "приоритет GSM"
        },
        nmeaMode: {
            nmeaOn: "режим трансляции NMEA включен",
            nmeaOff: "режим трансляции NMEA выключен",
            adaptiveMode:
                "адаптивный режим (при подключенном внешнем питании предложения NMEA транслируются, при отключении внешнего питания трансляция прекращается)"
        },
        navMessageFormat: {
            onlyCoordAndTime: "только координаты и время",
            dynamicFormation: "динамическое формирование пакета"
        },
        dataChannel: {
            onlyIridium: "только Iridium",
            onlyGSM: "только GSM",
            priorityIridium: "приоритет Iridium",
            priorityGSM: "приоритет GSM"
        },
        modulesAvailability: {
            true: "Да",
            false: "Нет"
        },
        exportTypes: {
            html: "HTML",
            xlsx: "XLSX"
        }
    },
    reports: {
        type: "Тип отчета",
        reports: "Отчеты",
        create: "Создать",
        saveTemplate: "Сохранить",
        loadTemplate: "Загрузить",
        noTemplates: "Нет шаблонов",
        chooseReportOrCreateNew: "Выберите шаблон или создайте новый",
        editor: {
            templateName: "Имя шаблона",
            object: "Объект",
            sensors: "Датчики",
            interval: "Интервал",
            intervalType: "Тип интервала",
            fixedInterval: "Фиксированный",
            daysInterval: "Дни",
            daysBeforeCurrent: "Дни",
            buildWithoutSaving: "Построить",
            creatingReport: "Создание отчета"
        },
        report: {
            templateName: "Имя шаблона",
            reportType: "Тип отчета",
            intervalType: "Тип интервала",
            interval: "Интервал",
            object: "Объект:",
            sensor: "Датчик:",
            sensorType: "Тип датчика:",
            transactions: "Транзакции:"
        },
        viewer: {
            object: "Объект(ы)",
            sensors: "Датчики",
            interval: "Интервал",
            daysBeforeCurrent: "Интервал: {days} дней",
            buildReport: "Построить",
            templateName: "Имя шаблона",
            noInterval: "Интервал не предусмотрен",
            closeReport: "Закрыть",
            fullscreen: "На весь экран"
        }
    },
    messenger: {
        placeholder: "Введите сообщение...",
        client: "Клиент {number}",
        chatLog: {
            loadMore: "Загрузить ещё",
            noMsgs: "Нет сообщений"
        },
        messageStatus: {
            sending: "Отправляется",
            sentToBeacon: "Отправлено на объект",
            sendError: "Ошибка отправки"
        }
    },
    payment: {
        fillBalance: "Пополнить баланс",
        balance: "Баланс: {balance} руб",
        sumToPay: "Сумма к пополнению",
        fill: "Пополнить",
        sumToPayRequired: "Пожалуйста, введите сумму для пополнения баланса"
    },
    paymentsuccess: {
        header: "Средства успешно зачислены",
        description:
            "В течение получаса средства будут зачислены на Ваш счет в сервисе. Оплата услуг возобновится автоматически.",
        toMainPage: "На главную",
        toProfile: "В личный кабинет"
    },
    paymenterror: {
        header: "Не удалось зачислить средства",
        description:
            "Пожалуйста, свяжитесь с нашим специалистом технической поддержки для получения дополнительной информации.",
        toMainPage: "На главную",
        toProfile: "В личный кабинет"
    },
    bnwas: {
        poweroff: "Выключено питание СКДВП",
        off: "СКДВП выключена",
        confirm: "Квитировать СКДВП",
        interfaceerror: "Отсутствие данных СКДВП",
        bnwas: "СКДВП"
    },
    StatusEvent: {
        RebootHardware: "Аппаратная перезагрузка",
        RebootSoftware: "Программная перезагрузка",
        RebootWatchdog: "Перезагрузка по таймеру",
        GPSFault: "Неисправен GPS",
        IridiumFault: "Неисправен Iridium",
        GSMFault: "Неисправен GSM",
        FlashFault: "Неисправна память",
        AccelerometerFault: "Неисправен акселерометр",
        TemperatureFault: "Неисправен датчик температуры",
        SimFault: "Неисправна SIM",
        VoltageFault: "Некорректное напряжение",
        RadioFault: "Неисправен радиомодуль",
        CaseOpen: "Вскрытие корпуса",
        CaseClose: "Закрытие корпуса",
        VoltageLow: "Низкое напряжение питания",
        MountRemove: "Снятие с крепления",
        MountInstall: "Установка на крепление",
        ExternalPowerConnected: "Внешнее питание подключено",
        ExternalPowerDisconnected: "Внешнее питание отключено",
        SOS: "Сигнал SOS",
        BNWASUndefined: "СКДВП не определено",
        BNWASPowerOff: "СКДВП отключено питание",
        BNWASOff: "СКДВП выключено",
        ExternalVoltageLow: "Низкое напряжение внешнего питания",
        ExternalVoltageHigh: "Высокое напряжение внешнего питания",
        InternalVoltageLow: "Низкое напряжение АКБ",
        BoardTempLow: "Низкая температура платы",
        BoardTempHigh: "Высокая температура платы",
        InternalVoltageNormal: "Нормальное напряжение АКБ",
        ExternalVoltageNormal: "Нормальное напряжение внешнего питания",
        BoardTempNormal: "Нормальная температура платы",
        IridiumUnavailable: "Iridium недоступен",
        GSMUnavailable: "GSM недоступен",
        PowerOn: "Включение",
        PowerOff: "Выключение",
        GPSAntennaBlockage: "Блокировка антенны GPS"
    },
    diagnosticMessage: {
        FirmwareVersion: "Версия прошивки",
        Voltage: "Напряжение",
        ExternalVoltage: "Внешнее напряжение",
        ProcessorVoltage: "Напряжение процессора",
        ProcessorTemp: "Температура процессора",
        DataTransferPeriod: "Data transfer period",
        DataTransferStartTime: "Data transfer start time",
        DataTransferChannel: "Data transfer channel",
        GNSSSessionTime: "GNSS session time",
        DataTransferSessionTime: "Data transfer session time",
        CoordinateAcquisitionPeriod: "Coordinate acquisition period",
        GSMPollPeriod: "GSM Poll период",
        ExternalVoltageUpperLimit: "Верхняя граница внешнего напряжения",
        ExternalVoltageLowerLimit: "Нижняя граница внешнего напряжения",
        StoredCoordsCount: "Кол-во сохраненных координат",
        CurrentCoordsCount: "Кол-во координат",
        StatusMessagesCount: "Кол-во статусных сообщений",
        GPSWithNonZeroSignalCount: "Спутников с не нулевым сигналом",
        GPSWithGoodSignalCount: "Спутников с хорошим сигналом",
        GPSPdop: "GPS PDOP",
        GSMImei: "GSM IMEI",
        GSMCcid: "GSM SSID",
        GsmCsq: "GSM CSQ",
        GsmSessionResult: "GSM session result",
        IridiumImei: "Iridium IMEI",
        IridiumCsq: "Iridium CSQ",
        IridiumSessionResult: "Iridium session result",
        GSMFault: "Ошибка GSM",
        IridiumFault: "Ошибка Iridium",
        SIMFault: "Ошибка SIM",
        GPSFault: "Ошибка GPS",
        FlashFault: "Ошибка флэш"
    }
};
