// https://github.com/plotly/plotly.js/blob/master/dist/translation-keys.txt
export default {
    ru: {
        moduleType: "locale",
        name: "ru",
        format: {
            days: [
                "воскресенье",
                "понедельник",
                "вторник",
                "среда",
                "четверг",
                "пятница",
                "суббота"
            ],
            shortDays: ["вск", "пнд", "втр", "срд", "чтв", "птн", "сбт"],
            months: [
                "Январь",
                "Февраль",
                "Март",
                "Апрель",
                "Май",
                "Июнь",
                "Июль",
                "Август",
                "Сентябрь",
                "Октябрь",
                "Ноябрь",
                "Декабрь"
            ],
            shortMonths: [
                "Янв",
                "Фев",
                "Мар",
                "Апр",
                "Май",
                "Июн",
                "Июл",
                "Авг",
                "Сен",
                "Окт",
                "Ноя",
                "Дек"
            ],
            date: "%d.%m.%Y"
        },
        dictionary: {
            Zoom: "Масштабирование",
            Autoscale: "Автомасштаб", // components/modebar/buttons.js:137
            "Compare data on hover": "Показывать все значения", // components/modebar/buttons.js:165
            "Zoom in": "Приблизить", // components/modebar/buttons.js:119
            "Zoom out": "Отдалить", // components/modebar/buttons.js:128
            Pan: "Перемещение", // components/modebar/buttons.js:92
            "Download plot as a png": "Сохранить как PNG", // components/modebar/buttons.js:50
            "Reset axes": "Сбросить оси", // components/modebar/buttons.js:146
            "Toggle Spike Lines": "Показывать проекции", // components/modebar/buttons.js:54
            "Show closest data on hover": "Показывать значение ближайшей точки" // components/modebar/buttons.js:351
        }
    }
};
