import { VNode } from "vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import IBeaconMonitoring from "@/contracts/IBeaconMonitoring";
import { BeaconAdditionalInfo, BeaconType } from "@/api/monitoring";

@Component
export default class BeaconMainInfo extends Vue {
    @Prop()
    beacon!: IBeaconMonitoring;

    @Prop()
    beaconInfo!: BeaconAdditionalInfo;

    public render(): VNode {
        if (!this.beacon || !this.beaconInfo) {
            return <div></div>;
        }
        return (
            <div class="container-fluid pb-3">
                <div class="row">
                    <div class="col px-1 text-right">{this.$t("default.model")}</div>
                    <div class="col px-1 font-weight-semibold word-break-all">
                        {this.$t(`Models.${BeaconType[this.beaconInfo.BeaconType]}`)}
                    </div>
                </div>
                <div class="row">
                    <div class="col px-1 text-right">{this.$t("default.name")}</div>
                    <div class="col px-1 font-weight-semibold word-break-all">{this.beacon.Name}</div>
                </div>
                <div class="row">
                    <div class="col px-1 text-right">{this.$t("default.comment")}</div>
                    <div class="col px-1 font-weight-semibold word-break-all">{this.beaconInfo.Comment}</div>
                </div>
                <div class="row">
                    <div class="col px-1 text-right">{this.$t("infoWidget.iridiumModule")}</div>
                    <div class="col px-1 font-weight-semibold word-break-all">
                        {this.beaconInfo.HasIridiumModule
                            ? this.$t("enums.modulesAvailability.true")
                            : this.$t("enums.modulesAvailability.false")}
                    </div>
                </div>
                <div class="row">
                    <div class="col px-1 text-right">{this.$t("infoWidget.gsmModule")}</div>
                    <div class="col px-1 font-weight-semibold word-break-all">
                        {this.beaconInfo.HasGsmModule
                            ? this.$t("enums.modulesAvailability.true")
                            : this.$t("enums.modulesAvailability.false")}
                    </div>
                </div>
                <div class="row">
                    <div class="col px-1 text-right">{this.$t("infoWidget.bluetoothModule")}</div>
                    <div class="col px-1 font-weight-semibold word-break-all">
                        {this.beaconInfo.HasBluetoothModule
                            ? this.$t("enums.modulesAvailability.true")
                            : this.$t("enums.modulesAvailability.false")}
                    </div>
                </div>
                <div class="row">
                    <div class="col px-1 text-right">{this.$t("infoWidget.serialNumber")}</div>
                    <div class="col font-weight-semibold word-break-all">{this.beacon.SerialNumber}</div>
                </div>
                <div class="row">
                    <div class="col px-1 text-right">{this.$t("infoWidget.iridiumImei")}</div>
                    <div class="col px-1 font-weight-semibold word-break-all">{this.beacon.IridiumImei}</div>
                </div>
                <div class="row">
                    <div class="col px-1 text-right">{this.$t("infoWidget.gsmImei")}</div>
                    <div class="col px-1 font-weight-semibold word-break-all">{this.beacon.GsmImei}</div>
                </div>
                <div class="row">
                    <div class="col px-1 text-right">{this.$t("infoWidget.sim1")}</div>
                    <div class="col px-1 font-weight-semibold word-break-all">{this.beacon.SimChipNumber1}</div>
                </div>
                <div class="row">
                    <div class="col px-1 text-right">{this.$t("infoWidget.sim2")}</div>
                    <div class="col px-1 font-weight-semibold word-break-all">{this.beacon.SimChipNumber2}</div>
                </div>
            </div>
        );
    }
}
