import { Vue, Prop, Component } from "vue-property-decorator";
import "./GeoobjectCard.scss";
import IGeoobject from "@/contracts/IGeoobject";

@Component
export default class GeoobjectCard extends Vue {
    @Prop()
    geoobject!: IGeoobject;

    @Prop()
    isSelected!: boolean;

    @Prop()
    disabled!: boolean;

    @Prop()
    isEditable!: boolean;

    handleSelectGeoobject(e) {
        e.stopPropagation();
        e.preventDefault();
        this.$emit("selectGeoobject", this.geoobject);
    }

    handleEditGeoobject(e) {
        e.stopPropagation();
        e.preventDefault();
        this.$emit("editGeoobject", this.geoobject);
    }

    handleDeleteGeoobject(e) {
        e.stopPropagation();
        e.preventDefault();
        this.$emit("deleteGeoobject", this.geoobject);
    }

    render() {
        return (
            <div
                class={[this.isSelected ? "selected" : "", "lo-card d-flex flex-column p-2 mb-2"]}
                onClick={this.handleSelectGeoobject}
                style="flex: 0 0 auto"
            >
                <div class="lo-card_title">
                    <div class="d-flex align-items-center" style="min-width: 0;">
                        <div
                            style={{
                                backgroundColor: this.geoobject.InterfaceSettings.color
                            }}
                            class="lo-color-swatch mr-2 flex-shrink-0"
                        ></div>
                        <div class="mr-2 text-truncate font-weight-medium font-size-s">{this.geoobject.Name}</div>
                    </div>
                    {this.isEditable && (
                        <div class="d-flex">
                            <el-button
                                disabled={this.disabled}
                                onClick={this.handleEditGeoobject}
                                class="lo-card-button geoobject-card__button"
                                icon="fas fa-pencil"
                            ></el-button>
                            <el-button
                                disabled={this.disabled}
                                onClick={this.handleDeleteGeoobject}
                                class="lo-card-button geoobject-card__button m-0"
                                icon="fas fa-trash"
                            ></el-button>
                        </div>
                    )}
                </div>
                {this.geoobject.Comment && (
                    <div class="lo-card_description font-size-xs text-justify mt-1">
                        <span>{this.geoobject.Comment}</span>
                    </div>
                )}
            </div>
        );
    }
}
