import { Vue, Component, Prop } from "vue-property-decorator";
import { DiagnosticMessageModel } from "@/api/monitoring";

import "./StatusEventCard.scss";

@Component
export default class DiagnosticMessageCard extends Vue {
    @Prop()
    diagnosticMessage!: DiagnosticMessageModel;

    public render() {
        if (!this.diagnosticMessage) {
            return;
        }
        return (
            <div class="lo-card mb-3">
                <div class="d-flex p-3">
                    <div class="mr-3">{this.$t("default.timestamp")}</div>
                    <date-wrapper date={this.diagnosticMessage.PacketDt} format="DD.MM.YY HH:mm" />
                </div>
                <div class="container-fluid pb-3">
                    <div class="row">
                        <div class="col px-1 text-right">{this.$t(`diagnosticMessage.FirmwareVersion`)}</div>
                        <div class="col px-1 font-weight-semibold word-break-all">
                            {this.diagnosticMessage.FirmwareVersion}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col px-1 text-right">{this.$t(`diagnosticMessage.Voltage`)}</div>
                        <div class="col px-1 font-weight-semibold word-break-all">{this.diagnosticMessage.Voltage}</div>
                    </div>
                    <div class="row">
                        <div class="col px-1 text-right">{this.$t(`diagnosticMessage.ExternalVoltage`)}</div>
                        <div class="col px-1 font-weight-semibold word-break-all">
                            {this.diagnosticMessage.ExternalVoltage}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col px-1 text-right">{this.$t(`diagnosticMessage.ProcessorVoltage`)}</div>
                        <div class="col px-1 font-weight-semibold word-break-all">
                            {this.diagnosticMessage.ProcessorVoltage}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col px-1 text-right">{this.$t(`diagnosticMessage.ProcessorTemp`)}</div>
                        <div class="col px-1 font-weight-semibold word-break-all">
                            {this.diagnosticMessage.ProcessorTemp}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col px-1 text-right">{this.$t(`diagnosticMessage.DataTransferPeriod`)}</div>
                        <div class="col px-1 font-weight-semibold word-break-all">
                            {this.diagnosticMessage.DataTransferPeriod}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col px-1 text-right">{this.$t(`diagnosticMessage.DataTransferStartTime`)}</div>
                        <div class="col px-1 font-weight-semibold word-break-all">
                            {this.diagnosticMessage.DataTransferStartTime}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col px-1 text-right">{this.$t(`diagnosticMessage.DataTransferChannel`)}</div>
                        <div class="col px-1 font-weight-semibold word-break-all">
                            {this.diagnosticMessage.DataTransferChannel}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col px-1 text-right">{this.$t(`diagnosticMessage.GNSSSessionTime`)}</div>
                        <div class="col px-1 font-weight-semibold word-break-all">
                            {this.diagnosticMessage.GNSSSessionTime}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col px-1 text-right">{this.$t(`diagnosticMessage.DataTransferSessionTime`)}</div>
                        <div class="col px-1 font-weight-semibold word-break-all">
                            {this.diagnosticMessage.DataTransferSessionTime}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col px-1 text-right">
                            {this.$t(`diagnosticMessage.CoordinateAcquisitionPeriod`)}
                        </div>
                        <div class="col px-1 font-weight-semibold word-break-all">
                            {this.diagnosticMessage.CoordinateAcquisitionPeriod}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col px-1 text-right">{this.$t(`diagnosticMessage.GSMPollPeriod`)}</div>
                        <div class="col px-1 font-weight-semibold word-break-all">
                            {this.diagnosticMessage.GSMPollPeriod}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col px-1 text-right">{this.$t(`diagnosticMessage.ExternalVoltageUpperLimit`)}</div>
                        <div class="col px-1 font-weight-semibold word-break-all">
                            {this.diagnosticMessage.ExternalVoltageUpperLimit}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col px-1 text-right">{this.$t(`diagnosticMessage.ExternalVoltageLowerLimit`)}</div>
                        <div class="col px-1 font-weight-semibold word-break-all">
                            {this.diagnosticMessage.ExternalVoltageLowerLimit}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col px-1 text-right">{this.$t(`diagnosticMessage.StoredCoordsCount`)}</div>
                        <div class="col px-1 font-weight-semibold word-break-all">
                            {this.diagnosticMessage.StoredCoordsCount}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col px-1 text-right">{this.$t(`diagnosticMessage.CurrentCoordsCount`)}</div>
                        <div class="col px-1 font-weight-semibold word-break-all">
                            {this.diagnosticMessage.CurrentCoordsCount}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col px-1 text-right">{this.$t(`diagnosticMessage.StatusMessagesCount`)}</div>
                        <div class="col px-1 font-weight-semibold word-break-all">
                            {this.diagnosticMessage.StatusMessagesCount}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col px-1 text-right">{this.$t(`diagnosticMessage.GPSWithNonZeroSignalCount`)}</div>
                        <div class="col px-1 font-weight-semibold word-break-all">
                            {this.diagnosticMessage.GPSWithNonZeroSignalCount}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col px-1 text-right">{this.$t(`diagnosticMessage.GPSWithGoodSignalCount`)}</div>
                        <div class="col px-1 font-weight-semibold word-break-all">
                            {this.diagnosticMessage.GPSWithGoodSignalCount}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col px-1 text-right">{this.$t(`diagnosticMessage.GPSPdop`)}</div>
                        <div class="col px-1 font-weight-semibold word-break-all">{this.diagnosticMessage.GPSPdop}</div>
                    </div>
                    <div class="row">
                        <div class="col px-1 text-right">{this.$t(`diagnosticMessage.GSMImei`)}</div>
                        <div class="col px-1 font-weight-semibold word-break-all">{this.diagnosticMessage.GSMImei}</div>
                    </div>
                    <div class="row">
                        <div class="col px-1 text-right">{this.$t(`diagnosticMessage.GSMCcid`)}</div>
                        <div class="col px-1 font-weight-semibold word-break-all">{this.diagnosticMessage.GSMCcid}</div>
                    </div>
                    <div class="row">
                        <div class="col px-1 text-right">{this.$t(`diagnosticMessage.GsmCsq`)}</div>
                        <div class="col px-1 font-weight-semibold word-break-all">{this.diagnosticMessage.GsmCsq}</div>
                    </div>
                    <div class="row">
                        <div class="col px-1 text-right">{this.$t(`diagnosticMessage.GsmSessionResult`)}</div>
                        <div class="col px-1 font-weight-semibold word-break-all">
                            {this.diagnosticMessage.GsmSessionResult}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col px-1 text-right">{this.$t(`diagnosticMessage.IridiumImei`)}</div>
                        <div class="col px-1 font-weight-semibold word-break-all">
                            {this.diagnosticMessage.IridiumImei}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col px-1 text-right">{this.$t(`diagnosticMessage.IridiumCsq`)}</div>
                        <div class="col px-1 font-weight-semibold word-break-all">
                            {this.diagnosticMessage.IridiumCsq}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col px-1 text-right">{this.$t(`diagnosticMessage.IridiumSessionResult`)}</div>
                        <div class="col px-1 font-weight-semibold word-break-all">
                            {this.diagnosticMessage.IridiumSessionResult}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col px-1 text-right">{this.$t(`diagnosticMessage.GSMFault`)}</div>
                        <div class="col px-1 font-weight-semibold word-break-all">
                            {this.diagnosticMessage.GSMFault
                                ? this.$t("modulesAvailability.true")
                                : this.$t("modulesAvailability.false")}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col px-1 text-right">{this.$t(`diagnosticMessage.IridiumFault`)}</div>
                        <div class="col px-1 font-weight-semibold word-break-all">
                            {this.diagnosticMessage.IridiumFault
                                ? this.$t("modulesAvailability.true")
                                : this.$t("modulesAvailability.false")}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col px-1 text-right">{this.$t(`diagnosticMessage.SIMFault`)}</div>
                        <div class="col px-1 font-weight-semibold word-break-all">
                            {this.diagnosticMessage.SIMFault
                                ? this.$t("modulesAvailability.true")
                                : this.$t("modulesAvailability.false")}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col px-1 text-right">{this.$t(`diagnosticMessage.GPSFault`)}</div>
                        <div class="col px-1 font-weight-semibold word-break-all">
                            {this.diagnosticMessage.GPSFault
                                ? this.$t("modulesAvailability.true")
                                : this.$t("modulesAvailability.false")}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col px-1 text-right">{this.$t(`diagnosticMessage.FlashFault`)}</div>
                        <div class="col px-1 font-weight-semibold word-break-all">
                            {this.diagnosticMessage.FlashFault
                                ? this.$t("modulesAvailability.true")
                                : this.$t("modulesAvailability.false")}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
