const dateFormats = {
    DATE_FORMAT: "DD.MM.YY",
    TIME_FORMAT: "HH:mm"
};

const config = {
    BASE_URL: "http://localhost:8085",
    DATE_FORMAT: dateFormats.DATE_FORMAT,
    TIME_FORMAT: dateFormats.TIME_FORMAT,
    DATETIME_FORMAT: `${dateFormats.DATE_FORMAT} ${dateFormats.TIME_FORMAT}`,
    EMPTY_STRING: "-"
};

export default config;
