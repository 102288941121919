import Vue from "vue";
import Vuex from "vuex";
import monitoring from "@/store/modules/monitoring";

import tracks from "@/store/modules/tracks";
import geoobjects from "@/store/modules/geoobjects";
import notifications from "@/store/modules/notifications";
import * as types from "@/store/const/mutation-types";

import { loadCurrUser, login } from "@/api/account";

Vue.use(Vuex);

const DEBUG = process.env.NODE_ENV === "development";

export default new Vuex.Store({
    modules: {
        monitoring,
        tracks,
        geoobjects,
        notifications
    },
    state: {
        error: null,
        isLoggedIn: !!localStorage.getItem("token"),
        user: null,
        canvasSize: {
            w: window.innerWidth,
            h: window.innerHeight - 85
        },
        decoratorOptions: {
            minDistanceInMeters: 50,
            minDistanceInPx: 200,
            maxPointVisibilityZoom: 10
        },
        maxMarkerVisibilityZoom: 10,
        mapOptions: {
            center: [54.194442, 37.599728],
            zoom: 10,
            maxZoom: 18
        },
        floatingPanelCurrentView: "",
        beaconsListVisibility: true
    },
    mutations: {
        toggleBeaconsListVisibility(state, value) {
            state.beaconsListVisibility = value;
        },

        [types.ERROR](state, error) {
            state.error = error;
        },
        [types.LOGIN](state) {
            state.isLoggedIn = true;
        },
        [types.LOGOUT](state) {
            state.isLoggedIn = false;
        },
        [types.SET_USER](state, user) {
            state.user = user;
        },
        [types.RESIZE_CANVAS](state, canvasSize) {
            state.canvasSize = canvasSize;
        },
        [types.SET_FLOATING_PANEL_CURRENT_VIEW](state, currentView) {
            state.floatingPanelCurrentView = currentView;
        }
    },
    actions: {
        async login({ commit }, credentials) {
            let { data } = await login({ ...credentials });
            localStorage.setItem("token", data);
            commit(types.LOGIN);
        },
        async logout({ commit }) {
            localStorage.removeItem("token");
            commit(types.LOGOUT);
        },
        async loadUser({ commit }) {
            let { data } = await loadCurrUser();
            commit(types.SET_USER, data);
        }
    },
    strict: DEBUG
});
