import dayjs from "dayjs";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
class DateWrapper extends Vue {
    render() {
        let emptyText = this.emptyText || "-";
        let format = this.format || "DD.MM.YY HH:mm";
        return <span class={this.innerClass}>{this.date ? dayjs(this.date).format(format) : emptyText}</span>;
    }

    @Prop() date!: string;
    @Prop() format!: string;
    @Prop() emptyText!: string;
    @Prop() innerClass!: string;
}

Vue.component("DateWrapper", DateWrapper);
