import { exportFuelReport, FuelReportDraftSettings, ReportDraftInput } from "@/api/report";
import IBeaconMonitoring from "@/contracts/IBeaconMonitoring";
import dayjs from "dayjs";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class FuelReportModal extends Vue {
    @Prop()
    visibility!: boolean;

    @Prop()
    beacon!: IBeaconMonitoring;

    get period() {
        return this.form.StartDt && this.form.EndDt ? [this.form.StartDt, this.form.EndDt] : "";
    }

    set period(value) {
        if (Array.isArray(value) && value.length === 2) {
            this.form.StartDt = value[0];
            this.form.EndDt = value[1];
        }
    }

    private form: Partial<ReportDraftInput<FuelReportDraftSettings>> = {
        StartDt: null,
        EndDt: null,
        Settings: {
            Beacon: this.beacon.Id,
            Channels: []
        }
    };

    private async handleSave() {
        try {
            await (this.$refs.form as any).validate();
            await this.handleExport();
        } catch (error) {}
    }

    private async handleExport() {
        const data = await exportFuelReport(this.form as ReportDraftInput<FuelReportDraftSettings>);
        if (!data) {
            return;
        }
        const url = window.URL.createObjectURL(
            new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            })
        );

        let element = document.createElement("a");
        element.href = url;
        element.download = `${this.beacon.Name}${dayjs().format("DDMMYYYYHHmm")}.xlsx`;
        element.style.display = "none";
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    render() {
        return (
            <el-dialog
                modal-append-to-body
                append-to-body
                close-on-click-modal={false}
                title={this.$t("datepicker.fuelReport")}
                visible={this.visibility}
                onClose={() => this.$emit("visibilityChange", false)}
                width="500px"
            >
                <div class="d-flex flex-column h-100 w-100">
                    <div class="scroll mb-3">
                        <el-form size="medium" ref="form" attrs={{ model: this.form }} show-message={false}>
                            <el-form-item>
                                <el-date-picker
                                    class="w-100"
                                    vModel={this.period}
                                    clearable={false}
                                    format="dd.MM.yy"
                                    type="daterange"
                                    picker-options={{
                                        firstDayOfWeek: 1
                                    }}
                                    range-separator="-"
                                    unlink-panels
                                    start-placeholder={this.$t("datepicker.from")}
                                    end-placeholder={this.$t("datepicker.to")}
                                    align="left"
                                />
                            </el-form-item>
                            {this.form.Settings && (
                                <div>
                                    <div class="font-weight-medium text-left w-100 mb-2">
                                        {this.$t("reports.editor.sensors")}
                                    </div>
                                    <el-form-item
                                        prop="Settings.Channels"
                                        rules={[
                                            {
                                                type: "array",
                                                required: true,
                                                min: 1,
                                                message: "",
                                                trigger: "change"
                                            }
                                        ]}
                                    >
                                        <el-select vModel={this.form.Settings.Channels} multiple filterable>
                                            {this.beacon.Channels.filter(x => x.FuelDailyConsumption).map(channel => (
                                                <el-option key={channel.Id} label={channel.Name} value={channel.Id} />
                                            ))}
                                        </el-select>
                                    </el-form-item>
                                </div>
                            )}
                        </el-form>
                    </div>
                    <div class="d-flex justify-content-center align-items-center">
                        <el-button round size="medium" type="primary" class="mr-2 w-50" onClick={this.handleSave}>
                            {this.$t("reports.editor.buildWithoutSaving")}
                        </el-button>
                    </div>
                </div>
            </el-dialog>
        );
    }
}
