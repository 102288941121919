import NavToggler from "@/components/Menu/NavBar/NavToggler";
import MobileMenu from "@/components/Menu/MobileMenu";

import { Component, Vue, Prop } from "vue-property-decorator";

import "./NavMenu.scss";

@Component({
    components: {
        NavToggler,
        MobileMenu
    }
})
export default class NavMenu extends Vue {
    @Prop({ default: "top" })
    alignment!: string;

    @Prop({ default: "" })
    outerClass!: string;

    @Prop({ default: "" })
    innerClass!: string;

    @Prop({ default: "" })
    logoClass!: string;

    @Prop({ default: "" })
    collapseSize!: string;

    private menuOpen: boolean = false;

    toggleBeaconsList(value) {
        this.$store.commit("toggleBeaconsListVisibility", value);
    }

    get collapseClass() {
        if (this.collapseSize) {
            return `navbar-expand-${this.collapseSize}`;
        }
        return null;
    }

    get lastElementOrder() {
        return this.alignment === "bottom" ? 999 : 0;
    }

    render() {
        return (
            <nav class={["fixed-" + this.alignment, this.collapseClass, this.outerClass, "navbar"]}>
                <a
                    class={["navbar-brand navbar__logo", this.logoClass]}
                    href={"#"}
                    style={{ order: this.lastElementOrder }}
                >
                    {this.$slots.logo}
                </a>
                <nav-toggler
                    class="mr-2"
                    order={this.lastElementOrder}
                    innerClass="fa-lg fal fa-bars"
                    vModel={this.menuOpen}
                />
                <mobile-menu
                    onOpenChanged={val => {
                        this.menuOpen = val;
                    }}
                    open={this.menuOpen}
                />
                <div class="navbar-collapse collapse">
                    {this.$slots.navbarLeft}
                    <div class="navbar-expand__ml">{this.$slots.navbarRight}</div>
                </div>
            </nav>
        );
    }
}
