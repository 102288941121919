import { notificationTypes } from "@/components/Management/Beacons/const/notification-enums";

import config from "@/config";
import "./NotificationCard.scss";
import { NotificationStatusType } from "../Management/Beacons/Notifications/INotificationSettingsForm";

export default ({ props, parent }) => {
    const type = notificationTypes[props.notification.NotificationType];
    if (!type) {
        return null;
    }
    const statusType = NotificationStatusType[props.notification.StatusType];
    if (!statusType) {
        return null;
    }
    const statusTypeClass = `notification-card__status_${statusType.toLowerCase()}`;
    if (props.notification) {
        return (
            <div class={["lo-card notification-card", statusTypeClass, props.className]}>
                <div
                    class={[
                        "notification-card__description",
                        !props.notification.Content && "notification-card__description_grey"
                    ]}
                    {...{
                        domProps: {
                            innerHTML: props.notification.Content || parent.$t("notifications.noinfo")
                        }
                    }}
                />
                <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex align-items-center">
                        <date-wrapper
                            class="notification-card__timestamp"
                            date={props.notification.CreatedDt}
                            format={config.DATETIME_FORMAT}
                        />
                        <div
                            class={[
                                "notification-card__icon-indicator",
                                !props.notification.IsRead && "notification-card__icon-indicator_new"
                            ]}
                        />
                    </div>
                    <div class={["notification-card__type"]}>{parent.$t("notifications." + type.key)}</div>
                </div>
            </div>
        );
    }
    return null;
};
