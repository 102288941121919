import { AxiosError } from "axios";

export default function(
    error: AxiosError
): { code: string | null; description: string } | undefined {
    if (error.response) {
        if (error.response.status === 500) {
            return {
                code: "500",
                description: "errors.internalServerError"
            };
        }
    }
    return {
        code: null,
        description: "errors.serverUnavailable"
    };
}
