import { Component, Prop, Mixins } from "vue-property-decorator";
import ClickOutside from "@/components/Mixins/clickOutside";

@Component
export default class NavItemDropdown extends Mixins(ClickOutside as any) {
    private collapsed: boolean = true;

    @Prop()
    outerClass!: string;

    @Prop()
    innerClass!: string;

    @Prop()
    dropdownClass!: string;

    @Prop()
    direction!: string;
    render() {
        return (
            <li
                class={["nav-item dropdown", this.collapsed ? "" : "show", this.outerClass]}
                onClick={() => {
                    this.collapsed = !this.collapsed;
                }}
                vClickOutside={() => {
                    this.collapsed = true;
                }}
            >
                <a class={["nav-link dropdown-toggle", this.innerClass]} href="#">
                    {this.$slots.header}
                </a>
                <div class={["dropdown-menu dropdown-menu-right", this.collapsed ? "" : "show", this.dropdownClass]}>
                    {this.$slots.default}
                </div>
            </li>
        );
    }
}
