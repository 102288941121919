// import config from 'config'
import http from "./http";
import store from "@/store";
import { ERROR } from "@/store/const/mutation-types";
import handleError from "./handleError";

export async function loadGroups() {
    try {
        return await http.get("/groups");
    } catch (error) {
        store.commit(ERROR, handleError(error));
        throw error;
    }
}

export async function createGroup(group) {
    try {
        return await http.post("/groups", group);
    } catch (error) {
        store.commit(ERROR, handleError(error));
        throw error;
    }
}

export async function updateGroup(group) {
    return http.put("/groups/" + group.Id, group).catch(error => {
        store.commit(ERROR, handleError(error));
    });
}

export async function deleteGroup(id) {
    return http.delete("/groups/" + id).catch(error => {
        store.commit(ERROR, handleError(error));
    });
}
