import { Component, Prop, Vue } from "vue-property-decorator";

@Component
class CoordinatesWrapper extends Vue {
    @Prop() coordinates!: [number, number];
    @Prop() format!: string;
    @Prop() emptyText!: string;
    @Prop() innerClass!: string;

    convertToDMS(coordinate: number): string {
        const dd = Math.trunc(coordinate);
        const mm = Math.trunc((coordinate - dd) * 60);
        const ss = Math.round(((coordinate - dd) * 60 - mm) * 60);
        return `${dd}°${mm}'${ss}"`;
    }

    render(h) {
        const emptyText = this.emptyText || "-";
        const format = this.format || "ddd";
        let coordinates = "";
        if (this.coordinates) {
            if (format === "ddd") {
                coordinates = `${this.coordinates[0].toFixed(6)}, ${this.coordinates[1].toFixed(6)}`;
            } else if (format === "dms") {
                const lat = this.coordinates[0] > 0 ? "N" : "S";
                const lng = this.coordinates[0] > 0 ? "E" : "W";
                coordinates = `${this.convertToDMS(this.coordinates[0])}${lat} ${this.convertToDMS(
                    this.coordinates[1]
                )}${lng}`;
            }
        }
        return <span class={this.innerClass}>{this.coordinates ? coordinates : emptyText}</span>;
    }
}

Vue.component("CoordinatesWrapper", CoordinatesWrapper);
