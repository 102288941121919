import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { State } from "vuex-class";

import { loadMessages, loadUnreadMessagesCount, sendMessage, markMessagesAsRead } from "@/api/messenger";

import ChatInput from "./ChatInput";
import MessageCard from "./MessageCard";

import IMessageOutput from "@/contracts/IMessageOutput";
import IUser from "@/contracts/IUser";
import IConversation from "@/contracts/IConversation";
import "./Chat.scss";
import { IMessage } from "@/contracts/IMessage";

const COUNT: number = 20;

@Component({
    components: {
        ChatInput,
        MessageCard
    }
})
export default class Chat extends Vue {
    @State
    user!: IUser;

    messages: IMessage[] = [];

    loading: boolean = false;

    messagesOffset: number = 0;

    noMoreMessages: boolean = false;

    messageContent: string = "";

    autoUpdateInterval: any = null;

    @Prop()
    beacon!: IConversation;

    @Watch("beacon.Id")
    onBeaconIdChanged() {
        this.loadData(COUNT, 0);
    }

    async loadData(count: number, offset: number) {
        this.messages = [];
        this.messagesOffset = 0;
        const loaded = await this.loadMessages(count, offset);
        if (loaded < COUNT) {
            this.noMoreMessages = true;
        }
    }

    async loadMore() {
        await this.loadMessages(COUNT, this.messages.length);
    }

    async loadMessages(count: number, offset: number) {
        if (this.beacon.Id) {
            this.loading = true;

            const messages = await loadMessages(this.beacon.Id, count, offset);
            this.messages = [...messages, ...this.messages];

            this.loading = false;

            this.messagesOffset += COUNT;

            setTimeout(async () => {
                await this.markAsRead(this.beacon.Id, this.messages[this.messages.length - 1].CreatedDt);
            }, 2000);

            return messages.length;
        }

        return 0;
    }

    async markAsRead(beaconId: number, timestamp: string) {
        if (!this.user.CanReadMessages) {
            return;
        }

        await markMessagesAsRead(beaconId, timestamp);
        this.messages.forEach(message => {
            message.IsRead = true;
        });

        this.$emit("updateUnread", this.beacon.Id);
    }

    async handleSendMessage(messageContent: string) {
        const message: IMessageOutput = {
            Content: messageContent,
            ClientId: 1
        };

        const sentMessage = await sendMessage(this.beacon.Id, message);
        this.messages.push(sentMessage);
        this.messagesOffset = this.messages.length;
    }

    async autoUpdate() {
        const messages = await loadMessages(
            this.beacon.Id,
            undefined,
            undefined,
            this.messages[this.messages.length - 1].CreatedDt
        );

        if (messages.length > 0) {
            this.messages = [...this.messages, ...messages];

            setTimeout(async () => {
                await this.markAsRead(this.beacon.Id, this.messages[this.messages.length - 1].CreatedDt);
            }, 2000);
        }
    }

    created() {
        this.loadData(COUNT, 0);
        this.autoUpdateInterval = setInterval(this.autoUpdate, 10000);
    }

    beforeDestroy() {
        clearInterval(this.autoUpdateInterval);
    }

    render() {
        return (
            <div class="d-flex flex-column flex-grow-1 h-100">
                <div class="pb-3 px-3 d-flex justify-content-between flex-shrink-0 chat__header">
                    <div class="d-flex flex-column w-100">
                        <div class="d-flex">
                            <el-button
                                onClick={() => this.$emit("back")}
                                class="mr-3"
                                icon="fas fa-arrow-left fa-lg"
                                size="small"
                            />
                        </div>
                    </div>
                </div>
                <div class="chat-messages-list" vLoading={this.loading}>
                    <div
                        class="d-flex flex-column scroll h-100 flex-grow-1 w-100"
                        vChatScroll={{ always: false, smooth: false }}
                    >
                        {!this.loading && !this.noMoreMessages ? (
                            <el-button
                                onClick={this.loadMore}
                                class="mt-3 mx-3 flex-shrink-0 load-more-button"
                                type="text"
                            >
                                {this.$t("messenger.chatLog.loadMore")}
                            </el-button>
                        ) : null}
                        {this.messages.map(message => (
                            <message-card beacon={this.beacon} key={message.Id} message={message} />
                        ))}
                        {!this.loading && this.messages.length === 0 ? (
                            <div class="h-100 d-flex flex-column justify-content-center">
                                <div class="text-muted d-flex flex-column justify-content-center align-items-center">
                                    <img class="mb-2" alt="No messages" height="65" src="/static/envelope.svg" />
                                    {this.$t("messenger.chatLog.noMsgs")}
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
                <chat-input
                    disabled={!(this.user.CanSendMessages && this.beacon.CanSendMessages)}
                    onSend={this.handleSendMessage}
                />
            </div>
        );
    }
}
