import Vue from "vue";
import Router from "vue-router";
import store from "@/store";

import LookoutMenu from "@/components/Menu/LookoutMenu";

const BnwasReportView = () => import(/* webpackChunkName: "views" */ "@/views/BnwasReportView");
const ErrorView = () => import(/* webpackChunkName: "views" */ "@/views/ErrorView.vue");
const MonitoringView = () => import(/* webpackChunkName: "views" */ "@/views/MonitoringView");
const TracksView = () => import(/* webpackChunkName: "views" */ "@/views/TracksView");
const GeoobjectsView = () => import(/* webpackChunkName: "views" */ "@/views/GeoobjectsView");
const HistoryView = () => import(/* webpackChunkName: "views" */ "@/views/HistoryView");
const UserProfileView = () => import(/* webpackChunkName: "views" */ "@/views/UserProfileView");
const PaymentView = () => import(/* webpackChunkName: "views" */ "@/views/PaymentView");
const PaymentSuccessView = () => import(/* webpackChunkName: "views" */ "@/views/PaymentSuccessView.vue");
const PaymentErrorView = () => import(/* webpackChunkName: "views" */ "@/views/PaymentErrorView.vue");

const LoginView = () => import(/* webpackChunkName: "account" */ "@/views/LoginView");
const RegisterView = () => import(/* webpackChunkName: "account" */ "@/views/RegisterView");
const ResetPasswordView = () => import(/* webpackChunkName: "account" */ "@/views/ResetPasswordView");

const ManagementView = () => import(/* webpackChunkName: "managementView" */ "@/views/ManagementView");
const BeaconsManagement = () =>
    import(/* webpackChunkName: "managementView" */ "@/components/Management/Beacons/BeaconsManagement");
const ExternalIntegrations = () =>
    import(/* webpackChunkName: "managementView" */ "@/components/Management/Integrations/ExternalIntegrations");
const BeaconManagement = () =>
    import(
        /* webpackChunkName: "managementView" */ "@/components/Management/Beacons/BeaconManagement/BeaconManagement.vue"
    );
const ChannelSettings = () =>
    import(/* webpackChunkName: "managementView" */ "@/components/Management/Beacons/ChannelSettings");
const BeaconSettings = () =>
    import(/* webpackChunkName: "managementView" */ "@/components/Management/Beacons/BeaconSettings");
const BeaconRemoteControl = () =>
    import(
        /* webpackChunkName: "managementView" */ "@/components/Management/Beacons/RemoteControl/BeaconRemoteControl.tsx"
    );
const BeaconCard = () => import(/* webpackChunkName: "managementView" */ "@/components/Management/Beacons/BeaconCard");
const NotificationsSettings = () =>
    import(
        /* webpackChunkName: "managementView" */ "@/components/Management/Beacons/Notifications/NotificationsSettings"
    );
// const BeaconTariffs = () => import(/* webpackChunkName: "beaconTariffs" */ @/components/BeaconsManagement/BeaconTariffs.vue')
// const TasksManagement = () => import(/* webpackChunkName: "tasksManagement" */ @/components/TasksManagement/TasksManagement.vue')

const ReportsView = () => import(/* webpackChunkName: "reportsView" */ "@/views/ReportsView");

Vue.use(Router);

const router = new Router({
    base: "/",
    routes: [
        {
            path: "/bnwas",
            name: "bnwas",
            components: {
                header: LookoutMenu,
                main: BnwasReportView
            },
            meta: { auth: true }
        },
        {
            path: "/error",
            name: "error",
            components: {
                main: ErrorView
            },
            meta: { auth: true }
        },
        {
            path: "/",
            redirect: { name: "monitoring" }
        },
        {
            path: "/login",
            name: "login",
            components: {
                main: LoginView
            },
            meta: { auth: false }
        },
        {
            path: "/register",
            name: "register",
            components: {
                main: RegisterView
            },
            meta: { auth: false }
        },
        {
            path: "/resetpassword",
            name: "resetpassword",
            components: {
                main: ResetPasswordView
            },
            meta: { auth: false }
        },
        {
            path: "/profile",
            name: "profile",
            components: {
                header: LookoutMenu,
                main: UserProfileView
            },
            props: true,
            meta: { auth: true }
        },
        {
            path: "/payment",
            name: "payment",
            components: {
                header: LookoutMenu,
                main: PaymentView
            },
            meta: { auth: true }
        },
        {
            path: "/payment/success",
            name: "paymentsuccess",
            components: {
                header: LookoutMenu,
                main: PaymentSuccessView
            },
            meta: { auth: true }
        },
        {
            path: "/payment/error",
            name: "paymenterror",
            components: {
                header: LookoutMenu,
                main: PaymentErrorView
            },
            meta: { auth: true }
        },
        {
            path: "/monitoring",
            name: "monitoring",
            components: {
                header: LookoutMenu,
                main: MonitoringView
            },
            meta: { auth: true, geoobjects: true }
        },
        {
            path: "/tracks",
            name: "tracks",
            components: {
                header: LookoutMenu,
                main: TracksView
            },
            meta: { auth: true, geoobjects: true }
        },
        {
            path: "/geoobjects",
            name: "geoobjects",
            components: {
                header: LookoutMenu,
                main: GeoobjectsView
            },
            meta: { auth: true }
        },
        {
            path: "/history",
            name: "history",
            components: {
                header: LookoutMenu,
                main: HistoryView
            },
            meta: { auth: true }
        },
        {
            path: "/management",
            name: "management",
            redirect: { name: "beacons" },
            components: {
                header: LookoutMenu,
                main: ManagementView
            },
            meta: { auth: true },
            children: [
                // UserHome will be rendered inside User's <router-view>
                // when /user/:id is matched
                // {
                //   path: 'users',
                //   component: UsersView,
                //   meta: { auth: true }
                // },
                {
                    path: "integrations",
                    component: ExternalIntegrations,
                    name: "integrations",
                    meta: { auth: true }
                },
                {
                    path: "beacons",
                    component: BeaconsManagement,
                    name: "beacons",
                    meta: { auth: true }
                },
                {
                    path: "beacon/:id",
                    component: BeaconManagement,
                    name: "beacon",
                    meta: { auth: true }
                },
                {
                    path: "beacon/:id/channel/:channelid",
                    component: ChannelSettings,
                    name: "channel",
                    meta: { auth: true }
                },
                {
                    path: "beacon/:id/settings",
                    name: "beaconsettings",
                    component: BeaconSettings,
                    meta: { auth: true }
                },
                {
                    path: "beacon/:id/remote",
                    name: "remotecontrol",
                    component: BeaconRemoteControl,
                    meta: { auth: true }
                },
                {
                    path: "beacon/:id/card",
                    name: "card",
                    component: BeaconCard,
                    meta: { auth: true }
                },
                {
                    path: "beacon/:id/notifications",
                    name: "beaconnotifications",
                    component: NotificationsSettings,
                    meta: { auth: true }
                }
            ]
        },
        {
            path: "/reports",
            name: "reports",
            components: {
                header: LookoutMenu,
                main: ReportsView
            },
            meta: { auth: true }
        }
    ]
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.auth)) {
        // этот путь требует авторизации, проверяем залогинен ли
        // пользователь, и если нет, перенаправляем на страницу логина
        if (!store.state.isLoggedIn) {
            next({ path: "/login" });
        } else {
            next();
        }
    } else {
        if (store.state.isLoggedIn) {
            next({
                path: "/"
            });
        } else {
            next(); // всегда так или иначе нужно вызвать next()!
        }
    }
});

export default router;
