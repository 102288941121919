import { Vue, Component } from "vue-property-decorator";
import { findConversations } from "@/search";

import Chat from "./Chat";
import ChatInput from "./ChatInput";

import { loadConversations, loadUnreadMessagesCount } from "@/api/messenger";
import IConversation from "@/contracts/IConversation";

import "./Messenger.scss";
import ConversationCard from "./ConversationCard";
import dayjs from "dayjs";

@Component({
    components: {
        Chat,
        ChatInput,
        ConversationCard
    }
})
export default class Messenger extends Vue {
    private loading: boolean = false;

    filter: string = "";

    mode: "chats" | "chatlog" = "chats";

    selectedConversationId: number | null = null;

    private conversations: IConversation[] = [];
    private autoupdateInterval: any = null;
    async created() {
        this.loading = true;
        this.conversations = await loadConversations();
        this.loading = false;
        this.autoupdateInterval = setInterval(async () => (this.conversations = await loadConversations()), 20000);
    }

    beforeDestroy() {
        clearInterval(this.autoupdateInterval);
    }

    get sortedConversations(): IConversation[] {
        const filteredConversations = findConversations(this.conversations, this.filter);
        return filteredConversations.sort((a, b) => {
            if (a.LastMessage) {
                if (b.LastMessage) {
                    if (dayjs(a.LastMessage.SentDt).isAfter(dayjs(b.LastMessage.SentDt))) {
                        return -1;
                    } else if (dayjs(a.LastMessage.SentDt).isBefore(dayjs(b.LastMessage.SentDt))) {
                        return 1;
                    }
                    return 0;
                } else {
                    return -1;
                }
            } else {
                if (b.LastMessage) {
                    return 1;
                } else if (a.Name.toLowerCase() > b.Name.toLowerCase()) {
                    return 1;
                } else if (a.Name.toLowerCase() < b.Name.toLowerCase()) {
                    return -1;
                }
                return 0;
            }
        });
    }

    get selectedConverstion(): IConversation | undefined {
        return this.conversations.find(conversation => conversation.Id === this.selectedConversationId);
    }

    handleConversationSelect(id: number): void {
        this.selectedConversationId = id;
        this.mode = "chatlog";
    }

    async updateUnread(conversationId: number) {
        const conversation = this.conversations.find(conversation => conversation.Id === conversationId);
        if (conversation) {
            conversation.UnreadCount = await loadUnreadMessagesCount(conversationId);
        }
    }

    render() {
        return (
            <div class="messenger-bar" vLoading={this.loading}>
                <transition mode="out-in" name="fade">
                    {this.mode === "chats" ? (
                        <div class="d-flex flex-column flex-grow-1 h-100" key="chats">
                            <div class="p-2 messenger-bar__header">
                                <el-input
                                    placeholder={this.$t("default.search")}
                                    clearable
                                    size="small"
                                    suffix-icon="el-icon-search"
                                    vModel={this.filter}
                                />
                            </div>
                            <div class="d-flex flex-column scroll flex-grow-1 h-100">
                                {this.sortedConversations.map(conversation => (
                                    <conversation-card
                                        conversation={conversation}
                                        key={conversation.Id}
                                        onSelect={() => this.handleConversationSelect(conversation.Id)}
                                    />
                                ))}
                            </div>
                        </div>
                    ) : this.mode === "chatlog" ? (
                        <chat
                            onUpdateUnread={this.updateUnread}
                            beacon={this.selectedConverstion}
                            onBack={() => (this.mode = "chats")}
                        />
                    ) : null}
                </transition>
            </div>
        );
    }
}
