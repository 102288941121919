import http from "./http";
import store from "@/store";
import { ERROR } from "@/store/const/mutation-types";
import handleError from "./handleError";
import ChartData from "@/contracts/IChartData";
import IBeaconMonitoring from "@/contracts/IBeaconMonitoring";
import { TrackPointState, TrackPointType } from "@/components/Map/yandex/Track";
import { CommunicationType } from "@/contracts/IBeaconManagement";
import { StatusEventEnum } from "./models/Timeline";

export enum FirmwareState {
    None,
    Waiting,
    Complete,
    Error
}

export interface Firmware {
    Id: number;
    Status: FirmwareState;
    FirmwareDescription: string;
    FirmwareSize: number;
    CreatedDt: Date | string;
    LastOperationDt: Date | string | null;
}

export interface ChannelData {
    Id: number;
    Name: string;
    Timestamp: Date | string;
    DisplayValue: string;
    State: TrackPointState;
    TrafficId: number | null;
}

export interface StatusEvent {
    Id: number;
    EventId: StatusEventEnum;
    StartDt: Date | string;
}

export interface TrackPoint {
    Coordinates: number[];
    Timestamp: Date | string;
    Speed: number | null;
    Course: number | null;
    State: TrackPointState;
    Type: TrackPointType;
    CommunicationType: CommunicationType | null;
    ChannelData: ChannelData[];
    StatusEvents: StatusEvent[];
}

export interface Track {
    TrackPoints: TrackPoint[];
    Coordinates: [number, number][];
}

export async function loadBeacons() {
    try {
        let { data } = await http.get<IBeaconMonitoring[]>("/beacons");
        return data;
    } catch (error) {
        store.commit(ERROR, handleError(error));
        throw error;
    }
}

export enum BeaconType {
    Undefined = 0,
    Mini = 1,
    Standard = 2,
    Pro = 3,
    ProEPS = 4,
    Fishery = 5,
    Marine = 6,
    Compact = 7,
    Marine2 = 8,
    Lite = 9
}

export interface BeaconAdditionalInfo {
    Id: number;
    Comment: string;
    ActivatedDt: Date | string | null;
    Status: number;
    GsmStatus: number;
    IridiumStatus: number;

    HasIridiumModule: boolean;
    HasGsmModule: boolean;
    HasBluetoothModule: boolean;

    PowerType: number;
    BeaconType: BeaconType;
}

export interface DiagnosticMessageModel {
    Id: string;
    BeaconId: number;
    PacketDt: string | null;
    TrafficId: number | null;
    FirmwareVersion: string;
    Voltage: number;
    ExternalVoltage: number;
    ProcessorVoltage: number;
    ProcessorTemp: number;
    DataTransferPeriod: number;
    DataTransferStartTime: number;
    DataTransferChannel: number;
    GNSSSessionTime: number;
    DataTransferSessionTime: number;
    CoordinateAcquisitionPeriod: number;
    GSMPollPeriod: number;
    ExternalVoltageUpperLimit: number;
    ExternalVoltageLowerLimit: number;
    StoredCoordsCount: number | null;
    CurrentCoordsCount: number | null;
    StatusMessagesCount: number | null;
    GPSWithNonZeroSignalCount: number | null;
    GPSWithGoodSignalCount: number | null;
    GPSPdop: number;
    GSMImei: string;
    GSMCcid: string;
    GsmCsq: number;
    GsmSessionResult: number;
    IridiumImei: string;
    IridiumCsq: number | null;
    IridiumSessionResult: number;
    GSMFault: boolean;
    IridiumFault: boolean;
    SIMFault: boolean;
    GPSFault: boolean;
    FlashFault: boolean;
}

export interface FuelDailyConsumptionData {
    Timestamps: Date[] | string[];
    Values: FuelDailyConsumptionChannelData[];
}

export interface FuelDailyConsumptionChannelData {
    Name: string;
    Values: (number | null)[];
}

export async function loadDiagnosticMessages(beaconId: number) {
    try {
        let { data } = await http.get<DiagnosticMessageModel[]>(`/beacons.getDiagnosticMessages/${beaconId}`);
        return data;
    } catch (error) {
        store.commit(ERROR, handleError(error));
        throw error;
    }
}

export async function loadBeaconInfo(id: number) {
    try {
        let { data } = await http.get<BeaconAdditionalInfo>(`/beacons.getAdditionalInfo/${id}`);
        return data;
    } catch (error) {
        store.commit(ERROR, handleError(error));
        throw error;
    }
}

export async function loadTrack(beaconId: number, startDt?: string | Date, endDt?: string | Date) {
    endDt = endDt ? new Date(endDt) : new Date(Date.now());
    startDt = startDt ? new Date(startDt) : new Date(Date.now() - 1000 * 60 * 60 * 24 * 2);

    try {
        let { data } = await http.get<Track>(`/beacons.getTrack2/${beaconId}`, {
            params: {
                startDt: startDt.toJSON(),
                endDt: endDt.toJSON()
            }
        });
        return data;
    } catch (error) {
        store.commit(ERROR, handleError(error));
        throw error;
    }
}

export async function loadStatusEvents(beaconId, startDt, endDt) {
    endDt = endDt ? new Date(endDt) : new Date(Date.now());
    startDt = startDt ? new Date(startDt) : new Date(Date.now() - 1000 * 60 * 60 * 24 * 30);

    try {
        let { data } = await http.get<StatusEvent[]>(`/beacons.getStatusEvents/${beaconId}`, {
            params: {
                startDt: startDt.toJSON(),
                endDt: endDt.toJSON()
            }
        });
        return data;
    } catch (error) {
        store.commit(ERROR, handleError(error));
        throw error;
    }
}

export async function loadHistoryTrack(beaconId, startDt, endDt) {
    endDt = endDt ? new Date(endDt) : new Date(Date.now());
    startDt = startDt ? new Date(startDt) : new Date(Date.now() - 1000 * 60 * 60 * 24 * 2);

    try {
        let { data } = await http.get(`/beacons.getHistoryTrack/${beaconId}`, {
            params: {
                startDt: startDt.toJSON(),
                endDt: endDt.toJSON()
            }
        });
        return data;
    } catch (error) {
        store.commit(ERROR, handleError(error));
    }
}

export async function loadHistoryChart(
    beaconId: number,
    startDt: string | Date,
    endDt: string | Date
): Promise<ChartData | null> {
    endDt = endDt ? new Date(endDt) : new Date(Date.now());
    startDt = startDt ? new Date(startDt) : new Date(Date.now() - 1000 * 60 * 60 * 24 * 2);

    try {
        let { data } = await http.get<ChartData>("/beacons.getHistoryData/" + beaconId, {
            params: {
                startDt: startDt.toJSON(),
                endDt: endDt.toJSON()
            }
        });
        return data;
    } catch (error) {
        store.commit(ERROR, handleError(error));
        return null;
    }
}

export async function confirmBNWAS(id: number, value: boolean) {
    try {
        await http.put(`/beacons.confirmBNWAS/${id}`, { bnwasState: value });
    } catch (error) {
        store.commit(ERROR, handleError(error));
    }
}

export async function getFirmwares(id: number) {
    try {
        const { data } = await http.get<Firmware[]>(`/firmware.getFirmwares/${id}`);
        return data;
    } catch (error) {
        store.commit(ERROR, handleError(error));
        throw error;
    }
}

export async function deleteFirmware(id: number) {
    try {
        await http.delete(`/firmware.deleteFirmware/${id}`);
    } catch (error) {
        store.commit(ERROR, handleError(error));
        throw error;
    }
}

export async function updateFirmwareStatus(id: number, status: FirmwareState) {
    try {
        await http.put(`/firmware.updateFirmware/${id}`, { Status: status });
    } catch (error) {
        store.commit(ERROR, handleError(error));
        throw error;
    }
}

export async function loadFuelDailyConsumption(
    beaconId: number,
    startDt?: string | Date,
    endDt?: string | Date
): Promise<FuelDailyConsumptionData | null> {
    endDt = endDt ? new Date(endDt) : new Date(Date.now());
    startDt = startDt ? new Date(startDt) : new Date(Date.now() - 1000 * 60 * 60 * 24 * 7);

    try {
        let { data } = await http.get<FuelDailyConsumptionData>("/beacons.getFuelDailyConsumption/" + beaconId, {
            params: {
                startDt: startDt.toJSON(),
                endDt: endDt.toJSON()
            }
        });
        return data;
    } catch (error) {
        store.commit(ERROR, handleError(error));
        return null;
    }
}
