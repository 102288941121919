import axios, { AxiosInstance } from "axios";

const axiosInstance: AxiosInstance = axios.create();

export const getConfig = () => fetch('/config.json').then(res => res.json())

export const configureAPI = () => getConfig().then(config => {
    axiosInstance.defaults.baseURL = config.apiUrl;
});

export default axiosInstance;
