import { loadBeacons, loadTrack } from "@/api/monitoring";
import { createGroup, loadGroups, deleteGroup, updateGroup } from "@/api/group";

import loDifference from "lodash/difference";
import loUnion from "lodash/union";
import cloneDeep from "lodash/cloneDeep";

import * as types from "@/store/const/mutation-types";
import IBeaconMonitoring from "@/contracts/IBeaconMonitoring";
import { GetterTree, ActionTree, MutationTree } from "vuex";
import { TrackSettings } from "@/components/Map/yandex/Track";
import { TrackData } from "./tracks";

interface State {
    beaconsTrackData: TrackSettings[];
    beaconsPosition: number[];
    groups: any[];
    beacons: IBeaconMonitoring[];
    selected: number | null;
}

const state = {
    beaconsTrackData: [],
    beaconsPosition: [],
    groups: [],
    beacons: [],
    selected: null
};

export const trackData: TrackData[] = [];

const getters: GetterTree<State, any> = {
    getSelectedBeacon: state => {
        if (!state.selected === null) {
            return undefined;
        }
        return state.beacons.find(el => el.Id === state.selected);
    },
    getBeaconsPosition: state => state.beacons.filter(el => state.beaconsPosition.includes(el.Id))
};

const actions: ActionTree<State, any> = {
    async loadBeacons({ commit }) {
        const beacons = await loadBeacons();
        if (beacons) {
            commit(types.LOAD_BEACONS, beacons);
        }
    },
    async showTracks({ commit }, beacons: IBeaconMonitoring[]) {
        let tracksData = await Promise.all(beacons.map(el => loadTrack(el.Id)));
        let tracks = beacons.map((el, index) => {
            trackData.push({
                Id: el.Id,
                TrackPoints: tracksData[index].TrackPoints,
                Coordinates: tracksData[index].Coordinates
            });
            return {
                Id: el.Id,
                BeaconName: el.Name,
                IconPath: el.IconPath,
                Color: el.UserInterfaceSettings.Color,
                LastCoordinateDt: el.Location ? el.Location.Timestamp : null,
                TrackLineWidth: el.UserInterfaceSettings.TrackLineWidth
            };
        });
        commit(types.SHOW_TRACKS, tracks);
    },
    async updateTracks({ commit, state }) {
        const beacons = state.beacons.filter(beacon =>
            state.beaconsTrackData.some(el => {
                return el.Id === beacon.Id;
            })
        );

        const responces = await Promise.all(beacons.map(el => loadTrack(el.Id)));

        const newTrackData: TrackData[] = [];
        const tracks = beacons.map((el, index) => {
            newTrackData.push({
                Id: el.Id,
                TrackPoints: responces[index].TrackPoints,
                Coordinates: responces[index].Coordinates
            });

            return {
                Id: el.Id,
                BeaconName: el.Name,
                IconPath: el.IconPath,
                Color: el.UserInterfaceSettings.Color,
                LastCoordinateDt: el.Location ? el.Location.Timestamp : null,
                TrackLineWidth: el.UserInterfaceSettings.TrackLineWidth
            };
        });

        trackData.splice(0, trackData.length, ...newTrackData);

        commit(types.UPDATE_TRACKS, tracks);
    },
    // Группы маяков на странице мониторинга
    async loadGroups({ commit }) {
        let { data } = await loadGroups();
        commit(types.SET_GROUPS, data);
    },
    async addGroup({ commit }, group) {
        let { data } = await createGroup(group);
        commit(types.ADD_GROUP, data);
    },
    async deleteGroup({ commit }, id) {
        await deleteGroup(id);
        commit(types.DELETE_GROUP, id);
    },
    async updateGroup({ commit }, group) {
        await updateGroup(group);
        commit(types.UPDATE_GROUP, group);
    }
};

const mutations: MutationTree<State> = {
    [types.LOAD_BEACONS](state, beacons) {
        state.beacons = cloneDeep(beacons);
    },
    [types.SET_GROUPS](state, groups) {
        state.groups = groups;
    },
    // группы объектов
    [types.ADD_GROUP](state, group) {
        state.groups = [...state.groups, group];
    },
    [types.DELETE_GROUP](state, id) {
        state.groups = state.groups.filter(el => el.Id !== id);
    },
    [types.UPDATE_GROUP](state, updatedGroup) {
        state.groups = state.groups.map(group => (group.Id === updatedGroup.Id ? updatedGroup : group));
    },
    // Позиция объекта
    [types.SHOW_POSITIONS](state, beaconIds) {
        state.beaconsPosition = loUnion(state.beaconsPosition, beaconIds);
    },
    [types.HIDE_POSITIONS](state, beacons) {
        state.beaconsPosition = loDifference(state.beaconsPosition, beacons);
    },
    // трек объекта
    [types.SHOW_TRACKS](state, tracks) {
        state.beaconsTrackData = state.beaconsTrackData.concat(tracks);
    },
    [types.HIDE_TRACKS](state, beaconIds) {
        state.beaconsTrackData = state.beaconsTrackData.filter(el => !beaconIds.includes(el.Id));
    },
    [types.UPDATE_TRACKS](state, tracks) {
        state.beaconsTrackData = tracks;
    },
    // Выбор и фокусировка объекта
    [types.SELECT_BEACON](state, id) {
        state.selected = id;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
