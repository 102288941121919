import { Vue, Component } from "vue-property-decorator";
import IChannelMonitoring from "@/contracts/IChannelMonitoring";

import dayjs from "dayjs";

import states from "@/const/beacon-states";
import { Getter, State } from "vuex-class";
import IBeaconMonitoring from "@/contracts/IBeaconMonitoring";

import "./BeaconChannels.scss";
import IUser from "@/contracts/IUser";

@Component
export default class BeaconChannels extends Vue {
    private sortByTimestamp(a: IChannelMonitoring, b: IChannelMonitoring) {
        return dayjs(a.Timestamp).isAfter(dayjs(b.Timestamp))
            ? 1
            : dayjs(a.Timestamp).isBefore(dayjs(b.Timestamp))
            ? -1
            : 0;
    }

    private sortByPacketDt(a: IChannelMonitoring, b: IChannelMonitoring) {
        return dayjs(a.PacketDt).isAfter(dayjs(b.PacketDt)) ? 1 : dayjs(a.PacketDt).isAfter(dayjs(b.PacketDt)) ? -1 : 0;
    }

    private handleChannelSettings(channel: IChannelMonitoring) {
        this.$router.push({
            name: "channel",
            params: { id: this.beacon.Id.toString(), channelid: channel.Id.toString() }
        });
    }

    private valueCellStyle({ row, column, rowIndex, columnIndex }) {
        if (columnIndex === 1) {
            if (row.ChannelFlag === 11 && !this.beacon.BNWASShowStatus && row.Value !== 0) {
                return "";
            }
            return states.channelValueStates[row.ValueEmergencyState].class;
        } else if (columnIndex === 3) {
            return states.relevanceStates[row.RelevanceState].class;
        }
    }

    @Getter("getSelectedBeacon", { namespace: "monitoring" })
    private beacon!: IBeaconMonitoring;

    @State("user")
    private user!: IUser;

    private TimestampCell(scope: { row: IChannelMonitoring }) {
        return (
            <div class="beacon-channels__timestamp">
                <date-wrapper date={scope.row.Timestamp} format="DD.MM.YY HH:mm" />
            </div>
        );
    }

    render() {
        return (
            <el-table
                data={this.beacon.Channels}
                empty-text={this.$t("default.nodata")}
                fit
                border
                header-cell-class-name="table-header"
                cell-class-name={this.valueCellStyle}
            >
                <el-table-column
                    sortable
                    show-overflow-tooltip
                    min-width="150"
                    label={this.$t("default.name")}
                    prop="Name"
                />
                <el-table-column
                    min-width="120"
                    align="left"
                    label={this.$t("channelswidget.value")}
                    {...{
                        scopedSlots: {
                            default: (scope: { row: IChannelMonitoring }) => {
                                return (
                                    <div class="beacon-channels__value" style="min-width: 0">
                                        {scope.row.DisplayedValue ? scope.row.DisplayedValue : "-"}
                                    </div>
                                );
                            }
                        }
                    }}
                />
                <el-table-column
                    sortable
                    min-width="160"
                    sort-method={this.sortByTimestamp}
                    label={this.$t("channelswidget.time")}
                    {...{
                        scopedSlots: {
                            default: this.TimestampCell
                        }
                    }}
                />
                <el-table-column
                    sortable={true}
                    sort-method={this.sortByPacketDt}
                    label={this.$t("channelswidget.timereceived")}
                    min-width="160"
                    {...{
                        scopedSlots: {
                            default: (scope: { row: IChannelMonitoring }) => {
                                return (
                                    <div class="beacon-channels__timestamp">
                                        <date-wrapper date={scope.row.PacketDt} format="DD.MM.YY HH:mm" />
                                    </div>
                                );
                            }
                        }
                    }}
                />
                {this.beacon.CanEditChannelsSettings && this.user.CanEditChannelsSettings && (
                    <el-table-column
                        label={this.$t("default.actions")}
                        width="100"
                        {...{
                            scopedSlots: {
                                default: (scope: { row: IChannelMonitoring }) => {
                                    return (
                                        <div class="d-flex align-items-center justify-content-center">
                                            <el-button-group>
                                                <el-button
                                                    size="small"
                                                    type="text"
                                                    icon="fa-lg fad fa-sliders-h"
                                                    onClick={() => this.handleChannelSettings(scope.row)}
                                                />
                                            </el-button-group>
                                        </div>
                                    );
                                }
                            }
                        }}
                    />
                )}
            </el-table>
        );
    }
}
