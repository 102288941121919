import IconBase from "@/components/Icons/IconBase.vue";

export default ({ props }) => (
    <div
        style={{
            backgroundColor: `${props.color}22`
        }}
        class="rounded-circle mr-3"
    >
        <IconBase
            icon-color={props.color}
            icon-name={props.iconPath}
            icon-width={props.width}
            icon-height={props.height}
        />
    </div>
);
