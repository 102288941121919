import { Vue, Component, Prop } from "vue-property-decorator";

@Component
class DurationWrapper extends Vue {
    render() {
        let durationSplit = this.duration.split(".");
        let days = 0;
        let time = "";
        if (durationSplit.length === 2) {
            days = Number(durationSplit[0]);
            time = durationSplit[1];
        } else {
            time = durationSplit[0];
        }
        return (
            <span class={this.innerClass}>
                {durationSplit.length === 2 && <span class="mr-1">{days} дн.</span>}
                <span>{time}</span>
            </span>
        );
    }

    @Prop() duration!: string;
    @Prop() innerClass!: string;
}

Vue.component("DurationWrapper", DurationWrapper);
