import { Vue, Component, Prop, Model } from "vue-property-decorator";

import "./SidePage.scss";

@Component
export default class SidePage extends Vue {
    @Model("hide", { type: Boolean })
    readonly visibility!: boolean;

    @Prop()
    readonly minWidth!: string;

    @Prop()
    readonly headerClass!: string;

    @Prop()
    readonly headerStyle!: string;

    @Prop()
    readonly header!: string;

    @Prop()
    readonly outerClass!: string;

    private get panelStyle() {
        return {
            minWidth: this.minWidth || 0
        };
    }

    private hide() {
        this.$emit("hide", false);
    }

    render() {
        return (
            <transition name="slide-right">
                {this.visibility ? (
                    <div class={[this.outerClass, "side-page"]} style={this.panelStyle}>
                        <div class={[this.headerClass, "side-page__header"]} style={this.headerStyle}>
                            <div class="side-page__name">{this.header}</div>
                            <i class="fal fa-lg fa-times lo-card-button side-page__close" onClick={this.hide} />
                        </div>
                        <div class="side-page__body">{this.$slots.default}</div>
                    </div>
                ) : null}
            </transition>
        );
    }
}
