import { NotificationStatusType } from "../Notifications/INotificationSettingsForm";

export const notificationTypes = {
    1: {
        key: "BeaconConnectionState",
        value: 1,
        statusType: NotificationStatusType.Info,
        settings: {
            Trigger: [1, 2] // 1 - Выходе, 2 - Конце
        }
    },
    3: {
        key: "BeaconCommandState",
        value: 3,
        statusType: NotificationStatusType.Info,
        settings: {
            Trigger: [0, 1] // 0 - отправке, 1 - подтверждении
        }
    },
    4: {
        key: "RealChannelState",
        value: 4,
        statusType: NotificationStatusType.Emergency,
        settings: {
            Trigger: [2]
            // NormalState = 0,
            // PreemergencyState = 1,
            // EmergencyState = 2,
            // IncorrectState = 3
        }
    },
    5: {
        key: "RealChannelStateWithCustomThresholds",
        value: 5,
        statusType: NotificationStatusType.Emergency,
        settings: {
            MinThreshold: 0,
            MaxThreshold: 0
        }
    },
    6: {
        key: "BinaryChannelState",
        value: 6,
        statusType: NotificationStatusType.Emergency,
        settings: {
            Trigger: 0
            // ZeroToOne = 0,
            // OneToZero = 1,
            // Both=2
        }
    },
    8: {
        key: "BeaconLocationInGeoobject",
        value: 8,
        statusType: NotificationStatusType.Info,
        settings: {
            Trigger: [1, 2] // 1 - inside, 2 - outside
        }
    },
    9: {
        key: "BNWASEmergencyState",
        statusType: NotificationStatusType.Emergency,
        value: 9,
        settings: null
    },
    10: {
        key: "LastOnline",
        statusType: NotificationStatusType.Emergency,
        value: 10,
        settings: {
            MinutesTimeout: 60
        }
    }
};
