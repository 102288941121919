import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import IBeaconMonitoring from "@/contracts/IBeaconMonitoring";
import Chart from "./Chart";
import { loadHistoryChart } from "@/api/monitoring";
import ChartData from "@/contracts/IChartData";

@Component({
    components: {
        Chart
    }
})
export default class BeaconChart extends Vue {
    @Getter("getSelectedBeacon", { namespace: "monitoring" })
    private beacon!: IBeaconMonitoring;

    @Prop()
    isActive!: boolean;

    @Watch("beacon")
    onBeacon(value: IBeaconMonitoring, oldValue: IBeaconMonitoring) {
        if (value.Id !== oldValue.Id) {
            this.loadData(value);
        }
    }

    chartData: ChartData | null = null;
    loading: boolean = false;

    private async loadData(beacon: IBeaconMonitoring) {
        try {
            if (beacon.ConnectionDt) {
                this.loading = true;
                const start = new Date(beacon.ConnectionDt);
                const end = new Date(beacon.ConnectionDt);
                start.setTime(start.getTime() - 1000 * 60 * 60 * 24);
                this.chartData = await loadHistoryChart(this.beacon.Id, start, end);
            }
        } catch (error) {
        } finally {
            this.loading = false;
        }
    }

    public mounted() {
        this.loadData(this.beacon);
    }

    public render() {
        if (
            this.chartData &&
            Object.values(this.chartData.ChartChannelData).some(value => value.Values && value.Values.length > 0)
        )
            return (
                <div style="height: 400px;">
                    <chart
                        style="height: 400px;"
                        ref="chart"
                        clickEvent={false}
                        loading={this.loading}
                        chartData={this.chartData}
                    />
                </div>
            );
    }
}
