import { Vue, Component } from "vue-property-decorator";
import NotificationCard from "@/components/Notifications/NotificationCard";
import { SET_UNREAD_NOTIFICATIONS_COUNT } from "@/store/const/mutation-types";
import { loadNotificationsCount, loadNotifications, markNotificationsAs } from "@/api/notifications";
import { State, Mutation } from "vuex-class";
import "./NotificationsBar.scss";
import groupBy from "lodash/groupBy";
import INotification from "@/contracts/INotification";
import dayjs from "dayjs";

const PAGE_SIZE = 20;

@Component({
    components: {
        NotificationCard: NotificationCard as any
    }
})
export default class NotificationsBar extends Vue {
    notifications: INotification[] = [];
    unreadNotifications: INotification[] = [];
    groupedNotifications: any = {};
    count: number = 0;
    loading: boolean = false;

    @State
    canvasSize;

    @State("unreadNotificationsCount", { namespace: "notifications" })
    unreadNotificationsCount!: number;

    @Mutation(SET_UNREAD_NOTIFICATIONS_COUNT, { namespace: "notifications" })
    setUnreadNotificationCount!: (count: number) => void;

    created() {
        this.reloadNotifications();
    }

    get showMarkAsReadBtn() {
        return this.unreadNotificationsCount > 0;
    }

    get loadedCount() {
        if (this.notifications) {
            return this.notifications.length;
        }
        return 0;
    }

    get hasNotificationsToLoad() {
        return this.loadedCount < this.count;
    }

    async reloadNotifications() {
        this.notifications = [];
        this.unreadNotifications = [];
        this.count = await loadNotificationsCount(false);
        const unreadCount = await loadNotificationsCount(true);
        this.setUnreadNotificationCount(unreadCount);

        await this.loadNotifications();
    }

    async loadNotifications() {
        this.loading = true;
        const notifications = await loadNotifications(PAGE_SIZE, this.loadedCount, false);
        this.notifications = [...this.notifications, ...notifications];
        const groupedNotifications = groupBy(this.notifications, notification =>
            dayjs(notification.CreatedDt)
                .startOf("day")
                .format("DD-MMM-YY")
        );
        const unreadCount = await loadNotificationsCount(true);
        if (unreadCount !== this.unreadNotificationsCount || unreadCount > 0) {
            this.setUnreadNotificationCount(unreadCount);
            this.unreadNotifications = await loadNotifications(unreadCount, 0, true);
            if (this.unreadNotifications.length > 0) {
                groupedNotifications[this.$t("notifications.unread").toString()] = this.unreadNotifications;
            }
        }

        this.groupedNotifications = groupedNotifications;
        this.loading = false;
    }

    async markAsRead() {
        const ids = this.unreadNotifications.map(notification => notification.Id);
        await markNotificationsAs(ids, true, true);
        await this.reloadNotifications();
    }

    sortDates(date1: string, date2: string) {
        if (date1 === this.$t("notifications.unread").toString()) {
            return -1;
        } else if (date2 === this.$t("notifications.unread").toString()) {
            return 1;
        }
        const dateJs1 = dayjs(date1);
        const dateJs2 = dayjs(date2);
        return dateJs1.isAfter(dateJs2) ? 1 : dateJs1.isBefore(dateJs2) ? -1 : 0;
    }

    render() {
        return (
            <div vLoading={this.loading} class="notification-bar" id="loadingPanel">
                <div class="notification-bar__header">
                    <el-button
                        type="text"
                        size="medium"
                        disabled={this.loading || !this.showMarkAsReadBtn}
                        onClick={this.markAsRead}
                    >
                        {this.$t("notifications.markasread")}
                    </el-button>
                </div>
                <div class="d-flex flex-column scroll flex-grow-1 p-2">
                    {this.loadedCount > 0 ? (
                        <div>
                            {Object.keys(this.groupedNotifications)
                                .sort(this.sortDates)
                                .map(value => {
                                    return (
                                        <div>
                                            <div class="d-flex p-1 justify-content-center">
                                                <div class="notification-bar__dates">{value}</div>
                                            </div>
                                            {this.groupedNotifications[value].map(notification => (
                                                <notification-card
                                                    className="mb-2 flex-shrink-0"
                                                    key={notification.Id}
                                                    notification={notification}
                                                />
                                            ))}
                                        </div>
                                    );
                                })}
                            <div class="notification-bar__loadmore">
                                {this.hasNotificationsToLoad ? (
                                    <el-button
                                        round
                                        type="primary"
                                        vShow={!this.loading}
                                        onClick={this.loadNotifications}
                                    >
                                        {this.$t("notifications.loadmore")}
                                    </el-button>
                                ) : (
                                    <span class="text-muted font-size-s">
                                        {this.$t("notifications.NoNotificationsToLoad")}
                                    </span>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div class="d-flex justify-content-center text-muted align-items-center h-100">
                            <span>{this.$t("notifications.nonotifications")}</span>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
