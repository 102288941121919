import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class NavToggler extends Vue {
    @Prop({ required: true })
    value;

    @Prop({ default: 0 })
    order;

    @Prop()
    outerClass!: string;

    @Prop()
    innerClass!: string;

    private togglerStyle = {
        order: this.order
    };

    update() {
        this.$emit("input", !this.value);
    }

    render() {
        return (
            <div
                class={["lo-navbar-toggler collapsed", this.outerClass]}
                style={this.togglerStyle}
                onClick={this.update}
            >
                <span class={[this.innerClass]} />
            </div>
        );
    }
}
