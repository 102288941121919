export enum NotificationType {
    None = 0,
    BeaconConnectionState = 1,
    BeaconDаtaRelevance = 2,
    BeaconCommandState = 3,
    RealChannelState = 4,
    RealChannelStateWithCustomThresholds = 5,
    BinaryChannelState = 6,
    EnumChannelState = 7,
    BeaconLocationInGeoobject = 8,
    BNWASEmergencyState = 9,
    LastOnline = 10
}

export enum NotificationMode {
    App,
    Email
}

export enum NotificationStatusType {
    None = 0,
    Normal = 1,
    PreEmergency = 2,
    Emergency = 3,
    Info = 4
}

export interface INotificationSettings {
    Id: number;
    Name: string;
    BeaconId: number | null;
    ChannelId: number | null;
    GeoobjectId: number | null;
    NotificationModes: NotificationMode[];
    NotificationType: NotificationType;
    StatusType: NotificationStatusType;
    Settings: any;
    Emails: string[];
    IsDisabled: boolean;
}
