export interface IMessage {
    Id: number;
    Content: string;
    ClientId: number;
    SentDt: string;
    CreatedDt: string;
    Status: MessageStatus;
    IsRead: boolean;
    BeaconId: number;
    UserId: number;
    IsCurrentUser: boolean;
    UserName: string;
}

export enum MessageStatus {
    Waiting,
    Sent,
    SentAndConfirmedByIridium,
    SentAndConfirmed,
    Incoming,
    Error
}
