import http from "./http";
import store from "@/store";
import { ERROR } from "@/store/const/mutation-types";
import handleError from "./handleError";
import IReportTemplate from "@/contracts/Reports/IReportTemplate";
import { IReport } from "@/contracts/Reports/IReport";

export interface BnwasReport {
    Id: number;
    Name: string;
    Distance: number;
    MovementTime: number;
    AlarmTime: number;
    NormalTime: number;
    AlarmTimePercentage: number;
    NormalTimePercentage: number;
    Segments: BnwasReportSegment[];
}

export interface BnwasReportSegment {
    Type: SegmentType;
    Distance: number;
    AverageSpeed: number;
    Time: number;
    AlarmTime: number;
    NormalTime: number;
    StartDt: Date | string;
    EndDt: Date | string;
}

export enum SegmentType {
    Move,
    Stop
}

export enum IntervalType {
    None = 0,
    DaysFromCurrent = 1,
    Fixed = 2
}

export interface FuelReportDraftSettings {
    Beacon: number;
    Channels: number[];
}
export interface ReportDraftInput<T> {
    Name: string;
    IntervalType: IntervalType;
    IntervalFromBaseDt: number | null;
    StartDt: string | null;
    EndDt: string | null;
    Settings: T;
}

export async function loadBnwasReport(
    ids: number[],
    startDt: Date,
    endDt: Date,
    minSpeed: number,
    minDistance: number
) {
    let params = new URLSearchParams();
    if (ids && ids.length > 0) {
        ids.forEach(value => {
            params.append("ids", value.toString());
        });
    }
    params.append("startDt", startDt.toJSON());
    params.append("endDt", endDt.toJSON());
    params.append("minSpeed", minSpeed.toString());
    params.append("minDistance", minDistance.toString());

    try {
        let { data } = await http.get<BnwasReport[]>("/beacons.getBnwasReport", {
            params: params
        });
        return data;
    } catch (error) {
        store.commit(ERROR, handleError(error));
        throw error;
    }
}

export async function loadReportTemplates(): Promise<IReportTemplate[]> {
    try {
        let { data } = await http.get<IReportTemplate[]>("/ReportTemplates");
        return data;
    } catch (error) {
        store.commit(ERROR, handleError(error));
        return [];
    }
}

export async function createReportTemplate(reportTemplate: IReportTemplate): Promise<IReportTemplate | void> {
    try {
        let { data } = await http.post<IReportTemplate>("/ReportTemplates", reportTemplate);
        return data;
    } catch (error) {
        store.commit(ERROR, handleError(error));
    }
}

// export async function updateReportTemplate(reportTemplate: IReportTemplate): Promise<void> {
//     try {
//         await http.put(`/ReportTemplates/${reportTemplate.Id}`, reportTemplate);
//     } catch (error) {
//         store.commit(ERROR, handleError(error));
//     }
// }

export async function deleteReportTemplate(id: number): Promise<void> {
    try {
        await http.delete(`/ReportTemplates/${id}`);
    } catch (error) {
        store.commit(ERROR, handleError(error));
    }
}

export async function buildReport(template: IReportTemplate): Promise<IReport | void> {
    try {
        let { data } = await http.post<IReport>("/Reports/", template);
        return data;
    } catch (error) {
        store.commit(ERROR, handleError(error));
    }
}

export async function exportReport(template: IReportTemplate, format: number): Promise<ArrayBuffer | void> {
    try {
        let { data } = await http.post<ArrayBuffer>("/Reports.export", template, {
            responseType: "arraybuffer",
            params: {
                format
            }
        });
        return data;
    } catch (error) {
        store.commit(ERROR, handleError(error));
    }
}

export async function exportFuelReport(draft: ReportDraftInput<FuelReportDraftSettings>): Promise<ArrayBuffer | void> {
    try {
        let { data } = await http.post<ArrayBuffer>("/reports.exportFuelReport", draft, {
            responseType: "arraybuffer"
        });
        return data;
    } catch (error) {
        store.commit(ERROR, handleError(error));
    }
}
