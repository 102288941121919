import { createGeoobject, updateGeoobject, deleteGeoobject, loadGeoobjects } from "@/api/geoobjects";
import loUnion from "lodash/union";
import loDifference from "lodash/difference";
import * as types from "../const/mutation-types";

const state = {
    currentGeoobjectId: null,
    geoobjects: [],
    selectedGeoobjectsIds: [],
    tracksSelectedGeoobjectsIds: []
};

const getters = {
    selectedGeoobjects: state => state.geoobjects.filter(el => state.selectedGeoobjectsIds.includes(el.Id)),
    tracksSelectedGeoobjects: state => state.geoobjects.filter(el => state.tracksSelectedGeoobjectsIds.includes(el.Id)),
    currentGeoobject: state => state.geoobjects.find(el => state.currentGeoobjectId === el.Id)
};

const actions = {
    async loadGeoobjects({ commit }) {
        let { data } = await loadGeoobjects();
        commit(types.SET_GEOOBJECTS, data);
    },
    async createGeoobject({ commit }, geoobject) {
        let { data } = await createGeoobject(geoobject);
        commit(types.INSERT_GEOOBJECT, data);
        commit(types.SET_CURRENT_GEOOBJECT_ID, data.Id);
    },
    async updateGeoobject({ commit }, geoobject) {
        await updateGeoobject(geoobject);
        commit(types.UPDATE_GEOOBJECT, geoobject);
    },
    async deleteGeoobject({ commit }, id) {
        await deleteGeoobject(id);
        commit(types.DELETE_GEOOBJECT, id);
    }
};

const mutations = {
    [types.HIDE_ALL_GEOOBJECT_MONITORING](state) {
        state.selectedGeoobjectsIds = [];
    },
    [types.HIDE_ALL_GEOOBJECT_TRACKS](state) {
        state.tracksSelectedGeoobjectsIds = [];
    },
    [types.SHOW_ALL_GEOOBJECT_MONITORING](state) {
        state.selectedGeoobjectsIds = state.geoobjects.map(el => el.Id);
    },
    [types.SHOW_ALL_GEOOBJECT_TRACKS](state) {
        state.tracksSelectedGeoobjectsIds = state.geoobjects.map(el => el.Id);
    },
    [types.TOGGLE_GEOOBJECT_MONITORING](state, id) {
        if (state.selectedGeoobjectsIds.includes(id)) {
            state.selectedGeoobjectsIds = loDifference(state.selectedGeoobjectsIds, [id]);
        } else {
            state.selectedGeoobjectsIds = loUnion(state.selectedGeoobjectsIds, [id]);
        }
    },
    [types.TOGGLE_GEOOBJECT_TRACKS](state, id) {
        if (state.tracksSelectedGeoobjectsIds.includes(id)) {
            state.tracksSelectedGeoobjectsIds = loDifference(state.tracksSelectedGeoobjectsIds, [id]);
        } else {
            state.tracksSelectedGeoobjectsIds = loUnion(state.tracksSelectedGeoobjectsIds, [id]);
        }
    },
    [types.SET_GEOOBJECTS](state, geoobjects) {
        state.geoobjects = geoobjects;
    },
    [types.SET_CURRENT_GEOOBJECT_ID](state, id) {
        state.currentGeoobjectId = id;
    },
    [types.INSERT_GEOOBJECT](state, geoobject) {
        state.geoobjects.unshift(geoobject);
    },
    [types.DELETE_GEOOBJECT](state, id) {
        state.geoobjects = state.geoobjects.filter(x => x.Id !== id);
    },
    [types.UPDATE_GEOOBJECT](state, geoobject) {
        state.geoobjects = state.geoobjects.filter(el => el.Id !== geoobject.Id);
        state.geoobjects.push(geoobject);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
