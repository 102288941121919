import { loadNotificationsCount, loadNotifications, markNotificationsAs } from "@/api/notifications";
import NotificationCard from "@/components/Notifications/NotificationCard";

import { Component, Vue } from "vue-property-decorator";
import { State } from "vuex-class";
import INotification from "@/contracts/INotification";

const OFFSET_TOP = 60;
const MIN_NOTIFICATION_HEIGHT = 125;

@Component({
    components: { NotificationCard: NotificationCard as any }
})
export default class PushNotifications extends Vue {
    notifications: INotification[] = [];

    @State
    canvasSize;

    get notificationsPerPageCount() {
        return Math.trunc(this.canvasSize.h / MIN_NOTIFICATION_HEIGHT);
    }

    mounted() {
        this.$root.$on("autoUpdate:start", this.startProcessNotifications);
    }

    async displayNotifications() {
        this.notifications.forEach(notification => {
            setTimeout(() => {
                this.$notify({
                    title: "",
                    message: this.$createElement("notification-card", {
                        props: {
                            notification,
                            trimContent: true
                        }
                    }),
                    offset: OFFSET_TOP,
                    duration: 7000,
                    onClose: () => {}
                });
            }, 0);
        });
        await this.markAsShown(this.notifications);
        this.notifications = [];
    }

    async markAsShown(notifications) {
        let ids = notifications.map(notification => notification.Id);
        await markNotificationsAs(ids, false, true);
    }

    async updateNotifications() {
        const notificationsCount = await loadNotificationsCount(false, true);
        if (notificationsCount > 0) {
            this.notifications = await loadNotifications(this.notificationsPerPageCount, 0, false, true);
        }
    }

    async startProcessNotifications() {
        setInterval(async () => {
            await this.updateNotifications();
            await this.displayNotifications();
        }, 10000);
    }
}
